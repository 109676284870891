APP.carCalculator = function () {
	const el = $('.loan-calculator');
	const calcModel = el.find('[name="calc_model"]');
	const labelTextModel = calcModel.data('label-text');
	const inputPlaceholderModel = calcModel.data('input-placeholder');
	const calcVariant = el.find('[name="calc_variant"]');
	const labelTextVariant = calcVariant.data('label-text');
	const inputPlaceholderVariant = calcVariant.data('input-placeholder');
	const calcRangeTerm = el.find('[name="calc_range_term"]');
	const calcCustomerDeposit = el.find('[name="calc_customer_deposit"]');
	const calcCustomerDepositError = calcCustomerDeposit.next();
	const calcRangeCustomerDeposit = el.find('[name="calc_range_customer_deposit"]');
	const financialPlan = el.find('.financial-plan');
	const titleCustom = financialPlan.data('title-custom');
	const contentCustom = financialPlan.data('content-custom');
	const financialPlanRow = financialPlan.find('.row');
	const monthlyPayments = el.find('.monthly-payments');
	const monthlyPaymentsTitle = monthlyPayments.find('.monthly-payments__title');
	const monthlyPaymentsThumb = monthlyPayments.find('.monthly-payments__thumb img');
	const monthlyPaymentsLeft = monthlyPayments.find('.monthly-payments__left');
	const monthlyPaymentsRight = monthlyPayments.find('.monthly-payments__right');
	const monthlyPaymentsDesc = monthlyPayments.find('.monthly-payments__desc');
	const customInterest = el.find('.custom-interest');

	const loanDetail = $('.loan-detail');
	const loanDeatailHead = loanDetail.find('.loan-detail__head');
	const soTienVay = loanDeatailHead.find('.so-tien-vay');
	const goiVay = loanDeatailHead.find('.goi-vay');
	const thoiGianVay = loanDeatailHead.find('.thoi-gian-vay');
	const dongXe = loanDeatailHead.find('.dong-xe');
	const thoiGianUuDai = loanDeatailHead.find('.thoi-gian-uu-dai');
	const thoiGianKhongUuDai = loanDeatailHead.find('.thoi-gian-khong-uu-dai');
	const laiSuatUuDai = loanDeatailHead.find('.lai-suat-uu-dai');
	const laiSuatThaNoi = loanDeatailHead.find('.lai-suat-tha-noi');

	let priceMask = null;

	if (document.getElementById('price')) {
		priceMask = IMask(document.getElementById('price'), {
			mask: Number,
			min: 94500000,
			max: 630000000,
			thousandsSeparator: ',',
		});
	}

	let calcRangeCustomerDepositSlider = calcRangeCustomerDeposit.slider({
		min: 0,
		max: 9,
		step: 1,
		value: 0,
		tooltip: 'hide',
		ticks: [0, 100],
		ticks_labels: ['0%', '100%'],
		enabled: false,
	});

	const [getFC, setFC] = useFC();
	const [getFilterFC, setFilterFC] = useFilterFC();
	const [getLoanStandard, setLoadStandard] = useLoanStandard();
	const [getLoanSelected, setLoanSelected] = useLoanSelected();

	function init() {
		if (el.length === 0) return;

		gsap.set('.test-drive', {
			alpha: 0,
			height: 0,
		});

		handleInitialLoad();
	}

	function useFC() {
		let _fc = null;

		function getFC() {
			return _fc;
		}

		function setFC(data) {
			_fc = [...data];
		}

		return [getFC, setFC];
	}

	function useFilterFC() {
		let _filterFC = null;

		function geFiltertFC() {
			return _filterFC;
		}

		function setFilterFC(data) {
			_filterFC = data;
		}

		return [geFiltertFC, setFilterFC];
	}

	function useLoanStandard() {
		let _loanStandard = null;

		function getLoanStandard() {
			return _loanStandard;
		}

		function setLoadStandard(data) {
			_loanStandard = [...data];
		}

		return [getLoanStandard, setLoadStandard];
	}

	function useLoanSelected() {
		let _loanSelected = null;

		function getLoanSelected() {
			return _loanSelected;
		}

		function setLoanSelected(data) {
			_loanSelected = data;
		}

		return [getLoanSelected, setLoanSelected];
	}

	function renderFinancialItem(item) {
		return `<div class="row__col row__col--lg-4">
			<div class="financial-plan__item" for="calc-plan-${item.id}" data-loan-id="${item.id}">
				<div class="financial-plan__body">
					<span class="financial-plan__radio"></span>
					<h3 class="financial-plan__title">${item.title.rendered}</h3>
					<div class="financial-plan__content">${item.excerpt.rendered}</div>
				</div>
			</div>
		</div>`;
	}

	// function renderFinancialItemCustom() {
	// 	return `<div class="row__col row__col--lg-4">
	// 		<div class="financial-plan__item is-active" for="calc-plan-111" data-loan-id="111">
	// 			<div class="financial-plan__body">
	// 				<span class="financial-plan__radio"></span>
	// 				<h3 class="financial-plan__title">${titleCustom}</h3>
	// 				<div class="financial-plan__content">${contentCustom}</div>
	// 			</div>
	// 		</div>
	// 	</div>`;
	// }

	function renderFinancialToDom(data) {
		let items = data.map((item) => renderFinancialItem(item)).join('');
		//items = renderFinancialItemCustom() + items;
		financialPlanRow.html(items);
		const financialPlanFirst = financialPlanRow.find('.row__col:first-child');
		const financialPlanItem = financialPlanRow.find('.financial-plan__item');
		const financialPlanItemFirst = financialPlanFirst.find('.financial-plan__item');
		const loanId = getParameterByName('loan_id');
		financialPlanItem.on('click', handleFinancialPlanItem);
		setTimeout(() => {
			if (loanId) {
				$(`[data-loan-id="${loanId}"]`).trigger('click');
			} else {
				financialPlanItemFirst.trigger('click');
			}
		}, 300);
	}

	function renderFinancialItemEmpty(message) {
		return `<p>${message}</p>`;
	}

	// function renderFinancialToDomEmpty(message) {
	// 	let items = renderFinancialItemEmpty(message);
	// 	$('.select-model-message').html(items);
	// }

	function handleFinancialPlanItem() {
		const obj = $(this);
		const loanId = parseInt(obj.data('loan-id'));
		const variantPrice = calcVariant.find('option:selected').data('price');
		let tra_truoc_toi_thieu = 0;
		let tra_truoc_toi_da = 0;
		let phan_tram_vay_toi_thieu = 0;
		let chartLeft = '';
		let chartRight = '';

		const loanItem = getFC().find((item) => item.id === loanId);

		const {
			acf: {
				thoi_gian_toi_thieu,
				thoi_gian_toi_da,
				muc_vay_toi_da,
				chart_description,
				chart_image,
				chart_title,
				goi_vay_mac_dinh,
				chart_info: { chart_left, chart_right },
			},
		} = loanItem;
		const arrMonths = [];

		setLoanSelected(loanItem);

		if (goi_vay_mac_dinh) {
			customInterest.addClass(CLASS._show);
			customInterest.find('.form__group').attr('data-required', 'data-required');
		} else {
			customInterest.removeClass(CLASS._show);
			customInterest.find('.form__group').removeAttr('data-required');
			customInterest.find('.form__group .form__control').val('');
		}

		if (chart_title) monthlyPaymentsTitle.html(chart_title);
		if (chart_image) monthlyPaymentsThumb.attr('src', chart_image);
		if (chart_left.length > 0) {
			chartLeft = chart_left.map((item) => {
				return `<span>${item.name}</span>`;
			});
			monthlyPaymentsLeft.html(chartLeft);
		}
		if (chart_right.length > 0) {
			chartRight = chart_right.map((item) => {
				return `<span>${item.name}</span>`;
			});
			monthlyPaymentsRight.html(chartRight);
		}
		if (chart_description) monthlyPaymentsDesc.html(chart_description);

		$('.financial-plan__item').removeClass(CLASS._active);
		obj.addClass(CLASS._active);

		$('[name="loan-id"]').val(loanId);

		for (let i = parseInt(thoi_gian_toi_thieu); i <= parseInt(thoi_gian_toi_da); i++) {
			if (i % 12 === 0) {
				arrMonths.push(i);
			}
		}

		if (variantPrice) {
			phan_tram_vay_toi_thieu = 100 - parseInt(muc_vay_toi_da);
			tra_truoc_toi_thieu = parseInt(variantPrice) * (phan_tram_vay_toi_thieu / 100);
			tra_truoc_toi_da = parseInt(variantPrice);
			priceMask.updateOptions({
				mask: Number,
				min: tra_truoc_toi_thieu,
				max: tra_truoc_toi_da,
			});
			priceMask.updateValue();
			calcCustomerDeposit.val(APP.numberWithCommas(tra_truoc_toi_thieu));
			calcRangeCustomerDeposit.slider('destroy');
			calcRangeCustomerDepositSlider = calcRangeCustomerDeposit
				.slider({
					min: tra_truoc_toi_thieu,
					max: tra_truoc_toi_da,
					step: 500000,
					ticks: [phan_tram_vay_toi_thieu, 100],
					ticks_labels: [`${phan_tram_vay_toi_thieu}%`, '100%'],
					tooltip: 'hide',
				})
				.on('change', function () {
					const parent = $(this).parent();
					const round = parent.find('.slider-handle.min-slider-handle.round');
					const percent = Math.ceil(($(this).val() * 100) / tra_truoc_toi_da);
					round.html(`<span>${percent}%</span>`);
					calcCustomerDeposit.val(APP.numberWithCommas($(this).val()));
				});
		}

		calcRangeTerm.slider('destroy');
		calcRangeTerm.slider({
			min: arrMonths[0],
			max: arrMonths[arrMonths.length - 1],
			step: 12,
			ticks: arrMonths,
			ticks_labels: arrMonths,
			tooltip: 'hide',
		});
	}

	async function getData(url) {
		const response = await fetch(url, {
			headers: {
				'Content-Type': 'application/json',
				//lang: _lang,
			},
		});

		const res = await response.json();

		return res;
	}

	async function handleInitialLoad() {
		const arrMonths = [12, 24, 36, 48, 60, 72, 84, 96];
		//const resFc = await getData(`${api}/wp/generate/financialCalculator`);
		const resFc = await getData(`${apiDealer}/getFinancialCalculator.php`);
		setFC(resFc.items);

		const loanStandard = getFC().filter((item) => item.acf.goi_vay_mac_dinh);
		setLoadStandard(loanStandard);
		setFilterFC(loanStandard);

		//renderFinancialToDom(getLoanStandard());

		const parent = $('#price:disabled, .slider-disabled').parents('.form__group');
		parent.on('click', function () {
			if ($('#price:disabled, .slider-disabled').length === 0) return;
			const posY = $('.loan-calculator__item:first-child').offset().top;
			const headerH = $('.header').height();
			APP.setScrollTopAnimation(posY - headerH);
		});

		calcRangeTerm.slider({
			min: arrMonths[0],
			max: arrMonths[arrMonths.length - 1],
			step: 12,
			ticks: arrMonths,
			ticks_labels: arrMonths,
			tooltip: 'hide',
		});

		const modelId = getParameterByName('model_id') ? parseInt(getParameterByName('model_id')) : '';
		const variantId = getParameterByName('variant_id')
			? parseInt(getParameterByName('variant_id'))
			: '';

		APP.getModels(calcModel, labelTextModel, inputPlaceholderModel, 0, function () {
			if (modelId) {
				calcModel.val(modelId);
				calcModel.select2('destroy');
				calcModel.select2({
					with: 'resolve',
					searchInputPlaceholder: inputPlaceholderModel,
					templateSelection: function (data, container) {
						if (data.id !== '') {
							$(container).addClass('select2-selection__rendered--value');
						} else {
							$(container).removeClass('select2-selection__rendered--value');
						}
						return data.text;
					},
				});
				calcModel.trigger('change');
			}
		});

		calcModel.on('change', function () {
			const obj = $(this);
			const modelIdObj = obj.val() !== '' ? parseInt(obj.val()) : '';

			APP.getVariants(
				calcVariant,
				labelTextVariant,
				inputPlaceholderVariant,
				modelIdObj,
				function () {
					if (variantId && modelId === modelIdObj) {
						calcVariant.val(variantId);
						calcVariant.select2('destroy');
						calcVariant.select2({
							minimumResultsForSearch: -1,
							with: 'resolve',
							searchInputPlaceholder: inputPlaceholderVariant,
							templateSelection: function (data, container) {
								if (data.id !== '') {
									$(container).addClass('select2-selection__rendered--value');
								} else {
									$(container).removeClass('select2-selection__rendered--value');
								}
								return data.text;
							},
						});
						if (getFC().length > 0) {
							calcVariant.trigger('change');
						}
					}
				}
			);

			if (modelIdObj !== '') {
				const filterFC = getFC().filter((item) => {
					return item.car_model.includes(modelIdObj) || item.acf.goi_vay_mac_dinh;
				});
				setFilterFC(filterFC);
				//renderFinancialToDom(getFilterFC());
			} else {
				//renderFinancialToDom(getLoanStandard());
				setFilterFC(getLoanStandard());
				$('.loan-calculator__item').hide();
				$('.loan-calculator__item').eq(0).show();
			}
			//console.log(getFC(), getFilterFC());
		});

		calcVariant.on('change', function () {
			const obj = $(this);
			// const modelName = calcModel.find('option:selected').text();
			// const variantName = obj.find('option:selected').text();
			//const message = $('.select-model-message').data('message');
			const variantId = obj.val() !== '' ? parseInt(obj.val()) : '';
			const modelId = calcModel.val() !== '' ? parseInt(calcModel.val()) : '';
			//const car_variant = getFilterFC().length > 0 ? getFilterFC()[0].acf.car_variant : '';

			//console.log(variantId, getFilterFC());

			const dataFC = getFilterFC().filter(
				(item) => item.acf.car_variant.includes(variantId) || item.acf.goi_vay_mac_dinh
			);

			if (variantId !== '') {
				//if (car_variant.includes(variantId)) {
				renderFinancialToDom(dataFC);
				$('.loan-calculator__item').show();
				//$('.select-model-message').hide();
				if (variantId !== '' && modelId !== '') {
					calcCustomerDeposit.removeAttr('disabled');
					calcCustomerDepositError.text('');
					calcCustomerDepositError.parent().removeClass(CLASS._error);
					const variantPrice = calcVariant.find('option:selected').data('price');
					if (variantPrice) {
						//console.log(getFilterFC());
						const {
							acf: { muc_vay_toi_da },
						} = getFilterFC()[0];
						phan_tram_vay_toi_thieu = 100 - parseInt(muc_vay_toi_da);
						tra_truoc_toi_thieu = parseInt(variantPrice) * (phan_tram_vay_toi_thieu / 100);
						tra_truoc_toi_da = parseInt(variantPrice);
						priceMask.updateOptions({
							mask: Number,
							min: tra_truoc_toi_thieu,
							max: tra_truoc_toi_da,
						});
						priceMask.updateValue();
						calcCustomerDeposit.val(APP.numberWithCommas(tra_truoc_toi_thieu));
						calcRangeCustomerDeposit.slider('destroy');
						calcRangeCustomerDepositSlider = calcRangeCustomerDeposit
							.slider({
								min: tra_truoc_toi_thieu,
								max: tra_truoc_toi_da,
								step: 500000,
								ticks: [phan_tram_vay_toi_thieu, 100],
								ticks_labels: [`${phan_tram_vay_toi_thieu}%`, '100%'],
								tooltip: 'hide',
							})
							.on('change', function () {
								const parent = $(this).parent();
								const round = parent.find('.slider-handle.min-slider-handle.round');
								const percent = Math.ceil(($(this).val() * 100) / tra_truoc_toi_da);
								round.html(`<span>${percent}%</span>`);
								calcCustomerDeposit.val(APP.numberWithCommas($(this).val()));
							});
					}
				} else {
					calcCustomerDeposit.val('').attr('disabled', 'disabled');
					calcRangeCustomerDeposit.slider('destroy');
					calcRangeCustomerDepositSlider = calcRangeCustomerDeposit.slider({
						min: 0,
						max: 9,
						step: 1,
						value: 0,
						tooltip: 'hide',
						ticks: [0, 100],
						ticks_labels: ['0%', '100%'],
						enabled: false,
					});
				}
			} else {
				$('.loan-calculator__item').hide();
				$('.loan-calculator__item').eq(0).show();
			}
		});

		calcCustomerDeposit.on('blur', function () {
			const obj = $(this);
			const value = obj.val();
			const price = value.replace(/,/g, '');
			calcRangeCustomerDepositSlider.slider('setValue', price, true, true);
		});

		APP.validate('.form--loan-calculator', {
			isAjax: true,
			onComplete: function (data) {
				const gia_xe = parseInt(calcVariant.find('option:selected').data('price'));
				const dong_xe = calcModel.find('option:selected').text();
				const kieu_xe = calcVariant.find('option:selected').text();

				// const { calc_range_customer_deposit, calc_range_term } = data;

				// const {
				// 	acf: { lai_suat_uu_dai, lai_suat_tha_noi, thoi_gian_uu_dai, name, bien_do },
				// } = getLoanSelected();

				let lai_suat_uu_dai = '';
				let lai_suat_tha_noi = '';
				let thoi_gian_uu_dai = '';
				let bien_do = '';
				let name = getLoanSelected().acf.name;

				const { calc_range_customer_deposit, calc_range_term } = data;

				if (customInterest.hasClass(CLASS._show)) {
					lai_suat_uu_dai = parseFloat($('[name="calc_custom_interest_rate"]').val())
						? parseFloat($('[name="calc_custom_interest_rate"]').val())
						: 0;
					lai_suat_tha_noi = parseFloat($('[name="calc_custom_interest_float"]').val())
						? parseFloat($('[name="calc_custom_interest_float"]').val())
						: 0;
					thoi_gian_uu_dai = parseInt($('[name="calc_custom_interest_time"]').val())
						? parseInt($('[name="calc_custom_interest_time"]').val())
						: 0;
					bien_do = getLoanSelected().acf.bien_do;
				} else {
					// const {
					// 	acf: { lai_suat_uu_dai, lai_suat_tha_noi, thoi_gian_uu_dai },
					// } = getLoanSelected();
					lai_suat_uu_dai = getLoanSelected().acf.lai_suat_uu_dai;
					lai_suat_tha_noi = getLoanSelected().acf.lai_suat_tha_noi;
					thoi_gian_uu_dai = getLoanSelected().acf.thoi_gian_uu_dai;
					bien_do = getLoanSelected().acf.bien_do;
				}

				const so_tien_tra_truoc = parseInt(calc_range_customer_deposit);
				const thoi_gian_vay = parseInt(calc_range_term);
				const so_tien_can_vay = gia_xe - so_tien_tra_truoc;
				const tien_goc_tra_hang_thang = Math.ceil(so_tien_can_vay / thoi_gian_vay);
				let dataHTML = '';

				soTienVay.html(APP.numberWithCommas(so_tien_can_vay));
				goiVay.html(name);
				thoiGianVay.html(thoi_gian_vay);
				dongXe.html(`${dong_xe} - ${kieu_xe}`);
				thoiGianUuDai.html(thoi_gian_uu_dai);
				thoiGianKhongUuDai.html(parseInt(thoi_gian_uu_dai) + 1);
				laiSuatUuDai.html(`${parseFloat(lai_suat_uu_dai)}%`);
				laiSuatThaNoi.html(`${parseFloat(bien_do)}%`);

				for (let i = 1; i <= thoi_gian_vay; i++) {
					const so_tien_can_vay_giam_dan =
						i === 1 ? so_tien_can_vay : so_tien_can_vay - tien_goc_tra_hang_thang * (i - 1);

					const lai_suat =
						i <= parseInt(thoi_gian_uu_dai)
							? parseFloat(lai_suat_uu_dai)
							: parseFloat(lai_suat_tha_noi);

					const tien_lai_hang_thang = Math.ceil((so_tien_can_vay_giam_dan * (lai_suat / 100)) / 12);

					const tong_tien_tra_hang_thang = Math.ceil(tien_goc_tra_hang_thang + tien_lai_hang_thang);

					dataHTML += `<tr>
						<td style="width: 10%; text-align: center;">${i}</td>
						<td style="width: 18%; text-align: center;">${APP.numberWithCommas(so_tien_can_vay_giam_dan)}</td>
						<td style="width: 18%; text-align: center;">${APP.numberWithCommas(tien_goc_tra_hang_thang)}</td>
						<td style="width: 18%; text-align: center;">${APP.numberWithCommas(lai_suat)}%</td>
						<td style="width: 18%; text-align: center;">${APP.numberWithCommas(tien_lai_hang_thang)}</td>
						<td style="width: 18%; text-align: center;">${APP.numberWithCommas(tong_tien_tra_hang_thang)}</td>
					</tr>`;
				}

				$('.loan-detail__body tbody').html(dataHTML);

				gsap.set(loanDetail, {
					display: 'block',
					alpha: 0,
				});

				gsap.to(loanDetail, {
					duration: 0.4,
					alpha: 1,
					onComplete: function () {
						const loanDetailPos = loanDetail.position().top;
						APP.setScrollTopAnimation(loanDetailPos);
						el.addClass(CLASS._blur);
						gsap.set('.test-drive', {
							alpha: 1,
							height: 'auto',
						});
					},
				});

				// tracking to CDP
				dataLayer.push({
					event: 'pangoTrack',
					eventAction: 'calc_MAF',
					cdpData: {
						properties: {
							model: dong_xe,
							variant: kieu_xe,
							nameMAF: name,
							prepaid: so_tien_tra_truoc,
							borrowedTime: thoi_gian_vay,
						},
					},
				});
				// End tracking
			},
		});
	}

	init();
};
