const code_campaign = '_mitsubishi_motors';

function setCookie(cname, cvalue, exminute) {
	var d = new Date();
	d.setTime(d.getTime() + exminute * 60 * 1000);
	var expires = 'expires=' + d.toUTCString();
	document.cookie = cname + code_campaign + '=' + cvalue + ';' + expires + ';path=/';
}

function getCookie(cname) {
	var name = cname + code_campaign + '=';
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(';');
	for (var i = 0; i < ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return '';
}

function checkCookie(cname) {
	var name = getCookie(cname);
	if (name != '') {
		return true;
	} else {
		return false;
	}
}

function deleteCookie(name) {
	document.cookie = name + code_campaign + '=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
}

function getParameterByName(name) {
	name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
	var regex = new RegExp('[\\?&]' + name + '=([^&#]*)'),
		results = regex.exec(location.search);
	return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

if (getParameterByName('utm_source')) {
	setCookie('utm_source', '', 60);
}
if (getParameterByName('utm_medium')) {
	setCookie('utm_medium', '', 60);
}
if (getParameterByName('utm_campaign')) {
	setCookie('utm_campaign', '', 60);
}
if (getParameterByName('utm_content')) {
	setCookie('utm_content', '', 60);
}
if (getParameterByName('mmv_campaign')) {
	setCookie('mmv_campaign', '', 60);
}

if (!checkCookie('utm_source')) {
	setCookie('utm_source', getParameterByName('utm_source'), 60);
}
if (!checkCookie('utm_medium')) {
	setCookie('utm_medium', getParameterByName('utm_medium'), 60);
}
if (!checkCookie('utm_campaign')) {
	setCookie('utm_campaign', getParameterByName('utm_campaign'), 60);
}
if (!checkCookie('utm_content')) {
	setCookie('utm_content', getParameterByName('utm_content'), 60);
}
if (!checkCookie('mmv_campaign')) {
	setCookie('mmv_campaign', getParameterByName('mmv_campaign'), 60);
}

APP.isMobile = function () {
	if (navigator.userAgent.match(/Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i))
		return true;
	else return false;
};

APP.isTablet = function () {
	if (navigator.userAgent.match(/Tablet|iPad/i)) return true;
	else return false;
};

APP.isDesktop = function () {
	if (!APP.isMobile() && !APP.isTablet()) return true;
	else return false;
};

APP.isLandscape = function () {
	if (window.innerHeight < window.innerWidth) return true;
	else return false;
};

APP.resize = function (func, timer) {
	let resizeEnd;
	timer = timer || 150;
	window.addEventListener('resize', function () {
		clearTimeout(resizeEnd);
		resizeEnd = setTimeout(func, timer);
	});
	return true;
};

APP.getResize = function () {
	WIN_WIDTH = WINDOW.width();
	WIN_HEIGHT = WINDOW.height();
	DOC_WIDTH = DOCUMENT.width();
	DOC_HEIGHT = DOCUMENT.height();
};

APP.setScrollTopAnimation = function (posY, callback) {
	gsap.to(window, {
		scrollTo: {
			y: posY,
			autoKill: false,
		},
		duration: 0.4,
		ease: 'power0',
		onComplete: function () {
			if (callback) {
				setTimeout(function () {
					callback();
				}, 150);
			}
		},
	});
	return true;
};

APP.getScrollTop = function () {
	return window.scrollY;
};

APP.setCookie = function (key, value, days) {
	const expires = new Date();
	expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
	document.cookie = key + '=' + value + ';path=/' + ';expires=' + expires.toUTCString();
	return true;
};

APP.getCookie = function (key) {
	const keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
	return keyValue ? keyValue[2] : null;
};

APP.removeCookie = function (key) {
	APP.setCookie(key, '', -1);
	return true;
};

APP.setSession = function (key, value) {
	sessionStorage.setItem(key, value);
};

APP.getSession = function (key) {
	return sessionStorage.getItem(key);
};

APP.checkSession = function (key) {
	var value = APP.getSession(key);
	if (value !== null && value !== '') {
		return true;
	} else {
		return false;
	}
};

APP.getParameterByName = function (query) {
	query = query.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
	var regex = new RegExp('[\\?&]' + query + '=([^&#]*)'),
		results = regex.exec(location.search);
	return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

APP.getScrollbarWidth = function () {
	const outer = document.createElement('div');
	outer.style.visibility = 'hidden';
	outer.style.overflow = 'scroll';
	outer.style.msOverflowStyle = 'scrollbar';
	document.body.appendChild(outer);
	const inner = document.createElement('div');
	outer.appendChild(inner);
	const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;
	outer.parentNode.removeChild(outer);
	return scrollbarWidth;
};

APP.numberWithCommas = function (x) {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

APP.renderSelect = function (selectEl, options, inputPlaceholder = '', search = true) {
	selectEl.html(options);
	selectEl.select2('destroy');
	if (search) {
		selectEl.select2({
			with: 'resolve',
			searchInputPlaceholder: inputPlaceholder,
			templateSelection: function (data, container) {
				if (data.id !== '') {
					$(container).addClass('select2-selection__rendered--value');
				} else {
					$(container).removeClass('select2-selection__rendered--value');
				}
				return data.text;
			},
		});
	} else {
		selectEl.select2({
			minimumResultsForSearch: -1,
			with: 'resolve',
			searchInputPlaceholder: inputPlaceholder,
			templateSelection: function (data, container) {
				if (data.id !== '') {
					$(container).addClass('select2-selection__rendered--value');
				} else {
					$(container).removeClass('select2-selection__rendered--value');
				}
				return data.text;
			},
		});
	}
};

APP.getData = function (url, cb) {
	fetch(url, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
			lang: _lang,
		},
	})
		.then((res) => res.json())
		.then((data) => cb(data))
		.catch((err) => console.log(err));
};

APP.getProvinces = function (selectEl, optText, inputPlaceholder) {
	let options = `<option value="">${optText}</option>`;
	APP.getData(`${api}/wp/generate/costEstimation`, function (data) {
		data.items
			.map((province) => {
				if (province.parent !== 0)
					options += `<option data-province-id="${province.id}" value="${province.slug}">${province.title.rendered}</option>`;
			})
			.join('');
		APP.renderSelect(selectEl, options, inputPlaceholder);
	});
};

APP.getModels = function (
	selectEl,
	optText,
	inputPlaceholder,
	is_service = 0,
	callback = function () {}
) {
	let options = `<option value="">${optText}</option>`;
	const apiLink = is_service === 1 ? `${api}/wp/model?is_service=1` : `${api}/wp/model`;
	APP.getData(apiLink, function (data) {
		data.items
			.map((model) => {
				options += `<option data-model-name="${model.name}" value="${model.id}">${model.name}</option>`;
			})
			.join('');
		APP.renderSelect(selectEl, options, inputPlaceholder);
		if (callback) callback();
	});
};

APP.getServices = function (selectEl, optText, inputPlaceholder) {
	let options = `<option value="">${optText}</option>`;
	APP.getData(`${api}/service/commonData`, function (data) {
		data.items
			.map((service) => {
				options += `<option data-service-id="${service.id}" data-service-name="${service.name}" value="${service.code}">${service.name}</option>`;
			})
			.join('');
		APP.renderSelect(selectEl, options, inputPlaceholder, false);
	});
};

APP.getDealers = function (selectEl, optText, inputPlaceholder) {
	let options = `<option value="">${optText}</option>`;

	APP.getData(`${api}/wp/dealerNetwork`, function (data) {
		data.area
			.map((area) => {
				options += `<optgroup label="${area.description}">${area.description}</optgroup>`;
				data.items
					.map((dealer) => {
						if (dealer.location_network.area.id === area.id && dealer.sales) {
							options += `<option value="${dealer.id}">${dealer.acf.short_name}</option>`;
						}
					})
					.join('');
			})
			.join('');
		APP.renderSelect(selectEl, options, inputPlaceholder);
	});
};

APP.getDealerGroup = function (selectEl, optText, inputPlaceholder) {
	let options = `<option value="">${optText}</option>`;
	APP.getData(`${api}/service/dealerGroup`, function (data) {
		data.items
			.map((dealer) => {
				if (crmServiceCode) {
					if (dealer.dealerCode === crmServiceCode) {
						options += `<option data-dealer-id="${dealer.id}" value="${dealer.dealerCode}" selected>${dealer.name}</option>`;
					} else {
						options += `<option data-dealer-id="${dealer.id}" value="${dealer.dealerCode}" disabled>${dealer.name}</option>`;
					}
				} else {
					options += `<option data-dealer-id="${dealer.id}" value="${dealer.dealerCode}">${dealer.name}</option>`;
				}
			})
			.join('');

		APP.renderSelect(selectEl, options, inputPlaceholder);
	});
};

APP.getVariants = function (
	selectEl,
	optText,
	inputPlaceholder,
	modelId,
	callback = function () {}
) {
	let options = `<option value="">${optText}</option>`;
	if (modelId !== '') {
		APP.getData(`${api}/wp/variant?model_id=${modelId}`, function (data) {
			const parentFormGroup = selectEl.parents('.form__group');

			if (data.items.length > 0) {
				if (data.items.length === 1) {
					options = '';
					parentFormGroup.hide();
				} else {
					parentFormGroup.show();
				}
				// parentFormGroup.attr('data-required', 'data-required');
				data.items
					.map((variant) => {
						if (variant.acf.show_on_form)
							options += `<option value="${variant.id}" data-price="${variant.acf.price}" ${
								data.items.length === 1 ? 'selected' : ''
							}>${variant.title}</option>`;
					})
					.join('');
			} else {
				parentFormGroup.hide();
				parentFormGroup.removeAttr('data-required');
				options = `<option value="0" data-price=""></option>`;
			}
			APP.renderSelect(selectEl, options, inputPlaceholder, false);
			if (callback) callback();
		});
	} else {
		APP.renderSelect(selectEl, options, inputPlaceholder, false);
	}
};

APP.getAllVariants = function (
	selectEl,
	optText,
	inputPlaceholder,
	modelId,
	callback = function () {}
) {
	let options = `<option value="">${optText}</option>`;
	if (modelId !== '') {
		APP.getData(`${api}/wp/variant?model_id=${modelId}`, function (data) {
			const parentFormGroup = selectEl.parents('.form__group');

			if (data.items.length > 0) {
				parentFormGroup.show();
				// parentFormGroup.attr('data-required', 'data-required');
				data.items
					.map((variant) => {
						if (variant.acf.show_on_form)
							options += `<option value="${variant.id}" data-price="${variant.acf.price}">${variant.title}</option>`;
					})
					.join('');
			} else {
				parentFormGroup.hide();
				parentFormGroup.removeAttr('data-required');
				options = `<option value="0" data-price=""></option>`;
			}
			APP.renderSelect(selectEl, options, inputPlaceholder, false);
			if (callback) callback();
		});
	} else {
		APP.renderSelect(selectEl, options, inputPlaceholder, false);
	}
};

APP.getSubVersion = function (selectEl, optText, inputPlaceholder, modelId) {
	let options = `<option value="">${optText}</option>`;
	if (modelId !== '') {
		APP.getData(`${api}/wp/generate/serviceInterval/version?parent=${modelId}`, function (data) {
			data.items
				.map((version) => {
					options += `<option value="${version.id}">${version.name}</option>`;
				})
				.join('');
			APP.renderSelect(selectEl, options, inputPlaceholder, false);
		});
	} else {
		APP.renderSelect(selectEl, options, inputPlaceholder, false);
	}
};
