APP.testDriveCheckin = function () {
	const el = $('.test-drive-checkin');
	if (el.length === 0) return false;

	const holder = el.find('.test-drive-checkin__holder');
	const holderWidth = holder.innerWidth();
	const inner = el.find('.test-drive-checkin__inner');
	const loading = el.find('.loading');

	const form1 = el.find('.info-step1');
	const phone = form1.find('[name="phone"]');

	const form1otp = el.find('.info-step1-otp');
	const otpCode = form1otp.find('[name="otp_code"]');

	const form2 = el.find('.info-step2');
	const nvbhSelect = form2.find('[name="nvbh"]');
	const customerName = form2.find('.test-drive-checkin__customer');
	const customerPhoneLabel = form2.find('.test-drive-checkin__phone--label');
	const customerPhoneValue = form2.find('.test-drive-checkin__phone--value');
	const customerEmailLabel = form2.find('.test-drive-checkin__email--label');
	const customerEmailValue = form2.find('.test-drive-checkin__email--value');
	const customerAgencyLabel = form2.find('.test-drive-checkin__agency--value');
	const customerAgencyValue = form2.find('.test-drive-checkin__agency--value');

	const form3 = el.find('.info-step3');
	const licenceFile1 = form3.find('[name="file_GPLX1"]');

	const thankYou = $('.thank-you');

	const backButtons = el.find('.btn-back');
	let currentFormPos = 0;
	let dataSubmit = {};

	let customerId = '';
	let userEventData = [];
	let userEventSelected = {};
	let dataSubmitLast = {};

	let croppers = [];

	function animForm(isBack) {
		currentFormPos = isBack ? currentFormPos + holderWidth : currentFormPos - holderWidth;
		APP.setScrollTopAnimation(0);
		TweenMax.to(inner, 0.4, {
			x: currentFormPos,
			ease: Power1.easeOut,
		});
	}

	function animForm1(isBack) {
		currentFormPos = isBack ? currentFormPos + holderWidth * 2 : currentFormPos - holderWidth * 2;
		APP.setScrollTopAnimation(0);
		TweenMax.to(inner, 0.4, {
			x: currentFormPos,
			ease: Power1.easeOut,
		});
	}

	function init() {
		phone.focus();
		// Step 1
		APP.validate(form1, {
			isAjax: true,
			onComplete: function (data) {
				dataSubmit = { ...dataSubmit, ...data };
				loading.show();

				fetch(`${api}/dealer/crm/customer`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Cookie: 'PHPSESSID=bn1pik82vpnsp9lqdkbjo44amk',
						lang: _lang,
					},
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((data) => {
						loading.hide();
						if (data?.result === 'fail') {
							form1.find('.form__error--response').html(data.errorMessage);
						} else if (!data?.data?.phone_number_verified) {
							otpCode.val(data?.data?.otp_code).focus();
							animForm();
						} else {
							animForm1();
							customerId = data?.data?.id;
							userEventData = data?.data?.items;

							if (userEventData.length > 0) {
								userEventSelected = userEventData[0];
								renderSelectSale(userEventData);
								renderCustomer(userEventSelected);

								dataSubmitLast = {
									...dataSubmitLast,
									name: userEventSelected?.name,
									phone: userEventSelected?.phone,
									email: userEventSelected?.email
										? userEventSelected?.email
										: 'tuong.bui@dsquare.com.vn',
									sales_consultant: userEventSelected?.brokerName,
									dealer_code: dataSubmit?.dealer_code,
									event_code: dataSubmit?.event_code,
									crm_customer_id: '',
								};
							}
						}
					})
					.catch((error) => {
						loading.hide();
						console.log(error);
					});
			},
		});

		// APP.validate(form1otp, {
		// 	isAjax: true,
		// 	onComplete: function (data) {
		// 		dataSubmit = { ...dataSubmit, ...data };
		// 		loading.show();
		// 	},
		// });
		APP.validate(form2, {
			isAjax: true,
			onComplete: function (data) {
				dataSubmitLast = {
					...dataSubmitLast,
					sales_consultant: data.nvbh,
					is_regist_test_drive: data.is_regist_test_drive,
				};
				animForm1();
			},
		});

		APP.validate(form3, {
			isAjax: true,
			onComplete: function (data) {
				loading.show();

				dataSubmitLast = {
					...dataSubmitLast,
					driving_licence: data.driving_licence,
				};

				const files = croppers.map((item) => {
					return item.getCroppedCanvas().toDataURL('image/jpeg');
				});

				const dataUpload = {
					note: dataSubmitLast.event_code,
					files,
					id_user: customerId,
				};

				fetch(`${api}/file/upload`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						lang: _lang,
					},
					body: JSON.stringify(dataUpload),
				})
					.then((res) => res.json())
					.then((data) => {
						if (data.result === 'success') {
							form1.find('.form__error--response').html('');
							const fileIds = data.files.map((item) => item.id).join(',');
							dataSubmitLast = {
								...dataSubmitLast,
								file_id: fileIds,
							};

							const formData = new URLSearchParams();

							for (let key in dataSubmitLast) {
								formData.append(key, dataSubmitLast[key]);
							}

							if (!dataSubmitLast.file_id) {
								formData.append('file_id', '');
							}

							fetch(`${api}/dealer/crm/customer/${customerId}`, {
								method: 'PUT',
								body: formData,
							})
								.then((res) => res.json())
								.then((data) => {
									loading.hide();
									if (data.result === 'success') {
										form3.find('.form__error--response').html('');
										if (data.item.orderIdTestDriveName) {
											thankYou
												.find('.thank-you__sttname')
												.html(' ' + data.item.orderIdTestDriveName);
										}
										TweenMax.to(thankYou, 0.4, {
											autoAlpha: 1,
											display: 'block',
											ease: Power1.easeOut,
											onComplete: function () {},
										});
									} else {
										form3.find('.form__error--response').html(data.errorMessage);
									}
								});
						} else {
							form3.find('.form__error--response').html(data.errorMessage);
						}
					});

				// grecaptcha.ready(function () {
				// 	grecaptcha
				// 		.execute('6LcCEBgnAAAAAJ_0NFiVTQSlpDYZ35ypza8kH-Qv', { action: 'submit' })
				// 		.then(function (token) {
				// 			// loading.hide();
				// 			dataSubmit = { ...dataSubmit, ...data, g_token: token };
				// 			delete dataSubmit.agree;
				// 			delete dataSubmit.form__agree;
				// 			// console.log(dataSubmit);
				// 			function submitForm() {
				// 				const formData = new URLSearchParams();
				// 				for (let key in dataSubmit) {
				// 					formData.append(key, dataSubmit[key]);
				// 				}
				// 				if (!dataSubmit.file_id) {
				// 					formData.append('file_id', '');
				// 				}
				//			}
				// 		});
				// });
			},
		});

		// APP.validate(form3, {
		// 	isAjax: true,
		// 	onComplete: function (data) {
		// 		loading.show();
		// 		grecaptcha.ready(function () {
		// 			grecaptcha
		// 				.execute('6LcCEBgnAAAAAJ_0NFiVTQSlpDYZ35ypza8kH-Qv', { action: 'submit' })
		// 				.then(function (token) {
		// 					// loading.hide();
		// 					dataSubmit = { ...dataSubmit, ...data, g_token: token };
		// 					delete dataSubmit.agree;
		// 					delete dataSubmit.form__agree;
		// 					// console.log(dataSubmit);
		// 					const files = croppers.map((item) => {
		// 						return item.getCroppedCanvas().toDataURL('image/jpeg');
		// 					});
		// 					const dataUpload = {
		// 						note: dataSubmit.event_code,
		// 						files,
		// 						id_user: dataSubmit.ticket_code,
		// 						// files: [file1, file2],
		// 					};
		// 					function submitForm() {
		// 						const formData = new URLSearchParams();
		// 						for (let key in dataSubmit) {
		// 							formData.append(key, dataSubmit[key]);
		// 						}
		// 						if (!dataSubmit.file_id) {
		// 							formData.append('file_id', '');
		// 						}
		// 						fetch(`${api}/crm/customer/${checkinData.data_website.id}`, {
		// 							method: 'PUT',
		// 							body: formData,
		// 						})
		// 							.then((res) => res.json())
		// 							.then((data) => {
		// 								loading.hide();
		// 								if (data.result === 'success') {
		// 									form3.find('.form__error--response').html('');
		// 									// if(data.item.orderIdTestDrive){
		// 									// 	thankYou.find('.thank-you__stt').html(' '+data.item.orderIdTestDrive);
		// 									// }
		// 									if (data.item.orderIdTestDriveName) {
		// 										thankYou
		// 											.find('.thank-you__sttname')
		// 											.html(' ' + data.item.orderIdTestDriveName);
		// 									}
		// 									TweenMax.to(thankYou, 0.4, {
		// 										autoAlpha: 1,
		// 										display: 'block',
		// 										ease: Power1.easeOut,
		// 										onComplete: function () {},
		// 									});
		// 								} else {
		// 									form3.find('.form__error--response').html(data.errorMessage);
		// 								}
		// 							});
		// 					}
		// 					if (files.length === 0) {
		// 						submitForm();
		// 					} else {
		// 						fetch(`${api}/file/upload`, {
		// 							method: 'POST',
		// 							headers: {
		// 								'Content-Type': 'application/json',
		// 								lang: _lang,
		// 							},
		// 							body: JSON.stringify(dataUpload),
		// 						})
		// 							.then((res) => res.json())
		// 							.then((data) => {
		// 								if (data.result === 'success') {
		// 									form1.find('.form__error--response').html('');
		// 									const fileIds = data.files.map((item) => item.id).join(',');
		// 									dataSubmit = { ...dataSubmit, file_id: fileIds };
		// 									// console.log(dataSubmit);
		// 									submitForm();
		// 								} else {
		// 									form3.find('.form__error--response').html(data.errorMessage);
		// 								}
		// 							});
		// 					}
		// 				});
		// 		});
		// 		return false;
		// 	},
		// });
		// File preview
		licenceFile1.on('change', async function (e) {
			const preview = licenceFile1.parent().find('.image-preview');
			preview.html('');
			croppers = [];
			[...e.target.files].map((file) => {
				const previewItem = $(`<div class="image-preview-item" style="padding: 1vw 0;"></div>`);
				const image = new Image();
				image.src = URL.createObjectURL(file);
				previewItem.append(image);
				preview.append(previewItem);
				function onLoadImage() {
					const cropper = new Cropper(image, {
						viewMode: 3,
						zoomOnTouch: false,
						zoomable: false,
						zoomOnWheel: false,
						autoCrop: true,
						aspectRatio: 3 / 2,
						responsive: false,
						// crop(event) {
						// 	const data = cropper.getCroppedCanvas().toDataURL('image/jpeg');
						// console.log(data);
						// },
					});
					croppers.push(cropper);
					image.removeEventListener('load', onLoadImage);
				}
				image.addEventListener('load', onLoadImage);
			});
		});

		// Back buttons
		backButtons.on('click', function (e) {
			animForm(true);
			e.preventDefault();
		});

		thankYou.find('.btn-close').on('click', function (e) {
			TweenMax.to(thankYou, 0.4, {
				autoAlpha: 0,
				display: 'none',
				ease: Power1.easeOut,
				onComplete: function () {},
			});
			// remove param ticket_code
			const url = new URL(window.location.href);
			url.searchParams.delete('ticket_code');
			window.location.href = url.href;
		});
	}

	function renderSelectSale(data) {
		let items = [];
		if (data.length > 0) {
			data.forEach((item) => {
				items.push(`<option value="${item.brokerName}">${item.brokerName}</option>`);
			});
		}

		nvbhSelect.html(items.join(''));
		nvbhSelect.select2('destroy');
		nvbhSelect.select2({
			with: 'resolve',
			minimumResultsForSearch: -1,
			//searchInputPlaceholder: chooseExtendSelectPlaceholder,
			templateSelection: function (data, container) {
				if (data.id !== '') {
					$(container).addClass('select2-selection__rendered--value');
				} else {
					$(container).removeClass('select2-selection__rendered--value');
				}
				return data.text;
			},
		});

		nvbhSelect.on('change', function () {
			const obj = $(this);
			const val = obj.val();
			console.log(val === dataSubmitLast.sales_consultant);
			if (val === dataSubmitLast.sales_consultant) {
				dataSubmitLast.crm_customer_id = val;
			}
		});
	}

	function renderCustomer(data) {
		if (data.name) customerName.html(' ' + data.name);
		if (data.phone) {
			customerPhoneLabel.show();
			customerPhoneValue.html(' ' + data.phone + '<br/>');
		} else {
			customerPhoneLabel.hide();
		}
		if (data.email) {
			customerEmailLabel.show();
			customerEmailValue.html(' ' + data.email + '<br/>');
		} else {
			customerEmailLabel.hide();
		}
		if (data.dealerShortName) {
			customerAgencyLabel.show();
			customerAgencyValue.html(' ' + data.dealerShortName + '<br/>');
		} else {
			customerAgencyLabel.hide();
		}
	}

	init();
};
