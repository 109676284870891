APP.registerOne = function () {
	const el = $('.register-one');
	const thankYou = $('.thank-you');
	const loading = el.find('.loading');
	const selectDealer = el.find('[name="dealer_id"]');
	// const selectDealerOption = selectDealer.data('label-dealer');
	// const selectDealerInputPlaceholder = selectDealer.data('input-placeholder');
	const modalOTP = $('.modal--otp');
	const btnLink = modalOTP.find('.btn--link');
	const btnCount = btnLink.find('.btn__count');
	const otpCode = $('[name="otp_code"]');
	const slickCarChoose = el.find('.slick--car-choose');
	const modelId = el.find('[name="model_id"]');
	const modelName = el.find('[name="model_name"]');
	const selectVariant = el.find('[name="variant_id"]');
	const selectVariantOption = selectVariant.data('label-text');
	const selectVariantInputPlaceholder = selectVariant.data('input-placeholder');
	let isVariant = true;
	let timeLeft = 60;
	let timerId;
	let token = '';

	function init() {
		if (el.length === 0) return;

		slickCarChoose.slick({
			infinite: true,
			centerMode: true,
			centerPadding: '180px',
			slidesToShow: 3,
			slidesToScroll: 3,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						arrows: false,
						infinite: true,
						centerMode: true,
						centerPadding: '60px',
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		});

		slickCarChoose.on('swipe', function () {
			handleCarChoose();
		});

		slickCarChoose.on('click', '.slick-active', onClickSlickItem);

		APP.validate('.form--register-one', {
			isAjax: true,
			onComplete: function (res) {
				const variant_name = selectVariant.find('option:selected').text();
				const dealer_name = selectDealer.find('option:selected').text();
				const dealer_crm_id = selectDealer.find('option:selected').data('dealer-crm-id');
				const data = {
					...res,
					variant_name,
					dealer_name,
					dealer_crm_id,
				};

				loading.show();
				fetch(`${api}/event/save_info`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						lang: _lang,
					},
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((data) => {
						loading.hide();

						if (data.result === 'success') {
							token = data.token;
							APP.modal('.modal--otp');
							timerId = setInterval(countdown, 1000);
							if (data.otp_code !== '') {
								otpCode.val(data.otp_code);
								otpCode.parent().addClass(CLASS._focus);
								setTimeout(() => {
									otpCode.focus();
								}, 100);
							}
						} else {
							$('.form__error--response').html(data.errorMessage);
						}
					})
					.catch((err) => {
						loading.hide();
						console.log(err);
					});
			},
		});

		APP.validate('.form--register-otp', {
			isAjax: true,
			onComplete: function (res) {
				const data = {
					...res,
				};
				loading.show();
				fetch(`${api}/event/verify_otp`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: token,
						lang: _lang,
					},
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((data) => {
						loading.hide();

						if (data.result === 'success') {
							$('.modal__close').trigger('click');
							el.addClass(CLASS._blur);
							gsap.set(thankYou, {
								display: 'block',
								alpha: 0,
							});

							gsap.to(thankYou, {
								duration: 0.4,
								alpha: 1,
								onComplete: function () {
									$('.thank-you__code .btn').attr(
										'href',
										`https://mitsubishi-motors.com.vn/qr?id=${data.crm_response.ticketCode}`
									);
									const thankYouPos = thankYou.position().top;
									APP.setScrollTopAnimation(thankYouPos);
								},
							});
						} else {
							$('.form__error--otp').html(data.errorMessage);
						}
					})
					.catch((err) => {
						loading.hide();
						console.log(err);
					});
			},
		});

		btnLink.on('click', function (evt) {
			evt.preventDefault();
			// resend api otp
			loading.show();
			fetch(`${api}/event/change_otp`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Authorization: token,
					lang: _lang,
				},
			})
				.then((res) => res.json())
				.then((data) => {
					loading.hide();
					if (data.result === 'success') {
						btnLink.addClass(CLASS._disabled);
						timerId = setInterval(countdown, 1000);
						if (data.otp_code !== '') {
							otpCode.val(data.otp_code);
							otpCode.parent().addClass(CLASS._focus);
							setTimeout(() => {
								otpCode.focus();
							}, 100);
						}
					}
				})
				.catch((err) => {
					loading.hide();
					console.log(err);
				});
		});
	}

	function onClickSlickItem(e) {
		e.stopPropagation();
		const index = $(this).data('slick-index');
		slickCarChoose.slick('slickGoTo', index);
		handleCarChoose();
	}

	function handleCarChoose() {
		const slickSlide = slickCarChoose.find('.slick-slide');
		const formGroup = slickCarChoose.next();
		const formError = formGroup.find('.form__error');
		const slickActiveCenter = slickCarChoose.find('.slick-active.slick-center');
		const title = slickActiveCenter.find('.title');
		const modelIdValue = parseInt(title.data('model-id'));
		const modelNameValue = title.text();
		slickSlide.removeClass(CLASS._selected);
		slickActiveCenter.addClass(CLASS._selected);
		formGroup.removeClass(CLASS._error).removeClass(CLASS._focus);
		formError.text('');
		modelId.val(modelIdValue);
		modelName.val(modelNameValue);
		APP.getVariants(
			selectVariant,
			selectVariantOption,
			selectVariantInputPlaceholder,
			modelIdValue
		);
		// APP.getData(`${api}/wp/variant?model_id=${modelIdValue}`, function (items) {
		// 	renderVariantList(items);
		// });

		// $('.car-degrees').removeClass(CLASS._active);
		// $(`.car-degrees[data-model-id="${modelIdValue}"]`).addClass(CLASS._active);
	}

	function renderVariantList(data) {
		let items = data.items
			.map((item, idx) => item.acf.show_on_form && renderVariantItem(item, idx))
			.join('');
		$('.variant-box .row').html(items);
		$('.variant-choose').show();
		if (isVariant) {
			isVariant = false;
			APP.carDegrees('.car-degrees--model');
		}
	}

	function countdown() {
		if (timeLeft < 0) {
			clearTimeout(timerId);
			timeLeft = 180;
			btnLink.removeClass(CLASS._disabled);
			btnCount.html('180s');
		} else {
			btnCount.html(`${timeLeft}s`);
			timeLeft--;
		}
	}

	init();
};
