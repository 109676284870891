APP.dealerNetwork = function () {
	const el = $('.dealer-network');
	const loading = el.find('.loading');
	const dealerNetworkMain = el.find('.dealer-network__main');
	const dealerNetworkMobile = el.find('.dealer-network-mobile');
	const areaMenu = el.find('.region');
	const areaMenuList = areaMenu.find('.region__list');
	const areaSelect = el.find('[name="dealer-area"]');
	const labelRegion = areaMenu.data('label-region');
	const locationResult = el.find('.location-result');
	const labelLocation = locationResult.data('label-location');
	const location = el.find('.location');
	//const scrollbarMacosx = location.find('.scrollbar-macosx');
	const province = el.find('[name="province"]');
	const labelProvince = province.data('label-province');
	const placeholderProvince = province.data('input-placeholder');
	const dealerService = el.find('[name="dealer-service"]');
	const labelService = dealerService.data('label-service');
	const dealerCurrent = el.find('.dealer-current');
	const dealerCurrentAlertText = dealerCurrent.data('alert-text');

	const [getDealer, setDealer] = useDealer();
	const [getArea, setArea] = useArea();
	const [getProvince, setProvince] = useProvince();
	const [getService, setService] = useSevice();
	const [getLocation, setLocation] = useLocation();
	const [getFilterDealer, setFilterDealer] = useFilterDealer();
	const [getCity, setCity] = useCity();

	function init() {
		if (el.length === 0) return;

		loading.show();
		//scrollbarMacosx.scrollbar();
		handleInitialLoad();

		province.on('change', handleSelectProvince);
		dealerService.on('change', handleSelectDealerService);
		dealerCurrent.on('click', handleCurrentLocation);

		navigator.permissions.query({ name: 'geolocation' }).then(async (permissionStatus) => {
			if (permissionStatus.state == 'granted') {
				await handleCurrentLocation();
			}
		});

		$('.dealer-back').on('click', function () {
			$('.dealer-network__find').show();
			$('.dealer-network-mobile').removeClass(CLASS._mobile);
			$('.dealer-network__detail').hide();
			renderProjectsToDom(getFilterDealer(), getCity());
		});
	}

	function useDealer() {
		let _dealer = null;

		function getDealer() {
			return _dealer;
		}

		function setDealer(data) {
			_dealer = [...data];
		}

		return [getDealer, setDealer];
	}

	function useArea() {
		let _area = null;

		function getArea() {
			return _area;
		}

		function setArea(data) {
			_area = [...data];
		}

		return [getArea, setArea];
	}

	function useProvince() {
		let _province = null;

		function getProvince() {
			return _province;
		}

		function setProvince(data) {
			_province = [...data];
		}

		return [getProvince, setProvince];
	}

	function useSevice() {
		let _service = null;

		function getService() {
			return _service;
		}

		function setService(data) {
			_service = [...data];
		}

		return [getService, setService];
	}

	function useLocation() {
		let _location = null;

		function getLocation() {
			return _location;
		}

		function setLocation(data) {
			_location = { ...data };
		}

		return [getLocation, setLocation];
	}

	function useFilterDealer() {
		let _filterDealer = null;

		function getFilterDealer() {
			return _filterDealer;
		}

		function setFilterDealer(data) {
			_filterDealer = [...data];
		}

		return [getFilterDealer, setFilterDealer];
	}

	function useCity() {
		let _city = null;

		function getCity() {
			return _city;
		}

		function setCity(data) {
			_city = data;
		}

		return [getCity, setCity];
	}

	function getCurrentLocation() {
		return new Promise((response) => {
			navigator.geolocation.getCurrentPosition(
				(position) => {
					const pos = {
						lat: position.coords.latitude,
						lng: position.coords.longitude,
					};

					let geocoder;
					geocoder = new google.maps.Geocoder();
					const latlng = new google.maps.LatLng(pos.lat, pos.lng);

					geocoder.geocode({ latLng: latlng }, (results, status) => {
						loading.hide();
						if (status == google.maps.GeocoderStatus.OK) {
							const data = {
								currentLocation: results[0].formatted_address.split(',')[3],
								lat: pos.lat,
								lng: pos.lng,
							};
							response(data);
						} else {
							const data = {
								currentLocation: '',
								lat: 0,
								lng: 0,
							};
							response(data);
						}
					});
				},
				() => {
					const data = {
						currentLocation: '',
						lat: 0,
						lng: 0,
					};
					response(data);
				}
			);
		});
	}

	function getDealerServices(data) {
		const dealerServices = [...new Set(data.map((item) => item['dealer_service']))];
		const merged = [].concat.apply([], dealerServices);
		const dealerServicesMerged = [
			...new Map(merged.map((merge) => [merge['name'], merge])).values(),
		];
		return dealerServicesMerged;
	}

	function renderLocationItem(data, num) {
		const {
			id,
			distance,
			dealer_service,
			acf: { address, company_name },
		} = data;

		return `
			<div class="location__item" data-location-id="${id}" data-location-num="${num}">
				<i class="icon icon--location-black">${num}</i>
				<div class="location__body">
					<h3 class="title title--6">${company_name}</h3>
					<div class="location__address">${address}</div>
					<div class="location__text">
					${dealer_service.map((ds) => `<span>${ds.name}</span>`).join('')}
					</div>
				</div>
				${distance !== undefined ? `<div class="location__km">${distance}</div>` : ''}
			</div>
		`;
	}

	function locationItemDetail(item) {
		renderMapDetail(item);
		const dealerDetail = $('.dealer-detail');

		const dealerKMText = dealerDetail.find('.dealer-km__text');
		const dealerKMTextIcon = dealerDetail.find('.icon--location-red');

		const locationDetail = dealerDetail.find('.location-detail');
		const locationDetailTitle = locationDetail.find('.title');
		const locationDetailAddress = locationDetail.find('.location-detail__address');
		const locationDetailServices = locationDetail.find('.location-detail__text');
		const locationDetailDirection = locationDetail.find('.btn');
		const locationServices = item.dealer_service.map((ds) => {
			return `<span>${ds.name}</span>`;
		});

		const dealerContact = dealerDetail.find('.dealer-contact');
		const dealerContactMail = dealerContact.find('.dealer-contact__mail');
		const dealerContactMailHTML = `<a href="mailto:${item.acf.email}" target="_blank">${item.acf.email}</a>`;

		const dealerContactPhone = dealerContact.find('.dealer-contact__phone');
		const dealerContactPhoneSaleText = dealerContactPhone.data('phone-sale');
		const dealerContactPhoneServiceText = dealerContactPhone.data('phone-service');
		let dealerContactPhoneSaleList = '';
		let dealerContactPhoneServiceList = '';

		if (item.acf.phone_sales.length > 0) {
			dealerContactPhoneSaleList = item.acf.phone_sales.map((item) => {
				return `<a href="tel:${item.phone_sale}">${item.phone_sale} (${dealerContactPhoneSaleText})</a>`;
			});
		}
		if (item.acf.phone_services.length > 0) {
			dealerContactPhoneServiceList = item.acf.phone_services.map((item) => {
				return `<a href="tel:${item.phone_service}" target="_blank">${item.phone_service} (${dealerContactPhoneServiceText})</a>`;
			});
		}

		const dealerContactWeb = dealerContact.find('.dealer-contact__web');
		const dealerContactWebHTML = `<a href="${item.acf.website}" target="_blank">${item.acf.website}</a>`;

		const dealerTime = dealerDetail.find('.dealer-time');
		const dealerTimeRow = dealerTime.find('.row');
		const dealerTimeRowCol = item.acf.service_items.map((service_item) => {
			return `<div class="row__col row__col--lg-6">
				<h3 class="title title--6">${service_item['\u001dservice_name']}</h3>
				${service_item.working_items
					.map(
						(working_item) =>
							`<p><span>${working_item.working_date}</span><span>${working_item.working_hour}</span></p>`
					)
					.join('')}
			</div>`;
		});

		if (item.distance !== undefined) dealerKMText.html(item.distance);
		if (item.number) dealerKMTextIcon.html(item.number);

		if (item.acf.company_name) locationDetailTitle.html(item.acf.company_name);
		if (item.acf.address) locationDetailAddress.html(item.acf.address);
		locationDetailServices.html(locationServices);
		locationDetailDirection.attr(
			'href',
			`http://www.google.com/maps/place/${item.acf.dealer_location.lat},${item.acf.dealer_location.lng}`
		);

		if (item.acf.email)
			dealerContactMail.html(`<i class="icon icon--mail"></i> ${dealerContactMailHTML}`);
		if (item.acf.phone_sales.length > 0 || item.acf.phone_services.length > 0)
			dealerContactPhone.html(
				`<i class="icon icon--phone"></i> ${dealerContactPhoneSaleList} ${dealerContactPhoneServiceList}`
			);
		dealerContactWeb.html(`<i class="icon icon--lap"></i> ${dealerContactWebHTML}`);

		dealerTimeRow.html(dealerTimeRowCol);
	}

	function renderSelectProvince(data) {
		let items = [`<option selected value="">${labelProvince}</option>`];
		if (data.length > 0) {
			data.forEach((item) => {
				items.push(`<option value="${item.slug}">${item.name}</option>`);
			});
		}

		province.html(items.join(''));
		province.select2('destroy');
		province.select2({
			with: 'resolve',
			searchInputPlaceholder: placeholderProvince,
			templateSelection: function (data, container) {
				if (data.id !== '') {
					$(container).addClass('select2-selection__rendered--value');
				} else {
					$(container).removeClass('select2-selection__rendered--value');
				}
				return data.text;
			},
		});
	}

	function renderSelectDealerService(data) {
		let items = [`<option selected value="">${labelService}</option>`];

		data.forEach((item) => {
			items.push(`<option value="${item.slug}">${item.name}</option>`);
		});

		dealerService.html(items.join(''));
		dealerService.select2('destroy');
		dealerService.select2({
			minimumResultsForSearch: -1,
			with: 'resolve',
			templateSelection: function (data, container) {
				if (data.id !== '') {
					$(container).addClass('select2-selection__rendered--value');
				} else {
					$(container).removeClass('select2-selection__rendered--value');
				}
				return data.text;
			},
		});
	}

	function renderProjectsToDom(data, city) {
		let items = data.map((item, index) => renderLocationItem(item, index + 1)).join('');
		if (city === '' || city === undefined || city === null) {
			city = labelRegion;
		} else {
			city = city;
		}
		renderMap(data);
		locationResult.html(`${data.length} ${labelLocation}`);
		$('.location__list').html(items);

		$('.location__item').on('click', function () {
			const obj = $(this);
			const locationID = obj.data('location-id');
			const filteredItems = data.filter((item) => item.id === locationID);
			locationItemDetail(filteredItems[0]);
			$('.dealer-network__find').hide();
			$('.dealer-network-mobile').addClass(CLASS._mobile);
			$('.dealer-network__detail').show();
			APP.setScrollTopAnimation(0);
		});
	}

	function renderMap(data) {
		const myOptions = {
			zoom: 4,
			mapTypeId: google.maps.MapTypeId.ROADMAP,
		};

		const map = new google.maps.Map(document.getElementById('map'), myOptions);
		const map2 = new google.maps.Map(document.getElementById('map2'), myOptions);

		const bounds = new google.maps.LatLngBounds();

		let locations = [];

		data.map((item) => {
			locations.push([
				item.title,
				item.acf.dealer_location.lat,
				item.acf.dealer_location.lng,
				item.id,
			]);
		});

		for (let i = 0; i < locations.length; i++) {
			const location = locations[i];
			const position = new google.maps.LatLng(location[1], location[2]);
			bounds.extend(position);
			const marker1 = new google.maps.Marker({
				animation: google.maps.Animation.DROP,
				map: map,
				position: position,
				title: location[0],
				label: {
					text: `${i + 1}`,
					className: 'marker-label',
				},
			});

			google.maps.event.addListener(
				marker1,
				'click',
				(function (i) {
					return function () {
						const locationID = locations[i][3];
						const filteredItems = data.filter((item) => item.id === locationID);
						locationItemDetail(filteredItems[0]);
						$('.dealer-network__find').hide();
						$('.dealer-network-mobile').addClass(CLASS._mobile);
						$('.dealer-network__detail').show();
					};
				})(i)
			);
		}

		for (let i = 0; i < locations.length; i++) {
			const location2 = locations[i];
			const position2 = new google.maps.LatLng(location2[1], location2[2]);
			bounds.extend(position2);
			const marker2 = new google.maps.Marker({
				animation: google.maps.Animation.DROP,
				map: map2,
				position: position2,
				title: location2[0],
				label: {
					text: `${i + 1}`,
					className: 'marker-label',
				},
			});

			google.maps.event.addListener(
				marker2,
				'click',
				(function (i) {
					return function () {
						const locationID = locations[i][3];
						const filteredItems = data.filter((item) => item.id === locationID);
						locationItemDetail(filteredItems[0]);
						$('.dealer-network__find').hide();
						$('.dealer-network-mobile').addClass(CLASS._mobile);
						$('.dealer-network__detail').show();
					};
				})(i)
			);
		}

		map.fitBounds(bounds);
		map2.fitBounds(bounds);
	}

	function renderMapDetail(item) {
		const location = [
			item.title,
			item.acf.dealer_location.lat,
			item.acf.dealer_location.lng,
			item.id,
		];

		const myOptions = {
			zoom: 16,
			center: { lat: location[1], lng: location[2] },
		};

		const map = new google.maps.Map(document.getElementById('map'), myOptions);
		const map1 = new google.maps.Map(document.getElementById('map1'), myOptions);
		var marker = new google.maps.Marker({
			animation: google.maps.Animation.DROP,
			map: map,
			position: { lat: location[1], lng: location[2] },
			title: location[0],
			label: {
				text: `${item.number}`,
				className: 'marker-label',
			},
		});

		var marker1 = new google.maps.Marker({
			animation: google.maps.Animation.DROP,
			map: map1,
			position: { lat: location[1], lng: location[2] },
			title: location[0],
			label: {
				text: `${item.number}`,
				className: 'marker-label',
			},
		});

		google.maps.event.addListener(
			marker,
			'click',
			(function () {
				return function () {
					locationItemDetail(item);
					$('.dealer-network__find').hide();
					$('.dealer-network-mobile').addClass(CLASS._mobile);
					$('.dealer-network__detail').show();
				};
			})()
		);

		google.maps.event.addListener(
			marker1,
			'click',
			(function () {
				return function () {
					locationItemDetail(item);
					$('.dealer-network__find').hide();
					$('.dealer-network-mobile').addClass(CLASS._mobile);
					$('.dealer-network__detail').show();
				};
			})()
		);
	}

	function renderAreas(data) {
		let menuItems = [`<div class="region__item is-active" data-area-id="">${labelRegion}</div>`];
		let items = [`<option selected value="">${labelRegion}</option>`];

		data.forEach((item) => {
			menuItems.push(`<div class="region__item" data-area-id="${item.id}">${item.name}</div>`);
			items.push(`<option value="${item.id}">${item.name}</option>`);
		});

		areaMenuList.html(menuItems.join(''));
		areaSelect.html(items.join(''));
		areaSelect.select2('destroy');
		areaSelect.select2({
			minimumResultsForSearch: -1,
			with: 'resolve',
			templateSelection: function (data, container) {
				if (data.id !== '') {
					$(container).addClass('select2-selection__rendered--value');
				} else {
					$(container).removeClass('select2-selection__rendered--value');
				}
				return data.text;
			},
		});

		const areaMenuItem = areaMenuList.find('.region__item');

		areaMenuItem.on('click', handleClickArea);
		areaSelect.on('change', handleChangeArea);
	}

	function handleClickArea(e) {
		const obj = $(this);
		const areaId = obj.data('area-id');
		const city = obj.text();

		const filteredProvinceItems = getProvince().filter((item) =>
			areaId === '' ? item : item.parent === areaId
		);

		let filteredItems = getDealer().filter((item) =>
			areaId === '' ? item : item.location_network.area.id === areaId
		);

		filteredItems.sort((a, b) => {
			const first = a.distanceValue;
			const second = b.distanceValue;
			return first > second ? 1 : first < second ? -1 : 0;
		});

		filteredItems = filteredItems.map((item, idx) => ({ ...item, number: idx + 1 }));

		dealerCurrent.removeClass(CLASS._active);

		//console.log(filteredItems);

		setFilterDealer(filteredItems);
		setCity(city);

		const dealerServices = getDealerServices(filteredItems);

		areaSelect.val(areaId);
		areaSelect.select2('destroy');
		areaSelect.select2({
			with: 'resolve',
			templateSelection: function (data, container) {
				if (data.id !== '') {
					$(container).addClass('select2-selection__rendered--value');
				} else {
					$(container).removeClass('select2-selection__rendered--value');
				}
				return data.text;
			},
		});

		$('.region__item').removeClass(CLASS._active);
		obj.addClass(CLASS._active);

		renderProjectsToDom(filteredItems, city);
		renderSelectProvince(filteredProvinceItems);
		renderSelectDealerService(dealerServices);
	}

	function handleChangeArea(e) {
		const obj = $(this);
		const areaId = obj.val() ? parseInt(obj.val()) : '';
		const city = obj.find('option:selected').text();

		const filteredProvinceItems = getProvince().filter((item) =>
			areaId === '' ? item : item.parent === areaId
		);

		let filteredItems = getDealer().filter((item) =>
			areaId === '' ? item : item.location_network.area.id === areaId
		);

		filteredItems.sort((a, b) => {
			const first = a.distanceValue;
			const second = b.distanceValue;
			return first > second ? 1 : first < second ? -1 : 0;
		});

		filteredItems = filteredItems.map((item, idx) => ({ ...item, number: idx + 1 }));

		dealerCurrent.removeClass(CLASS._active);

		setFilterDealer(filteredItems);
		setCity(city);

		const dealerServices = getDealerServices(filteredItems);

		$('.region__item').removeClass(CLASS._active);
		$('.region__item[data-value="' + areaId + '"]').addClass(CLASS._active);

		renderProjectsToDom(filteredItems, city);

		renderSelectProvince(filteredProvinceItems);
		renderSelectDealerService(dealerServices);
	}

	function handleSelectProvince(e) {
		const obj = $(this);
		const value = obj.val();
		const dealerServiceValue = dealerService.val();
		const city = obj.find('option:selected').text();
		const areaId = areaSelect.val() !== '' ? parseInt(areaSelect.val()) : areaSelect.val();

		let filteredItems = getDealer().filter((item) => {
			if (value === '') {
				if (areaId === '' && dealerServiceValue === '') {
					return item;
				} else if (areaId !== '' && dealerServiceValue === '') {
					return item.location_network.area.id === areaId;
				} else if (areaId === '' && dealerServiceValue !== '') {
					return item.dealer_service.find((ds) => ds.slug === dealerServiceValue);
				} else {
					return (
						item.location_network.area.id === areaId &&
						item.dealer_service.find((ds) => ds.slug === dealerServiceValue)
					);
				}
			} else {
				if (areaId === '' && dealerServiceValue === '') {
					return item.location_network.province.slug === value;
				} else if (areaId !== '' && dealerServiceValue === '') {
					return (
						item.location_network.province.slug === value &&
						item.location_network.area.id === areaId
					);
				} else if (areaId === '' && dealerServiceValue !== '') {
					return (
						item.location_network.province.slug === value &&
						item.dealer_service.find((ds) => ds.slug === dealerServiceValue)
					);
				} else {
					return (
						item.location_network.area.id === areaId &&
						item.dealer_service.find((ds) => ds.slug === dealerServiceValue) &&
						item.location_network.province.slug === value
					);
				}
			}
		});

		filteredItems.sort((a, b) => {
			const first = a.distanceValue;
			const second = b.distanceValue;
			return first > second ? 1 : first < second ? -1 : 0;
		});

		filteredItems = filteredItems.map((item, idx) => ({ ...item, number: idx + 1 }));

		dealerCurrent.removeClass(CLASS._active);

		setFilterDealer(filteredItems);
		setCity(city);

		renderProjectsToDom(filteredItems, city);
	}

	function handleSelectDealerService(e) {
		const obj = $(this);
		const value = obj.val();
		const provinceValue = province.val();
		const areaId = areaSelect.val() !== '' ? parseInt(areaSelect.val()) : areaSelect.val();
		const city = province.val()
			? province.find('option:selected').text()
			: $('.region__item').hasClass(CLASS._active)
			? areaSelect.find('option:selected').text()
			: getLocation().currentLocation;

		let filteredItems = getDealer().filter((item) => {
			if (value === '') {
				if (areaId === '' && provinceValue === '') {
					return item;
				} else if (areaId !== '' && provinceValue === '') {
					return item.location_network.area.id === areaId;
				} else if (areaId === '' && provinceValue !== '') {
					return item.location_network.province.slug === provinceValue;
				} else {
					return (
						item.location_network.area.id === areaId &&
						item.location_network.province.slug === provinceValue
					);
				}
			} else {
				if (areaId === '' && provinceValue === '') {
					return item.dealer_service.find((ds) => ds.slug === value);
				} else if (areaId !== '' && provinceValue === '') {
					return (
						item.location_network.area.id === areaId &&
						item.dealer_service.find((ds) => ds.slug === value)
					);
				} else if (areaId === '' && provinceValue !== '') {
					return (
						item.location_network.province.slug === provinceValue &&
						item.dealer_service.find((ds) => ds.slug === value)
					);
				} else {
					return (
						item.location_network.area.id === areaId &&
						item.location_network.province.slug === provinceValue &&
						item.dealer_service.find((ds) => ds.slug === value)
					);
				}
			}
		});

		filteredItems.sort((a, b) => {
			const first = a.distanceValue;
			const second = b.distanceValue;
			return first > second ? 1 : first < second ? -1 : 0;
		});

		filteredItems = filteredItems.map((item, idx) => ({ ...item, number: idx + 1 }));

		dealerCurrent.removeClass(CLASS._active);

		setFilterDealer(filteredItems);
		setCity(city);

		renderProjectsToDom(filteredItems, city);
	}

	async function getData(url) {
		const response = await fetch(url, {
			headers: {
				'Content-Type': 'application/json',
				lang: _lang,
			},
		});

		const res = await response.json();

		if (!res.errorCode) {
			setTimeout(() => {
				loading.hide();
				dealerNetworkMain.show();
				dealerNetworkMobile.removeClass(CLASS._mobile);
			}, 500);
		} else {
			loading.hide();
		}

		return res;
	}

	async function addDistance() {
		const dealers = getDealer();
		const currentLocation = getLocation();
		const curLocation = {
			lat: currentLocation.lat,
			lng: currentLocation.lng,
		};

		if (curLocation.lat !== 0 && curLocation.lng !== 0) {
			dealers.map(async (dealer) => {
				const posLocation = dealer.acf.dealer_location;
				// const distanceKM = await getDistanceLocation(curLocation, posLocation);
				// if (distanceKM.rows[0].elements[0].status !== 'ZERO_RESULTS') {
				// 	dealer.distance = distanceKM.rows[0].elements[0].distance.text;
				// 	dealer.distanceValue = distanceKM.rows[0].elements[0].distance.value;
				// }
				const distanceKMNew = haversine_distance(curLocation, posLocation);
				if (distanceKMNew) {
					dealer['distance'] = distanceKMNew;
					dealer['distanceValue'] = distanceKMNew;
				}
			});
		} else {
			APP.toasts({
				message: dealerCurrentAlertText,
				onComplete: function () {
					loading.hide();
				},
			});
		}
	}

	function haversine_distance(mk1, mk2) {
		var R = 3958.8;
		var rlat1 = mk1.lat * (Math.PI / 180);
		var rlat2 = mk2.lat * (Math.PI / 180);
		var difflat = rlat2 - rlat1;
		var difflon = (mk2.lng - mk1.lng) * (Math.PI / 180);

		var d =
			2 *
			R *
			Math.asin(
				Math.sqrt(
					Math.sin(difflat / 2) * Math.sin(difflat / 2) +
						Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflon / 2) * Math.sin(difflon / 2)
				)
			);
		return d;
	}

	function sortByKey(array, key) {
		return array.sort(function (a, b) {
			const x = a[key];
			const y = b[key];
			return x < y ? -1 : x > y ? 1 : 0;
		});
	}

	async function handleCurrentLocation() {
		loading.show();
		setTimeout(async () => {
			$(this).addClass(CLASS._active);
			if (getLocation() === null || getLocation().lat === 0 || getLocation().lng === 0) {
				// loading.show();
				// const currentLocation = await getCurrentLocation();
				// setLocation(currentLocation);
				const position = await getLocationCurrent();
				const curLocation = { lat: position.coords.latitude, lng: position.coords.longitude };
				setLocation(curLocation);
				await addDistance();
			}
			const curLocation = getLocation();
			//console.log(curLocation);
			if (curLocation.lat !== 0 && curLocation.lng !== 0) {
				const data = getDealer();

				const filteredItems = data.filter((item) => item.distanceValue <= 50000);
				if (filteredItems.length > 0) {
					filteredItems.sort((a, b) => {
						const first = a.distanceValue;
						const second = b.distanceValue;
						return first > second ? 1 : first < second ? -1 : 0;
					});
					setFilterDealer(filteredItems);
					setCity(getLocation().currentLocation);
					renderProjectsToDom(filteredItems, getLocation().currentLocation);
					$('.region__item').removeClass(CLASS._active);
					let dealerData = sortByKey(getDealer(), 'distanceValue');
					const provice = dealerData[0].location_network.province.slug;
					//console.log(provice);
					province.val(provice).trigger('change');
					dealerService.val('');
				}
			}
			loading.hide();
		}, 1000);
	}

	async function handleInitialLoad() {
		const data = await getData(`${api}/wp/dealerNetwork`);
		let filterItems = data.items.map((item, idx) => ({ ...item, number: idx + 1 }));
		setDealer(filterItems);
		setFilterDealer(filterItems);
		setArea(data.area);
		setProvince(data.province);
		setService(data.sevices);
		setCity(areaSelect.find('option:selected').text());

		renderAreas(getArea());
		renderSelectProvince(getProvince());
		renderSelectDealerService(getService());

		setTimeout(() => {
			renderProjectsToDom(getDealer(), getLocation());
		}, 1000);
	}

	function getLocationCurrent() {
		return new Promise(function (resolve, reject) {
			return navigator.geolocation.getCurrentPosition(resolve, reject);
		});
	}

	init();
};
