APP.conceptCarAtivation = function () {
	function init() {
		if (!document.getElementById('event_schedule')) return;
		const regionList = $('.region__item');
		const regionSelect = $('.region__select select');
		const locationSelect = $('.region__location select');
		const locationSelectOptions = $('.region__location select option');
		const icons = $('.activation-route .list li');
		const iconContents = $('.activation-route .list li .content');
		// Filter icons by region
		regionList
			.on('click', function () {
				const region = $(this).data('vung');
				regionList.removeClass('is-active');
				$(this).addClass('is-active');
				if (region === 'all') {
					icons.removeClass('active').fadeIn().eq(0).addClass('active');
				} else {
					icons.fadeOut().removeClass('active');
					icons.filter(`[data-vung="${region}"]`).fadeIn().eq(0).addClass('active');
				}
			})
			.eq(0)
			.trigger('click');

		const locationSelectData = locationSelectOptions.map(function () {
			if ($(this).val() !== '') {
				return {
					value: $(this).val(),
					text: $(this).text(),
					vung: $(this).val().split('-')[0],
				};
			}
		});

		console.log(locationSelectData);

		// Filter location select option by region
		regionSelect.on('change', function () {
			const region = $(this).val();
			locationSelect.empty();
			locationSelect.append(`<option value="">Tất cả</option>`);
			if (region === 'all') {
				locationSelectData.each(function () {
					locationSelect.append(`<option value="${this.value}">${this.text}</option>`);
				});
				icons.show();
			} else {
				locationSelectData.each(function () {
					if (this.vung === region) {
						locationSelect.append(`<option value="${this.value}">${this.text}</option>`);
					}
				});
				icons.hide().removeClass('active').find('.item').slideUp();
				icons
					.filter(`[data-vung="${region}"]`)
					.show()
					.eq(0)
					.addClass('active')
					.find('.item')
					.slideDown();
			}
		});

		// Toggle icon content on click content title
		iconContents.find('.title').on('click', function () {
			const content = $(this).parent();
			const contentBody = content.find('.item');
			content.toggleClass('is-active');
			// iconContents.find('.item').not(contentBody).slideUp();
			contentBody.slideToggle();
		});

		// Fitle icon content by location select
		locationSelect.on('change', function () {
			const location = $(this).val().split('-')[1];
			const region = regionSelect.val();
			icons.hide().removeClass('active').find('.item').slideUp();
			console.log(region);
			if (region === 'all' && !location) {
				icons.show();
			} else {
				if (!location) {
					icons
						.filter(`[data-vung="${region}"]`)
						.show()
						.eq(0)
						.addClass('active')
						.find('.item')
						.slideDown();
				} else {
					icons
						.filter(`[data-tinh="${location}"]`)
						.fadeIn()
						.eq(0)
						.addClass('active')
						.find('.item')
						.slideDown();
				}
			}
		});

		// Toggle icon detail
		icons.on('click', function (e) {
			const obj = $(this);
			icons.removeClass('active');
			obj.addClass('active');
		});
	}
	init();
};
