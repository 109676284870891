APP.navigation = function () {
	let lastScrollTop = 0;
	const footer = $('.footer');
	const ctaContact = $('.cta-contact');
	const navigation = footer.find('.navigation');
	//navigation.addClass(CLASS._sticky);
	//ctaContact.addClass(CLASS._sticky);

	WINDOW.on('scroll', function () {
		const scrollTop = $(this).scrollTop();
		const winH = $(this).height();
		const footerY = footer.length > 0 ? footer.offset().top : 0;
		const navigationH = navigation.height();

		if (lastScrollTop <= 0) {
			lastScrollTop = 0;
		}

		if (scrollTop + winH >= footerY + navigationH) {
			navigation.addClass(CLASS._docked);
			ctaContact.addClass(CLASS._docked);
		}

		if (scrollTop + winH < footerY) {
			if (scrollTop > lastScrollTop) {
				navigation.addClass(CLASS._sticky);
				ctaContact.addClass(CLASS._sticky);
			} else {
				navigation.removeClass(CLASS._sticky);
				ctaContact.removeClass(CLASS._sticky);
				setTimeout(function () {
					navigation.removeClass(CLASS._docked);
					ctaContact.removeClass(CLASS._docked);
				}, 300);
			}

			lastScrollTop = scrollTop;
		}
	});
};
