APP.specification = function () {
	const el = $('.specification');
	const slickSpecification = el.find('.slick--specification');

	function init() {
		if (el.length === 0) return;

		slickSpecification.slick({
			infinite: true,
			arrows: false,
			slidesToShow: 4,
			slidesToScroll: 1,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		});
	}

	init();
};
