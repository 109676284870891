const APP = {};

const DOCUMENT = $(document);
const WINDOW = $(window);
const BODY = $(document.body);
const MAIN = $('.main');

let WIN_WIDTH = WINDOW.width();
let WIN_HEIGHT = WINDOW.height();
let DOC_WIDTH = DOCUMENT.width();
let DOC_HEIGHT = DOCUMENT.height();

const CLASS = {
	_desktop: 'is-desktop',
	_tablet: 'is-tablet',
	_mobile: 'is-mobile',
	_portrait: 'is-portrait',
	_landscape: 'is-landscape',
	_active: 'is-active',
	_current: 'is-current',
	_valid: 'is-valid',
	_error: 'is-error',
	_focus: 'is-focus',
	_filled: 'is-filled',
	_noscroll: 'is-noscroll',
	_move: 'is-move',
	_open: 'is-open',
	_show: 'is-show',
	_expand: 'is-expand',
	_animate: 'is-animate',
	_noscroll: 'is-noscroll',
	_sticky: 'is-sticky',
	_docked: 'is-docked',
	_played: 'is-played',
	_disabled: 'is-disabled',
	_loader: 'is-loader',
	_selected: 'is-selected',
	_blur: 'is-blur',
	_checked: 'is-checked',
	_scroll: 'is-scroll',
	_done: 'is-done',
};

const api = 'https://www.mitsubishi-motors.com.vn/webapi/v1';

const fetchDataGet = {
	method: 'GET',
	headers: {
		'Content-Type': 'application/json',
		lang: _lang,
	},
};
