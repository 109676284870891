APP.taber = function (element, options) {
	const el = $(element);
	const tabLink = el.find('.taber__link');
	const tabSelect = el.find('.taber__select');
	const tabContent = el.find('.taber__content');

	const settings = $.extend(
		{
			isHash: false,
			onComplete: function () {},
		},
		options
	);

	const init = function () {
		detect();
		tabLink.on('click', onClickTabLink);
		tabSelect.on('change', onChangeTabSelect);
	};

	const detect = function () {
		const hash = location.hash;

		if (hash && settings.isHash) {
			setTimeout(function () {
				$('.taber__link[href="' + hash + '"]').trigger('click');
			}, 100);
		} else {
			tabLink.eq(0).addClass(CLASS._active);
			tabContent.eq(0).addClass(CLASS._active);
		}
	};

	const onClickTabLink = function (evt) {
		evt.preventDefault();
		const obj = $(this);
		const tabShow = obj.attr('href');
		const link = location.origin + '/' + tabShow;

		tabLink.removeClass(CLASS._active);
		obj.addClass(CLASS._active);

		tabContent.removeClass(CLASS._active);
		$(tabShow).addClass(CLASS._active);

		$(`.taber__select option[value='${tabShow}']`).attr('selected', true);
		$('select').select2('destroy');
		$('select').select2({
			minimumResultsForSearch: -1,
			with: 'resolve',
			templateSelection: function (data, container) {
				if (data.id !== '') {
					$(container).addClass('select2-selection__rendered--value');
				} else {
					$(container).removeClass('select2-selection__rendered--value');
				}
				return data.text;
			},
		});

		if (settings.isHash) {
			location.href = link;
		}
	};

	const onChangeTabSelect = function (evt) {
		evt.preventDefault();
		const obj = $(this);
		const tabSelected = obj.find('option:selected');
		const tabShow = tabSelected.val();
		const link = location.origin + '/' + tabShow;

		tabLink.removeClass(CLASS._active);
		$(`.taber__link[href='${tabShow}']`).addClass(CLASS._active);

		tabContent.removeClass(CLASS._active);
		$(tabShow).addClass(CLASS._active);

		if (settings.isHash) {
			location.href = link;
		}
	};

	init();
};
