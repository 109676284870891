APP.carouselTabbed = function () {
	const el = $('.carousel-tabbed');
	const slickImage = el.find('.slick--cti');
	const slickText = el.find('.slick--ctt');
	const typeSelect = el.find('[name="type-text"]');
	const typeText = el.find('.type-text');
	const typeTextItem = typeText.find('.type-text__item');

	function init() {
		if (el.length === 0) return;

		slickImage.slick({
			infinite: true,
			arrows: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			speed: 500,
			fade: true,
			cssEase: 'ease',
			swipe: false,
		});

		slickText.slick({
			infinite: true,
			arrows: false,
			slidesToShow: 1,
			slidesToScroll: 1,
			speed: 500,
			fade: true,
			cssEase: 'ease',
			swipe: false,
		});

		typeTextItem.on('click', handleTypeText);
		typeSelect.on('change', handleTypeSelect);
	}

	function handleTypeText(e) {
		e.preventDefault();
		const obj = $(this);
		const index = obj.index();
		typeTextItem.removeClass(CLASS._active);
		obj.addClass(CLASS._active);
		slickImage.slick('slickGoTo', index);
		slickText.slick('slickGoTo', index);
		typeSelect.val(index);
		typeSelect.select2({
			minimumResultsForSearch: -1,
			with: 'resolve',
			templateSelection: function (data, container) {
				if (data.id !== '') {
					$(container).addClass('select2-selection__rendered--value');
				} else {
					$(container).removeClass('select2-selection__rendered--value');
				}
				return data.text;
			},
		});
	}

	function handleTypeSelect() {
		const obj = $(this);
		const index = obj.val();
		typeTextItem.removeClass(CLASS._active);
		typeTextItem.eq(index).addClass(CLASS._active);
		slickImage.slick('slickGoTo', index);
		slickText.slick('slickGoTo', index);
	}

	init();
};
