APP.testDrive = function () {
	const el = $('.test-drive');
	const btnXFC = $('.btn--xfc');
	const loading = el.find('.loading');
	const company = el.find('[name="o2o-company"]');
	//const selectDealer = el.find('[name="dealer_id"]');
	//const selectDealerOption = selectDealer.data('label-dealer');
	//const selectDealerInputPlaceholder = selectDealer.data('input-placeholder');
	// const dealerId = el.find('[name="dealer_id"]');
	// const dealerName = el.find('[name="dealer_name"]');
	const slickCarChoose = el.find('.slick--car-choose');
	const tagsModel = el.find('[name="tags_model"]');
	const modelId = el.find('[name="model_id"]');
	const modelName = el.find('[name="model_name"]');
	const position = el.find('[name="position"]');
	const form_type = el.find('[name="form_type"]');
	const selectVariant = el.find('[name="variant_id"]');
	const noteTime = el.find('[name="note"]');
	const o2oBuyLevel = el.find('[name="o2o-buyLevel"]');
	const dealercode = el.find('[name="dealercode"]');
	const selectVariantOption = selectVariant.data('label-text');
	const selectVariantInputPlaceholder = selectVariant.data('input-placeholder');
	const textTD = el.find('.text-td');
	const textTDOne = el.find('.text-td--one');
	const textTDTwo = el.find('.text-td--two');

	const chooseExtend = $('.choose-extend');
	const chooseExtendSelect = chooseExtend.find('select');
	const chooseExtendSelectLabel = chooseExtendSelect.data('label-text');
	const chooseExtendSelectPlaceholder = chooseExtendSelect.data('input-placeholder');
	let chooseDealer = null;
	let crmSaleCodeArr = [];

	const thankYou = $('.thank-you');
	const thankYouName = thankYou.find('.thank-you__name');
	const thankYouPrice = thankYou.find('.thank-you__price');
	const thankYouThumb = thankYou.find('.thank-you__thumb img');

	const thankContact = thankYou.find('.thank-contact');
	const thankContactTitle = thankContact.find('.thank-contact__title');
	const thankContactAddress = thankContact.find('.thank-contact__address');
	const thankContactPhone = thankContact.find('.thank-contact__phone');
	const thankContactPhoneSaleText = thankContactPhone.data('phone-sale');
	const thankContactPhoneServiceText = thankContactPhone.data('phone-service');
	const thankContactWeb = thankContact.find('.thank-contact__web');
	const thankContactEmail = thankContact.find('.thank-contact__email');
	const mmv_campaign = getCookie('mmv_campaign')
		? getCookie('mmv_campaign')
		: getParameterByName('mmv_campaign');

	const dataSubmit = {
		utm_source: getCookie('utm_source') ? getCookie('utm_source') : '',
		utm_content: getCookie('utm_content') ? getCookie('utm_content') : '',
		utm_medium: getCookie('utm_medium') ? getCookie('utm_medium') : '',
		utm_campaign: getCookie('utm_campaign') ? getCookie('utm_campaign') : '',
		mmv_campaign,
		form_type: getParameterByName('form_type') ? getParameterByName('form_type') : 'test-drive',
		location: window.location.href,
		web_dealer: true,
		env: getParameterByName('env') ? getParameterByName('env') : 'dev',
	};

	function init() {
		if (el.length === 0) return;

		let companyVal = `${tagsModel.val()}`;
		if (dataSubmit.mmv_campaign) companyVal += `, ${dataSubmit.mmv_campaign}`;
		if (noteTime.val()) companyVal += `, ${noteTime.val()}`;

		company.val(`${companyVal}`);

		selectVariant.prop('selectedIndex', 1);
		selectVariant.trigger('change.select2');

		selectVariant.on('change', function () {
			const obj = $(this);
			const val = obj.find('option:selected').text();
			el.find('[name="dealer_note_variant"]').val(val);
		});

		noteTime.on('change', function () {
			const obj = $(this);
			const status = obj.find('option:selected').data('status');
			o2oBuyLevel.val(status);
		});

		const searchParams = new Proxy(new URLSearchParams(window.location.search), {
			get: (searchParams, prop) => searchParams.get(prop),
		});
		if (searchParams.position) {
			position.val(searchParams.position);
			form_type.val(searchParams.position);
		}

		slickCarChoose.slick({
			infinite: true,
			centerMode: true,
			centerPadding: '180px',
			slidesToShow: 3,
			slidesToScroll: 3,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						arrows: false,
						infinite: true,
						centerMode: true,
						centerPadding: '60px',
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		});

		slickCarChoose.on('swipe', function () {
			handleCarChoose();
		});

		slickCarChoose.on('afterChange', function () {
			handleCarChoose();
		});

		slickCarChoose.on('click', '.slick-active', onClickSlickItem);

		//APP.getDealers(selectDealer, selectDealerOption, selectDealerInputPlaceholder);

		thankYou.hide();

		textTD.hide();
		textTDOne.show();

		if (typeof hasBranch !== 'undefined' && hasBranch) {
			crmSaleCodeArr = JSON.parse(crmSaleCode);
			chooseExtend.show().find('.form__group').attr('data-required', 'data-required');
			renderSelectSale(crmSaleCodeArr);
		} else {
			chooseExtend.hide().find('.form__group').removeAttr('data-required');
		}

		btnXFC.on('click', function (evt) {
			evt.preventDefault();
			const obj = $(this);
			const secRegister = obj.attr('href');
			const postionValue = obj.data('position');
			//const dealerValue = obj.data('dealer-value');
			const posY = $(secRegister).offset().top;
			APP.setScrollTopAnimation(posY);
			textTD.hide();
			if (postionValue && postionValue !== 'data-position') {
				textTDTwo.show();
				position.val(postionValue);
				form_type.val(postionValue);
			} else {
				textTDOne.show();
				position.val('');
				form_type.val('');
			}
			// if (dealerValue && dealerValue !== 'data-dealer-value') {
			// 	selectDealer.val(dealerValue);
			// 	selectDealer.trigger('change');
			// }
		});

		APP.validate('.form--test-drive', {
			isAjax: true,
			onComplete: function (res) {
				const selectVariantType = selectVariant.attr('type');
				let variant_name = '';
				let buyLevel = noteTime.find('option:selected').data('status');
				//const dealer_name = selectDealer.find('option:selected').text() || $('[name="model_id"]').data('model-name');
				if (selectVariantType !== 'hidden') {
					variant_name =
						selectVariant.find('option:selected').text() || $('[name="variant_name"]').val();
				}

				if (form_type.val() !== '') {
					dataSubmit.form_type = form_type.val();
				}

				let companyVal = `${tagsModel.val()}`;
				if (dataSubmit.mmv_campaign) companyVal += `, ${dataSubmit.mmv_campaign}`;
				// if(noteTime.val()) companyVal += `, ${noteTime.val()}`

				company.val(`${companyVal}`);

				const data = {
					...res,
					...dataSubmit,
					variant_name,
					buyLevel,
				};
				const dataInput = data;

				loading.show();
				$.ajax(`${api}/form/testDrive`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						lang: _lang,
					},
					dataType: 'json',
					contentType: 'application/json; charset=utf-8',
					data: JSON.stringify(data),
					traditional: true,
				})
					.done((data) => {
						loading.hide();

						if (data.result === 'success') {
							thankYouName.html(data.data_variant?.title?.rendered);
							thankYouPrice.html(data.data_variant?.acf?.price_text_has_vat);
							if (data.data_variant?.image_large) {
								thankYouThumb.attr('src', data.data_variant?.image_large);
							} else {
								thankYouThumb.hide();
							}
							thankContactTitle.html(
								typeof dealerCompanyName !== 'undefined'
									? dealerCompanyName
									: data.data_dealer.acf.company_name
							);

							if (typeof hasBranch !== 'undefined' && hasBranch && chooseDealer !== null) {
								const thankContactPhoneSaleList = chooseDealer?.sales_hotline.map((item) => {
									return `<a href="tel:${item}">${item} (${thankContactPhoneSaleText})</a>`;
								});

								const thankContactPhoneServiceList = chooseDealer?.service_hotline.map((item) => {
									return `<a href="tel:${item}" target="_blank">${item} (${thankContactPhoneServiceText})</a>`;
								});
								thankContactAddress.html(chooseDealer?.address);
								if (
									chooseDealer?.sales_hotline.length > 0 ||
									chooseDealer?.service_hotline.length > 0
								)
									thankContactPhone.html(
										`<i class="icon icon--phone-red"></i> ${thankContactPhoneSaleList} ${thankContactPhoneServiceList}`
									);
							} else {
								const thankContactPhoneSaleList =
									typeof dealerSalesHotline !== 'undefined'
										? `<a href="tel:${dealerSalesHotline}">${dealerSalesHotline} (${thankContactPhoneSaleText})</a>`
										: data.data_dealer?.acf?.phone_sales?.map((item) => {
												return `<a href="tel:${item.phone_sale}">${item.phone_sale} (${thankContactPhoneSaleText})</a>`;
										  });

								const thankContactPhoneServiceList =
									typeof dealerServiceHotline !== 'undefined'
										? `<a href="tel:${dealerServiceHotline}">${dealerServiceHotline} (${thankContactPhoneServiceText})</a>`
										: data.data_dealer?.acf?.phone_services?.map((item) => {
												return `<a href="tel:${item.phone_service}" target="_blank">${item.phone_service} (${thankContactPhoneServiceText})</a>`;
										  });

								thankContactAddress.html(
									typeof dealerAddress !== 'undefined'
										? dealerAddress
										: data.data_dealer.acf.address
								);

								if (
									data.data_dealer.acf.phone_sales.length > 0 ||
									data.data_dealer.acf.phone_services.length > 0
								)
									thankContactPhone.html(
										`<i class="icon icon--phone-red"></i> ${thankContactPhoneSaleList} ${thankContactPhoneServiceList}`
									);
							}

							const thankContactWebHTML = `<a href="${
								typeof dealerWebsite !== 'undefined' ? dealerWebsite : data.data_dealer.acf.website
							}" target="_blank">${
								typeof dealerWebsite !== 'undefined' ? dealerWebsite : data.data_dealer.acf.website
							}</a>`;

							if (typeof dealerEmail !== 'undefined' || data.data_dealer.acf.email) {
								thankContactEmail.html(
									`<i class="icon icon--mail-red"></i> <a href="mailto:${
										typeof dealerEmail !== 'undefined' ? dealerEmail : data.data_dealer.acf.email
									}">${
										typeof dealerEmail !== 'undefined' ? dealerEmail : data.data_dealer.acf.email
									}</a>`
								);
							}

							thankContactWeb.html(`<i class="icon icon--lap-red"></i> ${thankContactWebHTML}`);

							const dataLng = data.data_dealer.acf.dealer_location.lng;
							const dataLat = data.data_dealer.acf.dealer_location.lat;

							function initMap() {
								const mapOptions = {
									zoom: 16,
									center: { lat: dataLat, lng: dataLng },
								};

								if (google) {
									let map = new google.maps.Map(document.getElementById('map'), mapOptions);

									const marker = new google.maps.Marker({
										position: { lat: dataLat, lng: dataLng },
										map: map,
									});
								}
							}

							setTimeout(initMap, 150);

							/// BEGIN Send data to CDP & CRM
							// dataLayer = dataLayer || [];
							let tags = dataInput.tags_model;
							if (dataInput.mmv_campaign) {
								tags += ',' + dataInput.mmv_campaign;
							}
							// console.log(dataInput);

							dataLayer.push({
								event: 'pangoTrack',
								eventAction: 'generate_lead',
								label: '',
								user_data: {
									email_address: dataInput.email,
									phone_number: dataInput.phone,
								},
								cdpData: {
									properties: {
										fullname: dataInput.name,
										email: dataInput.email,
										phone: dataInput.phone,
										listTagsModel: dataInput.tags_model,
										listTagsCampaign: dataInput.mmv_campaign,
										company: tags,
										address: dataInput.dealer_name,
										note: dataInput.note,
										position: dataInput.position,
										buyLevel: dataInput.buyLevel,
										dealercode: dataInput.dealercode,
									},
								},
							});
							/// End

							el.addClass(CLASS._blur);
							gsap.set(thankYou, {
								display: 'block',
								alpha: 0,
							});

							gsap.to(thankYou, {
								duration: 0.4,
								alpha: 1,
								onComplete: function () {
									const thankYouPos = thankYou.position().top;
									APP.setScrollTopAnimation(thankYouPos);
								},
							});
							$('.form__error--response').html('');
						} else {
							$('.form__error--response').html(data.errorMessage);
						}
					})
					.catch((err) => {
						loading.hide();
						console.log(err);
					});
			},
		});
	}

	function renderSelectSale(data) {
		let items = [`<option selected value="">${chooseExtendSelectLabel}</option>`];
		if (data.length > 0) {
			data.forEach((item) => {
				items.push(`<option value="${item.code}">${item.name}</option>`);
			});
		}

		chooseExtendSelect.html(items.join(''));
		chooseExtendSelect.select2('destroy');
		chooseExtendSelect.select2({
			with: 'resolve',
			searchInputPlaceholder: chooseExtendSelectPlaceholder,
			templateSelection: function (data, container) {
				if (data.id !== '') {
					$(container).addClass('select2-selection__rendered--value');
				} else {
					$(container).removeClass('select2-selection__rendered--value');
				}
				return data.text;
			},
		});

		chooseExtendSelect.on('change', function () {
			const obj = $(this);
			const val = obj.val();
			dealercode.val(val);
			chooseDealer = crmSaleCodeArr.find((item) => item.code === val);
		});
	}

	function onClickSlickItem(e) {
		e.stopPropagation();
		const index = $(this).data('slick-index');
		slickCarChoose.slick('slickGoTo', index);
		handleCarChoose();
	}

	function handleCarChoose() {
		const slickSlide = slickCarChoose.find('.slick-slide');
		const formGroup = slickCarChoose.next();
		const formError = formGroup.find('.form__error');
		const slickActiveCenter = slickCarChoose.find('.slick-active.slick-center');
		const title = slickActiveCenter.find('.title');
		const modelIdValue = parseInt(title.data('model-id'));
		const modelNameValue = title.text();
		const modelTagValue = title.data('model-tag');
		//$('.o2o-company').val(`${modelTagValue}, 25nam`);
		slickSlide.removeClass(CLASS._selected);
		slickActiveCenter.addClass(CLASS._selected);
		formGroup.removeClass(CLASS._error).removeClass(CLASS._focus);
		formError.text('');
		modelId.val(modelIdValue);
		modelName.val(modelNameValue);
		tagsModel.val(modelTagValue);
		let companyVal = `${modelNameValue}`;
		if (dataSubmit.mmv_campaign) companyVal += `, ${dataSubmit.mmv_campaign}`;
		if (noteTime.val()) companyVal += `, ${noteTime.val()}`;
		company.val(`${companyVal}`);
		APP.getVariants(
			selectVariant,
			selectVariantOption,
			selectVariantInputPlaceholder,
			modelIdValue
		);
	}

	init();
};
