APP.qrCode = function () {
	const el = $('.qr-code');
	const qrCodeIcon = el.find('.qr-code__icon');
	const qrCodeId = el.find('.qr-code__id');

	function init() {
		if (el.lenght === 0) return;

		const id = APP.getParameterByName('id');

		if (id) {
			qrCodeIcon.qrcode({
				width: 200,
				height: 200,
				text: id,
			});

			qrCodeId.html(id);
		}
	}

	init();
};
