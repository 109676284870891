APP.toasts = function (options) {
	let toasts, toastsDialog, toastsMain, toastsFoot, btnOk, btnConfirm;

	const settings = $.extend(
		{
			message: '',
			isConfirm: false,
			onComplete: function () {},
		},
		options
	);

	function init() {
		setup();
		btnOk.on('click', handleOk);
	}

	function setup() {
		handleOk();

		toasts = MAIN.append($('<div>').addClass('toasts')).find('.toasts');

		toastsDialog = toasts.append($('<div>').addClass('toasts__dialog')).find('.toasts__dialog');

		TweenMax.set(toastsDialog, {
			x: -100,
			zIndex: 2,
			y: '-50%',
		});

		BODY.addClass(CLASS._noscroll);
		TweenMax.set(BODY, {
			paddingRight: APP.getScrollbarWidth(),
		});

		TweenMax.set('.header', {
			paddingRight: APP.getScrollbarWidth(),
		});

		toastsMain = toastsDialog.append($('<div>').addClass('toasts__main')).find('.toasts__main');

		toastsMain
			.append($('<div>').addClass('toasts__body').html(settings.message))
			.find('.toasts__body');

		toastsFoot = toastsMain.append($('<div>').addClass('toasts__foot')).find('.toasts__foot');

		if (settings.isConfirm) {
			btnConfirm = toastsFoot
				.append(
					$('<button>').addClass('btn btn--ok').html('<span>Ok</span>').attr('type', 'button')
				)
				.find('.btn--ok');

			btnConfirm.append('<i class="icon icon--arrow-alt-right">');

			btnOk = toastsFoot
				.append(
					$('<button>')
						.addClass('btn btn--cancel')
						.html('<span>Cancel</span>')
						.attr('type', 'button')
				)
				.find('.btn--cancel');

			btnConfirm.on('click', handleConfirm);
		} else {
			btnOk = toastsFoot
				.append(
					$('<button>').addClass('btn btn--ok').html('<span>Ok</span>').attr('type', 'button')
				)
				.find('.btn--ok');

			btnOk.append('<i class="icon icon--arrow-alt-right">');
		}

		TweenMax.to(toastsDialog, 0.7, {
			x: '-50%',
			ease: Elastic.easeOut,
		});
	}

	function handleOk() {
		$('.toasts').remove();
		BODY.removeClass(CLASS._noscroll).removeAttr('style');
		$('.header').removeAttr('style');
		settings.onComplete();
	}

	function handleConfirm() {
		handleOk();
	}

	init();
};
