APP.configurator3d = function () {
	const el = $('.configurator3d');
	const slickChooseVariant = el.find('.slick--configurator3d');

	function init() {
		if (el.length === 0) return;

		slickChooseVariant.slick({
			infinite: false,
			centerMode: true,
			centerPadding: '20%',
			slidesToShow: 1,
			slidesToScroll: 1,
			dots: true,
			// responsive: [
			// 	{
			// 		breakpoint: 992,
			// 		settings: {
			// 			arrows: false,
			// 			infinite: true,
			// 			centerMode: true,
			// 			centerPadding: '60px',
			// 			slidesToShow: 1,
			// 			slidesToScroll: 1,
			// 		},
			// 	},
			// ],
		});
	}

	init();
};
