APP.validate = function (element, options) {
	const el = $(element);
	const btnSubmit = el.find('[type="submit"]');

	const settings = $.extend(
		{
			isAjax: false,
			onComplete: function () {},
		},
		options
	);

	function init() {
		$('[name="phone"]').mask('000000000000', {
			onKeyPress: function (cep, event, currentField) {
				const parent = currentField.parent();
				const formError = parent.find('.form__error');
				const textError = formError.data('error-phone');
				if (cep.length < 10) {
					parent.addClass(CLASS._error);
					formError.html(textError);
				} else {
					parent.removeClass(CLASS._error);
					formError.html('');
				}
			},
		});

		$('[name="company_phone"]').mask('000000000000', {
			onKeyPress: function (cep, event, currentField) {
				const parent = currentField.parent();
				const formError = parent.find('.form__error');
				const textError = formError.data('error-phone');
				if (cep.length < 10) {
					parent.addClass(CLASS._error);
					formError.html(textError);
				} else {
					parent.removeClass(CLASS._error);
					formError.html('');
				}
			},
		});

		$('[name="driving_licence"]').mask('0#');

		// $('[name="calc_custom_interest_rate"]').mask('00000');
		// $('[name="calc_custom_interest_float"]').mask('00000');
		$('[name="calc_custom_interest_time"]').mask('00');

		$('[name="quantily"]').mask('00');
		checkKeyCode();
		btnSubmit.on('click', handleSubmit);
	}

	function handleSubmit(e) {
		e.preventDefault();

		const fields = el.find('.form__group');
		const requiredFields = el.find('[data-required]');
		let isFlag = true;

		if (requiredFields.length > 0) {
			requiredFields.each(function () {
				const field = $(this);
				const input = field.find('input, textarea, select');
				const type = input.attr('type');
				const error = field.find('.form__error');
				const messageWrong = error.data('error-wrong');
				const messageEmail = error.data('error-email');
				const messagePhone = error.data('error-phone');
				const messageNumber = error.data('error-number');
				const messageFileExtension = error.data('error-file-extension');
				const messageFileSize = error.data('error-file-size');

				let value = input.val();

				field.addClass(CLASS._valid);

				if (value === '') {
					showError(error, field, messageWrong);
					isFlag = false;
				} else {
					switch (type) {
						case 'email':
							if (!checkEmail(value)) {
								showError(error, field, messageEmail);
							}
							break;
						case 'tel':
							if (!checkPhone(value)) {
								showError(error, field, messagePhone);
							}
							break;
						case 'number':
							if (!checkNumber(value)) {
								showError(error, field, messageNumber);
							}
							break;
						case 'radio':
							if (!input.is(':checked')) {
								showError(error, field, messageWrong);
							}
							break;
						case 'checkbox':
							if (!input.is(':checked')) {
								showError(error, field, messageWrong);
							}
							break;
						case 'file':
							if (
								!hasExtension(value.toLowerCase(), [
									'.doc',
									'.docx',
									'.pdf',
									'.png',
									'.jpg',
									'.jpeg',
								])
							) {
								showError(error, field, messageFileExtension);
								return false;
							} else if (!validateSize(input)) {
								showError(error, field, messageFileSize);
								return false;
							}
							break;
						default:
							break;
					}
				}

				input.on('keydown', function (evt) {
					const obj = $(this);
					const type = obj.attr('type');
					let value = obj.val();

					if (obj.attr('name') !== 'phone') {
						if (value !== '' && type !== 'radio' && type !== 'checkbox') {
							removeError(error, field);
						} else {
							if (obj.is(':checked')) {
								removeError(error, field);
							}
						}
					}
				});

				input.on('change', function () {
					const obj = $(this);
					const type = obj.attr('type');
					let value = obj.val();

					if (obj.attr('name') !== 'phone') {
						if (value !== '' && type !== 'radio' && type !== 'checkbox') {
							removeError(error, field);
						} else {
							if (obj.is(':checked')) {
								removeError(error, field);
							}
						}
					}
				});
			});

			requiredFields.each(function () {
				const field = $(this);
				const input = field.find('input, textarea, select');
				const type = input.attr('type');
				const value = input.val();

				if (value === '') {
					inputFocus(input, field);
					return false;
				} else {
					switch (type) {
						case 'email':
							if (!checkEmail(value)) {
								inputFocus(input, field);
								return false;
							}
							break;
						case 'tel':
							if (!checkPhone(value)) {
								inputFocus(input, field);
								return false;
							}
							break;
						case 'number':
							if (!checkNumber(value)) {
								inputFocus(input, field);
								return false;
							}
							break;
						case 'radio':
							if (!input.is(':checked')) {
								inputFocus(input, field);
								return false;
							}
							break;
						case 'checkbox':
							if (!input.is(':checked')) {
								inputFocus(input, field);
								return false;
							}
							break;
						case 'file':
							if (!hasExtension(value, ['.doc', '.docx', '.pdf', '.png', '.jpg', '.jpeg'])) {
								inputFocus(input, field);
								return false;
							} else if (!validateSize(input)) {
								inputFocus(input, field);
								return false;
							}
							break;
						default:
							break;
					}
				}
			});

			if (!fields.hasClass(CLASS._error) && fields.hasClass(CLASS._valid)) {
				let data = objectifyForm(el.serializeArray());
				settings.onComplete(data);
				if (isFlag && !settings.isAjax) {
					el.submit();
				}
			}
		} else {
			el.submit();
		}
	}

	function checkKeyCode() {
		const requiredFields = el.find('[data-required]');

		if (requiredFields.length > 0) {
			requiredFields.each(function () {
				const field = $(this);
				const input = field.find('input, textarea, select');
				const type = input.attr('type');

				switch (type) {
					case 'number':
						input.on('keydown', function (evt) {
							return evt.keyCode !== 69;
						});
						break;
					default:
						break;
				}
			});
		}
	}

	function showError(error, field, message) {
		error.html(message);
		action(field);
	}

	function removeError(error, field) {
		error.html('');
		field.removeClass(CLASS._error).removeClass(CLASS._focus);
	}

	function inputFocus(input, field) {
		const header = $('.header');
		const headerH = header.height();
		const inputFirst = input.first();
		const posY = el.offset().top - headerH;
		if (inputFirst.attr('type') === 'hidden') {
			APP.setScrollTopAnimation(posY);
		}
		inputFirst.focus();
		field.addClass(CLASS._focus);
	}

	function action(field) {
		field.addClass(CLASS._error);
		field.removeClass(CLASS._valid);
	}

	function checkEmail(email) {
		const reg =
			/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
		return reg.test(String(email).toLowerCase());
	}

	function checkPhone(phone) {
		const reg = /(84|0[1|3|5|7|8|9])+([0-9]{8})\b/;
		return reg.test(phone);
	}

	function checkNumber(number) {
		const reg = /^(100(\.00?)?|[1-9]?\d(\.\d\d?)?)$/;
		return reg.test(number);
	}

	function objectifyForm(formArray) {
		const returnArray = {};
		for (var i = 0; i < formArray.length; i++) {
			returnArray[formArray[i]['name']] = formArray[i]['value'];
		}
		return returnArray;
	}

	function hasExtension(value, exts) {
		return new RegExp('(' + exts.join('|').replace(/\./g, '\\.') + ')$').test(value);
	}

	function validateSize(input) {
		const fileSize = input[0].files[0].size / 1024 / 1024;
		if (fileSize >= 5) return false;
		return true;
	}

	init();
};
