APP.tableTabbed = function () {
	const el = $('.table-tabbed');
	const tableTabbedFoot = el.find('.table-tabbed__foot');
	const tableTabbedContent = el.find('.table-tabbed__content');
	//const tableTabbedText = el.find('.table-tabbed__text');
	const btnShowMoreLess = el.find('.btn--sml');
	const btnShowMoreLessText = btnShowMoreLess.find('.btn__text');
	const specification = el.find('.specification');
	const specificationBody = specification.find('.specification__body');
	const specificationBodyItem = specificationBody.find('.row__col');
	const specificationRowCol = specificationBody.find('.row__col');
	let specificationRowColWidth = specificationRowCol.outerWidth(true);
	let specificationRowColTotal = specificationRowCol.length;
	let lastScroll = 0;

	function init() {
		// detect();
		// APP.resize(detect);
		detectCollapse();
		APP.resize(detectCollapse);

		btnShowMoreLess.on('click', onClickShowLess);

		$('.specification__main').on('scroll', function () {
			const obj = $(this);
			const posX = obj.scrollLeft();
			if ($('.specification').hasClass('posFix') || $('.specification').hasClass('posAbs')) {
				$('.specification__body').css({ left: -posX });
			}
		});

		specificationBodyItem.each(function () {
			const obj = $(this);
			const slickCar = obj.find('.slick');
			const bulletColor = obj.find('.bullet');
			const bulletColorIcon = obj.find('.bullet__icon');

			slickCar.slick({
				arrows: false,
				infinite: true,
				fade: true,
				speed: 300,
			});

			slickCar.on('init reInit afterChange', function (event, slick, currentSlide) {
				let i = currentSlide ? currentSlide : 0;
				bulletColorIcon.removeClass(CLASS._active);
				bulletColorIcon.eq(i).addClass(CLASS._active);
			});

			bulletColorIcon.on('click', function (e) {
				e.preventDefault();
				const obj = $(this);
				const idx = obj.index();
				bulletColorIcon.removeClass(CLASS._active);
				obj.addClass(CLASS._active);
				slickCar.slick('slickGoTo', idx);
			});
		});
	}

	function onClickShowLess() {
		const obj = $(this);
		const header = $('.header');
		const showMoreText = obj.data('show-more-text');
		const showLessText = obj.data('show-less-text');
		const headerH = header.outerHeight();
		//const tableTabbedTextH = tableTabbedText.outerHeight();
		const tableTabbedTop = tableTabbedFoot.offset().top;

		if (el.hasClass(CLASS._show)) {
			APP.setScrollTopAnimation(tableTabbedTop - headerH);
			$('.specification').removeClass('posFix').removeClass('posAbs');
			$(window).off('scroll', sticky);
			$(window).off('resize', sticky);

			tableTabbedContent.removeClass(CLASS._show);
			btnShowMoreLessText.text(showMoreText);
		} else {
			$(window).on('scroll', sticky);
			$(window).on('resize', sticky);
			APP.setScrollTopAnimation(tableTabbedTop);
			tableTabbedContent.addClass(CLASS._show);
			btnShowMoreLessText.text(showLessText);
		}

		el.toggleClass(CLASS._show);
	}

	// function detect() {
	// 	if (el.hasClass(CLASS._show)) {
	// 		const tableTabbedTextH = tableTabbedText.outerHeight();

	// 		gsap.set(tableTabbedContent, {
	// 			height: tableTabbedTextH,
	// 		});
	// 	}
	// }

	function detectCollapse() {
		//DOC_WIDTH = DOCUMENT.width();

		//if (DOC_WIDTH < 576) {
		specificationRowColWidth = Math.floor(specificationRowCol.outerWidth(true));
		specificationRowColTotal = specificationRowCol.length;

		if ($('.collapse').length > 0) {
			gsap.set('.collapse', {
				width: specificationRowColTotal * specificationRowColWidth - 40,
			});
		}
		// } else {
		// 	$('.collapse').removeAttr('style');
		// }
	}

	function sticky() {
		let scroll = $(window).scrollTop();
		let topContent = $('.specification__main').offset().top;
		let sectionHeight = $('.specification__main').height();
		let rightHeight = $('.specification__body').height();
		let specificationHead = $('.specification__head').width() + 40;
		let bottomContent = topContent + sectionHeight - rightHeight;

		if (scroll > 0 && lastScroll <= scroll) {
			$('.specification').removeClass('scroll-up');
		} else {
			$('.specification').addClass('scroll-up');
		}

		lastScroll = scroll;

		if (scroll > topContent && scroll < bottomContent) {
			$('.specification__body').css({ width: specificationHead });
			if (!$('.specification__foot').hasClass('margined')) {
				const marginTop = $('.specification__body').outerHeight(true);
				$('.specification__foot')
					.addClass('margined')
					.css({ paddingTop: `${marginTop}px` });
			}
			$('.specification').removeClass('posAbs').addClass('posFix');
		} else if (scroll > bottomContent) {
			$('.specification').removeClass('posFix').addClass('posAbs');
		} else if (scroll < topContent) {
			$('.specification').removeClass('posFix');
			$('.specification__body').removeAttr('style');
			$('.specification__foot').removeClass('margined').removeAttr('style');
		}

		//detect();
		//DOC_WIDTH = DOCUMENT.width();

		//if (DOC_WIDTH < 576) {
		const offsetX = $('.specification__main').offset().left;
		const posX = $('.specification__main').scrollLeft();
		if ($('.specification').hasClass('posFix') || $('.specification').hasClass('posAbs')) {
			$('.specification__body').css({ left: -posX, marginLeft: offsetX });
		}
		//}
	}

	init();
};
