APP.career = function () {
	const el = $('.register-career');
	const loading = el.find('.loading');
	const thankYou = $('.thank-you');

	const dataSubmit = {
		utm_source: getCookie('utm_source') ? getCookie('utm_source') : '',
		utm_content: getCookie('utm_content') ? getCookie('utm_content') : '',
		utm_medium: getCookie('utm_medium') ? getCookie('utm_medium') : '',
		utm_campaign: getCookie('utm_campaign') ? getCookie('utm_campaign') : '',
		mmv_campaign: getCookie('mmv_campaign') ? getCookie('mmv_campaign') : '',
	};

	function init() {
		thankYou.hide();

		APP.validate('.form--register-career', {
			isAjax: true,
			onComplete: function (res) {
				console.log(document.getElementById('file_upload').files);
				const file = document.getElementById('file_upload').files[0];
				const formData = new FormData();

				formData.append('file_upload', file);

				const data = {
					...res,
					...dataSubmit,
				};

				for (let key of Object.keys(data)) {
					formData.append(key, data[key]);
				}

				for (var pair of formData.entries()) {
					console.log(pair[0] + ', ' + pair[1]);
				}

				loading.show();
				fetch(`${api}/form/recruitment`, {
					method: 'POST',
					headers: {
						lang: _lang,
					},
					body: formData,
				})
					.then((res) => res.json())
					.then((data) => {
						loading.hide();

						if (data.result === 'success') {
							el.addClass(CLASS._blur);
							gsap.set(thankYou, {
								display: 'block',
								alpha: 0,
							});

							gsap.to(thankYou, {
								duration: 0.4,
								alpha: 1,
								onComplete: function () {
									const thankYouPos = thankYou.position().top;
									APP.setScrollTopAnimation(thankYouPos);
								},
							});
						}
					});
			},
		});
	}

	init();
};
