APP.banner_product = function (
	url = '#',
	desktop_img = 'https://www.mitsubishi-motors.com.vn/w/wp-content/uploads/2022/06/finance-calculator-top-banner.jpeg',
	mobile_img = 'https://www.mitsubishi-motors.com.vn/w/wp-content/uploads/2022/06/finance-calculator-top-banner.jpeg'
) {
	const oldBanner = $('#product-banner');
	function init() {
		const html = `<div class="banner-xpander">
		<div class="banner-hero financial-slider">
			<div class="banner-hero">
				<div class="standard-hero"><a class="standard-hero__bg" href="${url}">
						<div class="standard-hero__desktop"><img class="img img--full lazyload" data-src="${desktop_img}"  src="${desktop_img}" alt=""></div>
						<div class="standard-hero__mobile"><img class="img img--full lazyloaded" data-src="${mobile_img}" src="${mobile_img}" alt=""></div></a></div>
			</div>
		</div>
	</div>`;
		oldBanner.after(html);
		oldBanner.remove();
	}
	init(url, desktop_img, mobile_img);
};
