APP.mapWorkHour = function () {
	const mapWorkHourWrap = $('.map-work-hour__wrap');
	const dataLng = mapWorkHourWrap.data('lng');
	const dataLat = mapWorkHourWrap.data('lat');

	function initMap() {
		if (mapWorkHourWrap.length === 0) return;

		const mapOptions = {
			zoom: 16,
			center: { lat: dataLat, lng: dataLng },
		};

		if (google) {
			let map = new google.maps.Map(document.getElementById('map'), mapOptions);

			const marker = new google.maps.Marker({
				position: { lat: dataLat, lng: dataLng },
				map: map,
			});
		}
	}

	setTimeout(initMap, 150);
};
