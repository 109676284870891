APP.carouselImage = function () {
	const el = $('.carousel-image');
	const slickCarouselImage = el.find('.slick--carousel-image');
	const paging = el.find('.carousel-image__paging');

	function init() {
		if (el.length === 0) return;

		slickCarouselImage.on('init reInit afterChange', function (event, slick, currentSlide) {
			let i = (currentSlide ? currentSlide : 0) + 1;
			let slideCount = slick.slideCount;
			let number = i > 9 ? i : '0' + i;
			let total = slideCount > 9 ? slideCount : '0' + slideCount;
			paging.html(number + '<span>/</span>' + total);
		});

		slickCarouselImage.slick({
			infinite: true,
			arrows: true,
			slidesToShow: 1,
			slidesToScroll: 1,
		});
	}

	init();
};
