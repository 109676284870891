APP.form = function () {
	const formControl = $('.form__control');

	const init = function () {
		placeholderLabel();
	};

	const placeholderLabel = function () {
		formControl.each(function () {
			const obj = $(this);
			const field = obj.closest('.form__group');
			const type = obj.attr('type');
			const value = obj.val();

			if (value !== '' && type !== 'checkbox' && type !== 'radio') {
				field.addClass(CLASS._filled);
			} else {
				if (obj.is(':checked')) {
					field.addClass(CLASS._filled);
				} else {
					field.removeClass(CLASS._filled);
				}
			}
		});

		formControl.on('focus', function () {
			const obj = $(this);
			const field = obj.closest('.form__group');
			field.addClass(CLASS._filled);
		});

		formControl.on('blur input', function () {
			const obj = $(this);
			const field = obj.closest('.form__group');
			const type = obj.attr('type');
			const value = obj.val();

			if (value !== '' && type !== 'checkbox' && type !== 'radio') {
				field.addClass(CLASS._filled);
			} else {
				if (obj.is(':checked')) {
					field.addClass(CLASS._filled);
				} else {
					field.removeClass(CLASS._filled);
				}
			}
		});

		$('select').on('change', function () {
			var field = $(this).closest('.form__group');
			if (this.value) {
				field.addClass(CLASS._filled);
			} else {
				field.removeClass(CLASS._filled);
			}
		});
	};

	init();
};
