APP.xpander = function () {
	const el = $('.modal--xpander');
	const loading = el.find('.loading');
	const selectVariant = el.find('[name="variant_id"]');
	const selectVariantOption = selectVariant.data('label-text');
	const selectVariantInputPlaceholder = selectVariant.data('input-placeholder');
	const selectDealer = el.find('[name="dealer_id"]');
	const selectDealerOption = selectDealer.data('label-dealer');
	const selectDealerInputPlaceholder = selectDealer.data('input-placeholder');

	const thankContact = el.find('.thank-contact');
	const thankContactTitle = thankContact.find('.thank-contact__title');
	const thankContactAddress = thankContact.find('.thank-contact__address');
	const thankContactPhone = thankContact.find('.thank-contact__phone');
	const thankContactPhoneSaleText = thankContactPhone.data('phone-sale');
	const thankContactPhoneServiceText = thankContactPhone.data('phone-service');
	const thankContactWeb = thankContact.find('.thank-contact__web');

	const dataSubmit = {
		utm_source: getCookie('utm_source') ? getCookie('utm_source') : '',
		utm_content: getCookie('utm_content') ? getCookie('utm_content') : '',
		utm_medium: getCookie('utm_medium') ? getCookie('utm_medium') : '',
		utm_campaign: getCookie('utm_campaign') ? getCookie('utm_campaign') : '',
		mmv_campaign: getCookie('mmv_campaign') ? getCookie('mmv_campaign') : '',
		form_type: getParameterByName('form_type') ? getParameterByName('form_type') : 'test-drive',
		location: window.location.href,
		env: getParameterByName('env') ? getParameterByName('env') : 'dev',
	};

	function init() {
		// $('.modal__close').on('click', function () {
		// 	$('.modal--xpander-one .form--xpander')[0].reset();
		// 	$('.modal--xpander-two .form--xpander')[0].reset();
		// 	$('.modal--xpander-three .form--xpander')[0].reset();
		// 	selectVariant.trigger('change');
		// 	selectDealer.trigger('change');
		// 	setTimeout(() => {
		// 		$('.modal__form').show();
		// 		$('.modal__result').hide();
		// 	}, 150);
		// });

		APP.getDealers(selectDealer, selectDealerOption, selectDealerInputPlaceholder);

		APP.getAllVariants(selectVariant, selectVariantOption, selectVariantInputPlaceholder, 156);

		APP.validate('.modal--xpander-one .form--xpander', {
			isAjax: true,
			onComplete: function (res) {
				const el = $('.modal--xpander-one .form--xpander');
				const selectVariant = el.find('[name="variant_id"]');
				const selectDealer = el.find('[name="dealer_id"]');
				const model_id = 156;
				const model_name = 'Xpander';
				const dealer_name = selectDealer.find('option:selected').text();
				const variant_name = selectVariant.find('option:selected').text();
				const data = {
					...res,
					...dataSubmit,
					dealer_name,
					variant_name,
					model_id,
					model_name,
				};
				const dataInput = data;

				console.log(data);

				loading.show();
				fetch(`${api}/form/testDrive`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						lang: _lang,
					},
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((data) => {
						loading.hide();

						if (data.result === 'success') {
							/// BEGIN Send data to CDP & CRM
							submitCDP(dataInput);
							/// End
							thankContactTitle.html(data.data_dealer.acf.company_name);
							thankContactAddress.html(data.data_dealer.acf.address);

							const thankContactPhoneSaleList = data.data_dealer.acf.phone_sales.map((item) => {
								return `<a href="tel:${item.phone_sale}">${item.phone_sale} (${thankContactPhoneSaleText})</a>`;
							});
							const thankContactPhoneServiceList = data.data_dealer.acf.phone_services.map(
								(item) => {
									return `<a href="tel:${item.phone_service}" target="_blank">${item.phone_service} (${thankContactPhoneServiceText})</a>`;
								}
							);

							const thankContactWebHTML = `<a href="${data.data_dealer.acf.website}" target="_blank">${data.data_dealer.acf.website}</a>`;

							if (
								data.data_dealer.acf.phone_sales.length > 0 ||
								data.data_dealer.acf.phone_services.length > 0
							)
								thankContactPhone.html(
									`<i class="icon icon--phone-red"></i> ${thankContactPhoneSaleList} ${thankContactPhoneServiceList}`
								);
							thankContactWeb.html(`<i class="icon icon--lap-red"></i> ${thankContactWebHTML}`);

							$('.modal__form').hide();
							$('.modal__result').show();
						}
					});
			},
		});

		APP.validate('.modal--xpander-two .form--xpander', {
			isAjax: true,
			onComplete: function (res) {
				const el = $('.modal--xpander-two .form--xpander');
				const selectVariant = el.find('[name="variant_id"]');
				const selectDealer = el.find('[name="dealer_id"]');
				const model_id = 156;
				const model_name = 'Xpander';
				const dealer_name = selectDealer.find('option:selected').text();
				const variant_name = selectVariant.find('option:selected').text();
				const data = {
					...res,
					...dataSubmit,
					dealer_name,
					variant_name,
					model_id,
					model_name,
				};
				const dataInput = data;

				loading.show();
				fetch(`${api}/form/testDrive`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						lang: _lang,
					},
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((data) => {
						loading.hide();

						if (data.result === 'success') {
							/// BEGIN Send data to CDP & CRM
							submitCDP(dataInput);
							/// End
							thankContactTitle.html(data.data_dealer.acf.company_name);
							thankContactAddress.html(data.data_dealer.acf.address);

							const thankContactPhoneSaleList = data.data_dealer.acf.phone_sales.map((item) => {
								return `<a href="tel:${item.phone_sale}">${item.phone_sale} (${thankContactPhoneSaleText})</a>`;
							});
							const thankContactPhoneServiceList = data.data_dealer.acf.phone_services.map(
								(item) => {
									return `<a href="tel:${item.phone_service}" target="_blank">${item.phone_service} (${thankContactPhoneServiceText})</a>`;
								}
							);

							const thankContactWebHTML = `<a href="${data.data_dealer.acf.website}" target="_blank">${data.data_dealer.acf.website}</a>`;

							if (
								data.data_dealer.acf.phone_sales.length > 0 ||
								data.data_dealer.acf.phone_services.length > 0
							)
								thankContactPhone.html(
									`<i class="icon icon--phone-red"></i> ${thankContactPhoneSaleList} ${thankContactPhoneServiceList}`
								);
							thankContactWeb.html(`<i class="icon icon--lap-red"></i> ${thankContactWebHTML}`);

							$('.modal__form').hide();
							$('.modal__result').show();
						}
					});
			},
		});

		APP.validate('.modal--xpander-three .form--xpander', {
			isAjax: true,
			onComplete: function (res) {
				const el = $('.modal--xpander-three .form--xpander');
				const selectVariant = el.find('[name="variant_id"]');
				const selectDealer = el.find('[name="dealer_id"]');
				const model_id = 156;
				const model_name = 'Xpander';
				const dealer_name = selectDealer.find('option:selected').text();
				const variant_name = selectVariant.find('option:selected').text();
				const data = {
					...res,
					...dataSubmit,
					dealer_name,
					variant_name,
					model_id,
					model_name,
				};
				const dataInput = data;

				loading.show();
				fetch(`${api}/form/testDrive`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						lang: _lang,
					},
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((data) => {
						loading.hide();

						if (data.result === 'success') {
							/// BEGIN Send data to CDP & CRM
							submitCDP(dataInput);
							/// End
							thankContactTitle.html(data.data_dealer.acf.company_name);
							thankContactAddress.html(data.data_dealer.acf.address);

							const thankContactPhoneSaleList = data.data_dealer.acf.phone_sales.map((item) => {
								return `<a href="tel:${item.phone_sale}">${item.phone_sale} (${thankContactPhoneSaleText})</a>`;
							});
							const thankContactPhoneServiceList = data.data_dealer.acf.phone_services.map(
								(item) => {
									return `<a href="tel:${item.phone_service}" target="_blank">${item.phone_service} (${thankContactPhoneServiceText})</a>`;
								}
							);

							const thankContactWebHTML = `<a href="${data.data_dealer.acf.website}" target="_blank">${data.data_dealer.acf.website}</a>`;

							if (
								data.data_dealer.acf.phone_sales.length > 0 ||
								data.data_dealer.acf.phone_services.length > 0
							)
								thankContactPhone.html(
									`<i class="icon icon--phone-red"></i> ${thankContactPhoneSaleList} ${thankContactPhoneServiceList}`
								);
							thankContactWeb.html(`<i class="icon icon--lap-red"></i> ${thankContactWebHTML}`);

							$('.modal__form').hide();
							$('.modal__result').show();
						}
					});
			},
		});
	}

	function submitCDP(dataInput) {
		// dataLayer = dataLayer || [];
		const mmv_campaign = 'MA.RN.MY22';
		let model_name = 'Xpander';
		if (dataInput.variant_name === 'Cross') {
			model_name = 'XpanderCross';
		}
		let tags = model_name;
		if (mmv_campaign) {
			tags += ',' + mmv_campaign;
		}
		dataLayer.push({
			event: 'pangoTrack',
			eventAction: 'generate_lead',
			label: '',
			user_data: {
				email_address: dataInput.email,
				phone_number: dataInput.phone,
			},
			cdpData: {
				properties: {
					fullname: dataInput.name,
					email: dataInput.email,
					phone: dataInput.phone,
					listTagsModel: model_name,
					listTagsCampaign: mmv_campaign,
					company: tags,
					address: dataInput.dealer_name,
					note: dataInput.note,
				},
			},
		});
		setCookie('registered-test-drive', mmv_campaign, 60 * 24 * 100); // 100 day
	}

	init();
};
