APP.contactUs = function () {
	const el = $('.newsletter');
	const formNewsletter = el.find('.form--newsletter');
	const email = formNewsletter.find('[name="email"]');
	const contactInfo = $('.contact-info');
	const contactInfoItem = contactInfo.find('.contact-info__item--map');
	const contactInfoTitle = contactInfoItem.find('.title');

	function init() {
		contactInfoTitle.on('click', handleContactInfo);

		setTimeout(() => {
			contactInfoItem.eq(0).find('.title').trigger('click');
		}, 300);

		APP.validate('.form--newsletter', {
			isAjax: true,
			onComplete: function (dataForm) {
				const formData = new FormData();
				formData.append('email', dataForm.email);
				fetch(`${apiDealer}/saveContact.php`, {
					method: 'POST',
					body: formData,
				})
					.then((res) => res.json())
					.then((data) => {
						// loading.hide();
						if (data.result === 'success') {
							el.find('.form--newsletter').hide();
							el.find('.subed').show();
						} else {
							email.parent('.form__group').find('.form__error').text(err.errorMessage);
						}
					})
					.catch((err) => {
						// loading.hide();
						email.parent('.form__group').find('.form__error').text(err.errorMessage);
					});
			},
		});
	}

	function handleContactInfo() {
		const obj = $(this);
		const parent = obj.parent();
		const lng = parent.data('lng');
		const lat = parent.data('lat');

		contactInfoTitle.removeClass(CLASS._active);
		obj.addClass(CLASS._active);

		const myOptions = { lat: lat, lng: lng };
		if (google) {
			const map = new google.maps.Map(document.getElementById('map'), {
				zoom: 14,
				center: myOptions,
			});

			new google.maps.Marker({
				animation: google.maps.Animation.DROP,
				position: myOptions,
				map: map,
			});
		}
	}

	init();
};
