APP.showPopup3 = function () {
	const loanCalculator = $('.loan-calculator');
	const loanPackage = $('.loan-package');

	function init() {
		if (loanCalculator.length === 0 && loanPackage.length === 0) return;

		APP.modal('.modal--xpander-three');
	}

	init();
};
