APP.costEstimate = function () {
	const el = $('.cost-estimate');
	const slickCarChoose = el.find('.slick--car-choose');
	const provinceSelect = el.find('[name="province"]');
	const modelId = el.find('[name="model_id"]');
	const modelName = el.find('[name="model_name"]');
	const usagePurpose = el.find('[name="usage_purpose"]');
	const customerType = el.find('[name="customer_type"]');
	const provinceSelectOption = provinceSelect.data('label-province');
	const provinceInputPlaceholder = provinceSelect.data('input-placeholder');
	let isVariant = true;

	const costEstimateSummary = $('.cost-estimate-summary');
	const cesInfo = costEstimateSummary.find('.ces-info');
	const cesBill = costEstimateSummary.find('.ces-bill');
	const cesSummary = costEstimateSummary.find('.ces-summary');

	const cesThumb = costEstimateSummary.find('.ces-thumb');

	const cesInfoTitle = cesInfo.find('.title');
	const cesSummaryTitle = cesSummary.find('.title');
	const cesSummaryVariant = cesSummary.find('.ces-summary__variant');
	const cesSummaryProvince = cesSummary.find('.ces-summary__province');
	const cesSummaryUsage = cesSummary.find('.ces-summary__usage');
	const cesSummaryType = cesSummary.find('.ces-summary__type');
	const cesSummaryBtn = cesSummary.find('.btn');

	const cesBillPrice = cesBill.find('.ces-bill__price');
	const cesBillRegistration = cesBill.find('.ces-bill__registration');
	const cesBillLicense = cesBill.find('.ces-bill__license');
	const cesBillCetification = cesBill.find('.ces-bill__certification');
	const cesBillRoad = cesBill.find('.ces-bill__road');
	const cesBillCivil = cesBill.find('.ces-bill__civil');
	const cesBillTotal = cesBill.find('.ces-bill__total');
	const btnCescal = cesBill.find('.ces-bill__button .btn--red');
	let btnCescalLink = btnCescal.attr('href');
	// const cesThumbnail = estHolder.find('.cost-estimate__thumb img');

	const [getCostEstimate, setCostEstimate] = useCostEstimate();
	const [getFilterCostEstimate, setFilterCostEstimate] = useFilterCostEstimate();

	function init() {
		if (el.length === 0) return;

		handleInitialLoad();

		slickCarChoose.slick({
			infinite: true,
			centerMode: true,
			centerPadding: '180px',
			slidesToShow: 3,
			slidesToScroll: 3,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						arrows: false,
						infinite: true,
						centerMode: true,
						centerPadding: '60px',
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		});

		slickCarChoose.on('swipe', function () {
			handleCarChoose();
		});

		slickCarChoose.on('click', '.slick-active', onClickSlickItem);

		APP.getProvinces(provinceSelect, provinceSelectOption, provinceInputPlaceholder);

		APP.validate('.form--cost-estimate', {
			isAjax: true,
			onComplete: function (data) {
				const filterCostEstimate = getFilterCostEstimate();
				const province_name = provinceSelect.find('option:selected').text();
				const usage_purpose_name = usagePurpose.find('option:selected').text();
				const customer_type_name = customerType.find('option:selected').text();
				const variant_price = $('[name="variant"]:checked').data('variant-price');
				const variant_thumb = $('[name="variant"]:checked').data('variant-thumb');
				const variant_id = parseInt($('[name="variant"]:checked').data('variant-id'));
				const model_id = parseInt(modelId.val());

				const dataSubmit = {
					...data,
					province_name,
					variant_price,
					usage_purpose_name,
					customer_type_name,
					variant_thumb,
				};

				if (filterCostEstimate.length > 0) {
					const item = filterCostEstimate[0];
					const bhtn_dan_su_khong_kinh_doanh_van_tai = parseInt(
						item.acf.bhtn_dan_su_khong_kinh_doanh_van_tai
					);
					const bhtn_dan_su_kinh_doanh_van_tai = parseInt(item.acf.bhtn_dan_su_kinh_doanh_van_tai);
					const phi_bao_tri_duong_bo_ca_nhan = parseInt(item.acf.phi_bao_tri_duong_bo_ca_nhan);
					const phi_bao_tri_duong_bo_doanh_nghiep = parseInt(
						item.acf.phi_bao_tri_duong_bo_doanh_nghiep
					);
					const phi_bien_so = parseInt(item.acf.phi_bien_so);
					const phi_dang_kiem = parseInt(item.acf.phi_dang_kiem);
					const thue_truoc_ba = parseInt(
						model_id === 168 ? item.acf.thue_truoc_ba_2 : item.acf.thue_truoc_ba
					);
					const phi_truoc_ba = parseInt(dataSubmit.variant_price * thue_truoc_ba) / 100;

					el.hide();
					costEstimateSummary.show();

					const posY = costEstimateSummary.position().top;
					const headerH = $('.header').height();
					let phi_bao_tri_duong_bo = 0;
					let bhtn_dan_su = 0;

					APP.setScrollTopAnimation(posY - headerH);

					cesInfoTitle.html(dataSubmit.model_name);
					cesThumb.html(`<img class="img img--full" src="${variant_thumb}" alt="">`);

					cesSummaryTitle.html(dataSubmit.model_name);
					cesSummaryVariant.html(dataSubmit.variant);
					cesSummaryProvince.html(item.title.rendered);
					cesSummaryUsage.html(dataSubmit.usage_purpose_name);
					cesSummaryType.html(dataSubmit.customer_type_name);

					cesBillPrice.html(APP.numberWithCommas(dataSubmit.variant_price));
					cesBillRegistration.html(APP.numberWithCommas(phi_dang_kiem));
					cesBillLicense.html(APP.numberWithCommas(phi_bien_so));
					cesBillCetification.html(APP.numberWithCommas(phi_truoc_ba));
					if (dataSubmit.customer_type === 'personal') {
						phi_bao_tri_duong_bo = phi_bao_tri_duong_bo_ca_nhan;
						cesBillRoad.html(APP.numberWithCommas(phi_bao_tri_duong_bo_ca_nhan));
					} else {
						phi_bao_tri_duong_bo = phi_bao_tri_duong_bo_doanh_nghiep;
						cesBillRoad.html(APP.numberWithCommas(phi_bao_tri_duong_bo_doanh_nghiep));
					}

					if (dataSubmit.usage_purpose === 'transportation-business') {
						bhtn_dan_su = bhtn_dan_su_kinh_doanh_van_tai;
						cesBillCivil.html(APP.numberWithCommas(bhtn_dan_su_kinh_doanh_van_tai));
					} else {
						bhtn_dan_su = bhtn_dan_su_khong_kinh_doanh_van_tai;
						cesBillCivil.html(APP.numberWithCommas(bhtn_dan_su_khong_kinh_doanh_van_tai));
					}

					const total =
						dataSubmit.variant_price +
						phi_bien_so +
						phi_truoc_ba +
						phi_dang_kiem +
						phi_bao_tri_duong_bo +
						bhtn_dan_su;
					cesBillTotal.html(APP.numberWithCommas(total));

					btnCescalLink = `${btnCescalLink}?model_id=${model_id}&variant_id=${variant_id}`;
					btnCescal.attr('href', btnCescalLink);

					// tracking to CDP
					dataLayer.push({
						event: 'pangoTrack',
						eventAction: 'cost_simulation',
						cdpData: {
							properties: {
								model: dataSubmit.model_name,
								variant: dataSubmit.variant,
								province: item.title.rendered,
								usage: dataSubmit.usage_purpose_name,
								customerType: dataSubmit.customer_type_name,
							},
						},
					});
					// end tracking
				}
			},
		});

		provinceSelect.on('change', function () {
			const filterCostEstimate = getCostEstimate().filter((item) => item.slug === $(this).val());
			setFilterCostEstimate(filterCostEstimate);
		});

		cesSummaryBtn.on('click', function (e) {
			e.preventDefault();

			el.show();
			costEstimateSummary.hide();

			const posY = el.position().top;
			const headerH = $('.header').height();

			APP.setScrollTopAnimation(posY - headerH);
		});
	}

	function useCostEstimate() {
		let _costEstimate = null;

		function getCostEstimate() {
			return _costEstimate;
		}

		function setCostEstimate(data) {
			_costEstimate = [...data];
		}

		return [getCostEstimate, setCostEstimate];
	}

	function useFilterCostEstimate() {
		let _costFilterEstimate = null;

		function getFilterCostEstimate() {
			return _costFilterEstimate;
		}

		function setFilterCostEstimate(data) {
			_costFilterEstimate = data;
		}

		return [getFilterCostEstimate, setFilterCostEstimate];
	}

	async function getData(url) {
		const response = await fetch(url, {
			headers: {
				'Content-Type': 'application/json',
				lang: _lang,
			},
		});

		const res = await response.json();

		return res;
	}

	async function handleInitialLoad() {
		const data = await getData(`${api}/wp/generate/costEstimation`);
		setCostEstimate(data.items);
	}

	function onClickSlickItem(e) {
		e.stopPropagation();
		const index = $(this).data('slick-index');
		slickCarChoose.slick('slickGoTo', index);
		handleCarChoose();
	}

	function handleCarChoose() {
		const slickSlide = slickCarChoose.find('.slick-slide');
		const formGroup = slickCarChoose.next();
		const formError = formGroup.find('.form__error');
		const slickActiveCenter = slickCarChoose.find('.slick-active.slick-center');
		const title = slickActiveCenter.find('.title');
		const modelIdValue = parseInt(title.data('model-id'));
		const modelNameValue = title.text();
		slickSlide.removeClass(CLASS._selected);
		slickActiveCenter.addClass(CLASS._selected);
		formGroup.removeClass(CLASS._error).removeClass(CLASS._focus);
		formError.text('');
		modelId.val(modelIdValue);
		modelName.val(modelNameValue);

		APP.getData(`${api}/wp/variant?model_id=${modelIdValue}`, function (items) {
			renderVariantList(items);
		});

		$('.car-degrees').removeClass(CLASS._active);
		$(`.car-degrees[data-model-id="${modelIdValue}"]`).addClass(CLASS._active);
	}

	function renderVariantList(data) {
		let items = data.items
			.filter((item) => item.acf?.price)
			.map((item, idx) => item.acf.show_on_form && renderVariantItem(item, idx))
			.join('');
		// cesThumbnail.attr('src', data.items.filter((item) => item.acf?.price)[0].image_thumbnail);
		$('.variant-box .row').html(items);
		$('.variant-choose').show();
		// $('.variant-box__item').on('click', function () {
		// 	cesThumbnail.attr('src', $(this).find('input').data('variant-thumb'));
		// });
		if (isVariant) {
			isVariant = false;
			APP.carDegrees('.car-degrees--model');
		}
	}

	function renderVariantItem(data, idx) {
		const {
			id,
			title,
			description,
			image_thumbnail,
			acf: { price, price_text_has_vat },
		} = data;

		if (!price) return '';

		return `<div class="row__col row__col--lg-6">
				<label class="variant-box__item" for="elite-edition-${idx}">
					<input id="elite-edition-${idx}" type="radio" name="variant" value="${title}" data-variant-id="${id}" data-variant-thumb="${image_thumbnail}" data-variant-price="${price}" ${
			idx === 0 ? 'checked' : ''
		}>
					<div class="variant-box__content">
						<h4 class="title title--4 title--uppercase">${title}</h4>
						<div class="variant-box__desc">${description}</div>
						<div class="variant-box__price"><strong>${price_text_has_vat}</strong></div>
					</div>
				</label>
			</div>`;
	}

	init();
};
