APP.fleetsale = function () {
	const el = $('.fleetsale');
	const loading = el.find('.loading');
	const selectDealer = el.find('[name="dealer_id"]');
	const selectDealerOption = selectDealer.data('label-dealer');
	const selectDealerInputPlaceholder = selectDealer.data('input-placeholder');
	const slickCarChoose = el.find('.slick--car-choose');
	const modelId = el.find('[name="model_id"]');
	const modelName = el.find('[name="model_name"]');

	const thankYou = $('.thank-you');
	const thankYouThumb = thankYou.find('.thank-you__thumb img');

	const dataSubmit = {
		utm_source: getCookie('utm_source') ? getCookie('utm_source') : '',
		utm_content: getCookie('utm_content') ? getCookie('utm_content') : '',
		utm_medium: getCookie('utm_medium') ? getCookie('utm_medium') : '',
		utm_campaign: getCookie('utm_campaign') ? getCookie('utm_campaign') : '',
		mmv_campaign: getCookie('mmv_campaign') ? getCookie('mmv_campaign') : '',
		form_type: getParameterByName('form_type') ? getParameterByName('form_type') : 'test-drive',
		location: window.location.href,
		env: getParameterByName('env') ? getParameterByName('env') : 'dev',
	};

	function init() {
		if (el.length === 0 || $('.tradein').length > 0) return;

		slickCarChoose.slick({
			infinite: true,
			centerMode: true,
			centerPadding: '180px',
			slidesToShow: 3,
			slidesToScroll: 3,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						arrows: false,
						infinite: true,
						centerMode: true,
						centerPadding: '60px',
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		});

		slickCarChoose.on('swipe', function () {
			handleCarChoose();
		});

		slickCarChoose.on('afterChange', function () {
			handleCarChoose();
		});

		slickCarChoose.on('click', '.slick-active', onClickSlickItem);

		APP.getDealers(selectDealer, selectDealerOption, selectDealerInputPlaceholder);

		thankYou.hide();

		APP.validate('.form--fleetsale', {
			isAjax: true,
			onComplete: function (res) {
				const data = {
					...res,
					...dataSubmit,
				};

				loading.show();
				fetch(`${api}/form/fleetsales`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						lang: _lang,
					},
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((data) => {
						loading.hide();

						if (data.result === 'success') {
							el.addClass(CLASS._blur);
							gsap.set(thankYou, {
								display: 'block',
								alpha: 0,
							});

							gsap.to(thankYou, {
								duration: 0.4,
								alpha: 1,
								onComplete: function () {
									const thankYouPos = thankYou.position().top;
									APP.setScrollTopAnimation(thankYouPos);
								},
							});
						} else {
							console.log(data);
						}
					})
					.catch((err) => {
						loading.hide();
						console.log(err);
					});
			},
		});
	}

	function onClickSlickItem(e) {
		e.stopPropagation();
		const index = $(this).data('slick-index');
		slickCarChoose.slick('slickGoTo', index);
		handleCarChoose();
	}

	function handleCarChoose() {
		const slickSlide = slickCarChoose.find('.slick-slide');
		const formGroup = slickCarChoose.next();
		const formError = formGroup.find('.form__error');
		const slickActiveCenter = slickCarChoose.find('.slick-active.slick-center');
		const title = slickActiveCenter.find('.title');
		const parent = title.parents('.car-choose__main');
		const imgSrc = parent.find('.img').data('src');
		const modelIdValue = parseInt(title.data('model-id'));
		const modelNameValue = title.text();
		slickSlide.removeClass(CLASS._selected);
		slickActiveCenter.addClass(CLASS._selected);
		formGroup.removeClass(CLASS._error).removeClass(CLASS._focus);
		formError.text('');
		modelId.val(modelIdValue);
		modelName.val(modelNameValue);
		thankYouThumb.attr('src', imgSrc);
	}

	init();
};
