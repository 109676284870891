APP.footer = function () {
	$('.slick--navigation').slick({
		slidesToShow: 4,
		slidesToScroll: 4,
		responsive: [
			{
				breakpoint: 992,
				settings: {
					arrows: true,
					infinite: false,
					slidesToShow: 3,
					slidesToScroll: 1,
				},
			},
		],
	});

	$('.nav-foot__title').on('click', function (e) {
		e.preventDefault();
		const obj = $(this);
		const parent = obj.parent();

		if (parent.hasClass(CLASS._show)) {
			parent.removeClass(CLASS._show);
		} else {
			$('.nav-foot').removeClass(CLASS._show);
			parent.addClass(CLASS._show);
		}
	});

	// Show .cta-contact__top on scroll 1000px
	$(window).on('scroll', function () {
		const scroll = $(window).scrollTop();
		const obj = $('.cta-contact__top');

		if (scroll > 1000) {
			obj.addClass(CLASS._show);
		} else {
			obj.removeClass(CLASS._show);
		}
	});
};
