APP.recallChecking = function () {
	const url = 'https://mitsubishi-motors.com.vn/webapi/v1/recall/info';
	const recallResultBody = $('.recall-result');
	const errorEle = $('.form__error');
	let loading = false;
	$('#enter-code').on('keyup', function () {
		recallResultBody.html('');
		errorEle.text('');
	});
	$('.recall-check form .btn').on('click', function (e) {
		e.preventDefault();
		if (loading) return;
		const code = $('#enter-code').val();
		const recallResultBodyTop = recallResultBody.position().top;

		if (code && code.length === 17) {
			errorEle.text('');
			loading = true;
			// fetch(`${api}/recall/info`, {
			// 	method: 'GET',
			// 	headers: {
			// 		'Content-Type': 'application/json',

			// 		lang: _lang,
			// 	},
			// })
			// .then(res => res.json())
			// .then(data => {

			// });

			$.get(url, { vin_number: code }, function (res) {
				if (res.items?.length > 0) {
					let html = `<div class="recall-result__main">
          <div class="recall-result__thumb">
            <div class="container container--small"><img class="img img--full" src="${res.items[0].image}" alt=""></div>
          </div>
          <div class="recall-result__body">
            <div class="container container--small">`;

					res.items.forEach((item) => {
						html =
							html +
							`<h4>${item.reason}</h4>
							<div class="row">

							<div class="row__col row__col--lg-4">
								<div class="recall-result__item">
									<p class="recall-result__label">Frame Number (VIN)</p>
									<p class="recall-result__value">${item.vin}</p>
								</div>
							</div>
							<div class="row__col row__col--lg-4">
								<div class="recall-result__item">
									<p class="recall-result__label">Recall Code</p>
									<p class="recall-result__value">${item.recall_code}</p>
								</div>
							</div>
							<div class="row__col row__col--lg-4">
								<div class="recall-result__item">
									<p class="recall-result__label">Recall Event</p>
									<p class="recall-result__value"><a href='${item.link}'>${item.reason}</a></p>
								</div>
							</div>
							<div class="row__col row__col--lg-4">
								<div class="recall-result__item">
									<p class="recall-result__label">Status</p>
									<p class="recall-result__value">${item.status}</p>
								</div>
							</div>
							<div class="row__col row__col--lg-4">
								<div class="recall-result__item">
									<p class="recall-result__label">Responsible Distributor</p>
									<p class="recall-result__value">${item.replaced_by}</p>
								</div>
							</div>
						</div>`;
					});
					html =
						html +
						`</div>
						</div>
					</div>`;
					recallResultBody.html(html);
					APP.setScrollTopAnimation(recallResultBodyTop);
					loading = false;
				} else {
					errorEle.text(res.errorMessage);
				}
			});
		} else {
			errorEle.text(errorEle.data('error-wrong'));
		}
	});
};
