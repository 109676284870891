APP.configurator = function () {
	const el = $('.configurator');
	const backLink = el.data('back-link');
	const progress = el.find('.progress');
	const progressItem = progress.find('.progress__item');
	const progressLinkCar = progressItem.find('.progress__link--car');
	const progressLinkModel = progressItem.find('.progress__link--model');
	const progressItemModel = progress.find('.progress__item--model');
	const progressItemAccessory = progress.find('.progress__item--accessory');
	const progressColor = progress.find('.progress__color');
	const progressStep = progressColor.find('.progress-step');
	const progressStepItem = progressStep.find('.progress-step__item');
	const progressStepItemColor = progressStep.find('.progress-step__item--color');
	const progressStepItemFabric = progressStep.find('.progress-step__item--fabric');
	const progressStepItemSummary = progressStep.find('.progress-step__item--summary');
	const choose = el.find('.choose');
	const chooseItem = choose.find('.choose__item');
	const chooseItemModel = choose.find('.choose__item--model');
	const chooseItemAccessory = choose.find('.choose__item--accessory');
	const chooseItemFabric = choose.find('.choose__item--fabric');
	const chooseItemResult = choose.find('.choose__item--result');
	const summary = el.find('.summary');
	const summaryTitle = summary.find('.summary__title-text');
	const summaryItem = summary.find('.summary__item');
	const summaryItemModel = summary.find('.summary__item--model');
	const summaryItemAccessory = summary.find('.summary__item--accessory');
	const summaryToggle = summaryItem.find('.summary__toggle');
	const summaryPriceText = summary.find('.summary__price-text');
	const summaryTotal = summary.find('.summary__total');
	const summaryTotalPrice = summary.find('.summary__total-price');
	const summaryThumb = summary.find('.summary__thumb');
	const summaryDescription = summary.find('.summary__description');
	const summaryModel = summary.find('.summary__model');
	const summaryVariant = summary.find('.summary__variant');
	const summaryCollapse = summary.find('.collapse');
	const collapseColorText = summaryCollapse.find('.collapse__color-text');
	const collapseColorPrice = summaryCollapse.find('.collapse__color-price');
	const collapseFabricText = summaryCollapse.find('.collapse__fabric-text');
	const collapseFabricPrice = summaryCollapse.find('.collapse__fabric-price');
	const summaryCollapseItemColor = summaryCollapse.find('.collapse__item--color');
	const summaryCollapseItemFabric = summaryCollapse.find('.collapse__item--fabric');
	const btnColor = summaryItem.find('.btn--color');
	const btnFabric = summaryItem.find('.btn--fabric');
	const btnSummary = summaryItem.find('.btn--summary');
	const configuratorResult = el.find('.configurator-result');
	const configuratorResultThumb = configuratorResult.find('.configurator-result__thumb');
	const btnCancel = $('.btn--cancel');
	const btnChangeModel = $('.btn--change-model');
	const modelId = APP.getParameterByName('model_id');
	const origin = window.location.origin;
	const pathname = window.location.pathname;

	let variantIdParam = parseInt(APP.getParameterByName('variant_id')) || '';
	let colorIdParam = APP.getParameterByName('colour_id') || '';
	let fabricIdParam = APP.getParameterByName('fabric_id') || '';
	let stepConfigurator = APP.getParameterByName('step') || '';
	let variantId = '';
	let colorId = '';
	let fabricId = '';
	let modelName = '';
	let colorText = '';
	let colorPriceText = 0;
	let colorPriceTextIncluded = collapseColorText.data('price-included');
	let fabricText = '';
	let fabricPriceText = 0;
	let fabricPriceTextIncluded = collapseFabricText.data('price-included');
	let variantPriceTotal = 0;
	let colorPriceTotal = 0;
	let fabricPriceTotal = 0;
	let isColor = false;

	const loading = $('.loading');

	function init() {
		if (el.length === 0) return;

		scrollSummary();
		WINDOW.on('scroll', scrollSummary);
		WINDOW.on('resize', scrollSummary);

		$('.cta-contact').addClass(CLASS._current);

		btnCancel.on('click', function (evt) {
			evt.preventDefault();
			$('.modal__close').trigger('click');
		});

		progressLinkCar.on('click', function (evt) {
			evt.preventDefault();
			APP.modal('.modal--change-your-car');
		});

		progressLinkModel.on('click', function (evt) {
			evt.preventDefault();
			APP.modal('.modal--change-model');
		});

		$('.btn--step-model').on('click', function (evt) {
			evt.preventDefault();
			APP.modal('.modal--change-model');
			$('.test-drive').hide();
			$('.thank-you').hide();
			if ($('.summary__item--accessory').hasClass(CLASS._active)) {
				summaryToggle.trigger('click');
			}
			APP.setScrollTopAnimation(0);
		});

		btnChangeModel.on('click', function (evt) {
			evt.preventDefault();
			el.removeClass(CLASS._active);
			progressItem.removeClass(CLASS._active);
			progressItemModel.addClass(CLASS._active);
			progressStepItem.addClass(CLASS._active).removeClass(CLASS._current);
			progressStepItemColor.addClass(CLASS._current);
			chooseItem.removeClass(CLASS._active).hide();
			summaryItem.removeClass(CLASS._active).hide();
			chooseItemModel.addClass(CLASS._active).show();
			summaryItemModel.addClass(CLASS._active).show();
			btnFabric.removeAttr('style');
			btnSummary.removeAttr('style');
			btnColor.show();
			colorPriceTotal = 0;
			fabricPriceTotal = 0;
			addPriceToSummary();
			$('.test-drive').hide();
			$('.thank-you').hide();
			$('.modal__close').trigger('click');
			addParams(modelId, variantId, '', '', '');
			APP.setScrollTopAnimation(0);
		});

		btnColor.on('click', function (evt) {
			evt.preventDefault();
			const variantActive = $('.variant-box__item--variant.is-active');
			const variantActiveName = variantActive.data('variant-title');

			const colorActive = $('.variant-box__item--color.is-active');
			const colorActiveId = colorActive.data('color-id');
			const colorActiveTitle = colorActive.data('color-title');
			const colorActivePrice = colorActive.data('color-price');
			const colorActivePriceText = colorActive.data('color-price-text');
			const colorActiveThumb = colorActive.data('color-thumb');
			colorId = colorActiveId;
			colorPriceTotal = colorActivePrice;
			fabricPriceTotal = 0;
			colorText = colorActiveTitle;
			colorPriceText = colorActivePriceText;

			progressItem.removeClass(CLASS._active);
			progressItemAccessory.addClass(CLASS._active);
			chooseItem.removeClass(CLASS._active).hide();
			chooseItemAccessory.addClass(CLASS._active).show();
			summaryItem.hide();
			summaryItemAccessory.show();
			summaryCollapseItemColor.addClass(CLASS._active);
			collapseColorText.html(colorText);
			if (colorPriceTotal === 0) {
				collapseColorPrice.html(colorPriceTextIncluded);
			} else {
				collapseColorPrice.html(colorPriceText);
			}
			summaryTitle.html(modelName + ' ' + variantActiveName);
			$('.configurator-result__title').html(modelName);
			summaryThumb.html(`<img class="img" src="${colorActiveThumb}" alt="">`);
			configuratorResultThumb.html(`<img class="img img--full" src="${colorActiveThumb}" alt="">`);

			addParams(modelId, variantId, colorId, '', 'colorSelector');
			addPriceToSummary();
			shortLinkColor();

			if (!isColor) {
				isColor = true;
			}

			APP.carDegrees('.car-degrees--color');

			APP.setScrollTopAnimation(0);
			sendEvent('variant');
		});

		$('.btn--step-fabric').on('click', function (evt) {
			evt.preventDefault();
			progressStepItemFabric.trigger('click');
		});

		$('.btn--step-color').on('click', function (evt) {
			evt.preventDefault();
			progressStepItemColor.trigger('click');
		});

		progressStepItemColor.on('click', function (evt) {
			evt.preventDefault();

			if (progressStepItemColor.hasClass(CLASS._current)) return;

			el.removeClass(CLASS._active);
			progressStepItem.removeClass(CLASS._current).removeClass(CLASS._done).addClass(CLASS._active);
			progressStepItemColor.addClass(CLASS._active).addClass(CLASS._current);

			chooseItem.removeClass(CLASS._active).hide();
			btnSummary.hide();
			btnFabric.show();
			chooseItemAccessory.addClass(CLASS._active).show();
			fabricPriceTotal = 0;
			addPriceToSummary();
			$('.test-drive').hide();
			$('.thank-you').hide();
			if ($('.summary__item--accessory').hasClass(CLASS._active)) {
				summaryToggle.trigger('click');
			}
			if (!isColor) {
				isColor = true;
				APP.carDegrees('.car-degrees--color');
			}
			addParams(modelId, variantId, colorId, '', 'colorSelector');
			APP.setScrollTopAnimation(0);
		});

		progressStepItemFabric.on('click', function (evt) {
			evt.preventDefault();

			if (progressStepItemFabric.hasClass(CLASS._current)) return;

			el.removeClass(CLASS._active);
			progressStepItemSummary.removeClass(CLASS._current).addClass(CLASS._active);
			progressStepItemFabric
				.addClass(CLASS._active)
				.addClass(CLASS._current)
				.removeClass(CLASS._done);

			chooseItemFabric.addClass(CLASS._active).show();
			chooseItemResult.removeClass(CLASS._active).hide();
			summaryTotal.hide();
			addPriceToSummary();
			$('.test-drive').hide();
			$('.thank-you').hide();
			if ($('.summary__item--accessory').hasClass(CLASS._active)) {
				summaryToggle.trigger('click');
			}
			addParams(modelId, variantId, colorId, fabricId, 'fabricSelector');
			APP.setScrollTopAnimation(0);
		});

		btnFabric.on('click', function (evt) {
			evt.preventDefault();
			const fabricActive = $('.variant-box__item--fabric.is-active');
			const fabricActiveId = fabricActive.data('fabric-id');
			const fabricActiveName = fabricActive.data('fabric-title');
			const fabricActivePrice = fabricActive.data('fabric-price');
			const fabricActivePriceText = fabricActive.data('fabric-price-text');
			fabricPriceTotal = fabricActivePrice;
			fabricId = fabricActiveId;
			fabricText = fabricActiveName;
			fabricPriceText = fabricActivePriceText;

			btnColor.hide();
			btnFabric.hide();
			summaryItem.hide();
			summaryItemAccessory.show();
			chooseItem.removeClass(CLASS._active).hide();
			btnSummary.show();
			progressItem.removeClass(CLASS._active);
			progressItemAccessory.addClass(CLASS._active);
			progressStepItemColor
				.removeClass(CLASS._active)
				.removeClass(CLASS._current)
				.addClass(CLASS._done);
			chooseItemFabric.addClass(CLASS._active).show();
			progressStepItemFabric.addClass(CLASS._current);
			summaryCollapseItemFabric.addClass(CLASS._active);
			collapseFabricText.html(fabricText);
			collapseColorText.html(colorText);
			if (colorPriceTotal === 0) {
				collapseColorPrice.html(colorPriceTextIncluded);
			} else {
				collapseColorPrice.html(colorPriceText);
			}

			if (fabricPriceTotal === 0) {
				collapseFabricPrice.html(fabricPriceTextIncluded);
			} else {
				collapseFabricPrice.html(fabricPriceText);
			}

			addParams(modelId, variantId, colorId, fabricId, 'fabricSelector');
			addPriceToSummary();
			shortLinkFabric();
			APP.setScrollTopAnimation(0);
			sendEvent('color');
		});

		btnSummary.on('click', function (evt) {
			evt.preventDefault();

			BODY.removeClass(CLASS._noscroll);
			summaryItemModel.hide();
			summaryItemAccessory.show();
			progressItem.removeClass(CLASS._active);
			progressItemAccessory.addClass(CLASS._active);
			chooseItem.removeClass(CLASS._active).hide();
			chooseItemResult.addClass(CLASS._active).show();
			progressStepItem.removeClass(CLASS._active).removeClass(CLASS._current).addClass(CLASS._done);
			progressStepItemSummary
				.addClass(CLASS._current)
				.addClass(CLASS._active)
				.removeClass(CLASS._done);
			$('.test-drive').show();
			if ($('.test-drive').hasClass(CLASS._blur)) {
				$('.thank-you').show();
			}
			el.addClass(CLASS._active);
			summaryTotal.show();
			addParams(modelId, variantId, colorId, fabricId, 'summarySelector');
			addPriceToSummary();
			shortLinkFabric();
			APP.setScrollTopAnimation(0);
			sendEvent('fabric');
		});

		summaryToggle.on('click', function () {
			const obj = $(this);
			const parent = obj.parents('.summary__item');
			if (!parent.hasClass(CLASS._active)) {
				APP.setScrollTopAnimation(0);
				parent.addClass(CLASS._active);
				BODY.addClass(CLASS._noscroll);
			} else {
				parent.removeClass(CLASS._active);
				BODY.removeClass(CLASS._noscroll);
			}
		});

		$('.summary__copy button').on('click', function (evt) {
			evt.preventDefault();
			copyToClipboard();
		});

		handleInitialLoad();
	}

	function sendEvent(sestep) {
		let semodel = $('.summary__model').text();
		let sevariant = $('.summary__variant').text();
		let secolor = $('.variant-box__item--color.is-active').data('color-title');
		let sefabric = $('.variant-box__item--fabric.is-active').data('fabric-title');
		if (sestep === 'model') {
			sevariant = '';
			secolor = '';
			sefabric = '';
		} else if (sestep === 'variant') {
			secolor = '';
			sefabric = '';
		} else if (sestep === 'color') {
			sefabric = '';
		}
		dataLayer.push({
			event: 'pangoTrack',
			eventAction: 'config_car_' + sestep,
			cdpData: {
				properties: {
					sestep, //variant, color, fabric, summary
					semodel,
					sevariant,
					secolor,
					sefabric,
				},
			},
		});
	}

	function shortLinkVariant() {
		stepConfigurator = APP.getParameterByName('step') || '';

		APP.getData(
			`${api}/wp/configure/shortLink?model_id=${modelId}&variant_id=${variantId}`,
			function (data) {
				$('.summary__copy input').val(mainUrl + '/' + data.page);
			}
		);
	}

	function shortLinkColor() {
		stepConfigurator = APP.getParameterByName('step') || '';

		APP.getData(
			`${api}/wp/configure/shortLink?model_id=${modelId}&variant_id=${variantId}&colour_id=${colorId}&step=${stepConfigurator}`,
			function (data) {
				$('.summary__copy input').val(mainUrl + '/' + data.page);
			}
		);
	}

	function shortLinkFabric() {
		stepConfigurator = APP.getParameterByName('step') || '';

		APP.getData(
			`${api}/wp/configure/shortLink?model_id=${modelId}&variant_id=${variantId}&colour_id=${colorId}&fabric_id=${fabricId}&step=${stepConfigurator}`,
			function (data) {
				$('.summary__copy input').val(mainUrl + '/' + data.page);
			}
		);
	}

	function addParams(modelId, variantId, colorId, fabricId, step) {
		const url = new URL(origin + pathname);

		if (modelId) {
			url.searchParams.set('model_id', modelId);
			summaryCollapseItemColor.removeClass(CLASS._active);
		}

		if (variantId) {
			url.searchParams.set('variant_id', variantId);
		}

		if (colorId) {
			url.searchParams.set('colour_id', colorId);
			summaryCollapseItemColor.addClass(CLASS._active);
			summaryCollapseItemFabric.removeClass(CLASS._active);
		}

		if (fabricId) {
			url.searchParams.set('fabric_id', fabricId);
			summaryCollapseItemFabric.addClass(CLASS._active);
		}

		if (step) {
			url.searchParams.set('step', step);
		}

		window.history.replaceState(null, null, url);
	}

	function copyToClipboard() {
		$('.summary__copy input').select();
		document.execCommand('copy');
	}

	function scrollSummary() {
		const scrollTop = $(WINDOW).scrollTop();
		const winH = $(WINDOW).height();
		const elH = el.height();

		if (!el.hasClass(CLASS._active)) {
			if (scrollTop + winH > elH) {
				summary.removeClass(CLASS._sticky);
			} else {
				summary.addClass(CLASS._sticky);
			}
		}
	}

	function addPriceToSummary() {
		const priceTotal =
			parseInt(variantPriceTotal) + parseInt(colorPriceTotal) + parseInt(fabricPriceTotal);
		summaryPriceText.html(' ' + APP.numberWithCommas(priceTotal));
		summaryTotalPrice.html(' ' + APP.numberWithCommas(priceTotal));
	}

	function renderVariantItem(data, num) {
		const {
			id,
			title,
			description,
			acf: { price, price_text_has_vat },
		} = data;

		if (num === 0) {
			variantPriceTotal = price;
			summaryPriceText.html(' ' + APP.numberWithCommas(price));
			summaryDescription.html(description);
		}

		return `<div class="row__col row__col--lg-6">
			<label class="variant-box__item variant-box__item--variant ${
				num === 0 ? 'is-active' : ''
			}" data-variant-id="${id}" data-variant-price="${price}" data-variant-idx="${num}" data-variant-title="${title}" data-variant-desc="${description}" for="elite-edition-${num}">
				<input id="elite-edition-${num}" type="radio" name="variant" value="${title}" ${
			num === 0 ? 'checked=checked' : ''
		}>
				<div class="variant-box__content">
					<h4 class="title title--4 title--uppercase">${title}</h4>
					<div class="variant-box__desc">${description}</div>
					<div class="variant-box__price"><strong>${price_text_has_vat}</strong></div>
				</div>
			</label>
		</div>`;
	}

	function renderGalleryItem(data, first_image) {
		return `<div class="car-degrees car-degrees--model is-active">
			<div class="slick slick--car-degrees">
				<div class="slick__item"><img class="img img--full lazyload" data-src="${first_image}" alt="" /></div>
				${data
					.map(
						(item) =>
							`<div class="slick__item"><img class="img img--full lazyload" data-src="${item.url}" alt="" /></div>`
					)
					.join('')}
			</div>
			<div class="car-degrees__control">
				<div class="car-degrees__prev">Prev</div>
				<div class="car-degrees__paging"></div>
				<div class="car-degrees__next">Next</div>
			</div>
		</div>`;
	}

	function renderColorItem(data, num) {
		const {
			model_color_name,
			model_image_default,
			configure_2d_colour: { colour_id, colour_image, price, price_text },
		} = data;

		return `<div class="row__col row__col--6 row__col--lg-4">
			<label class="variant-box__item variant-box__item--color ${
				num === 0 ? 'is-active' : ''
			}" for="color-${num}" data-idx="${num}" data-color-id="${colour_id}" data-color-price="${price}" data-color-price-text="${price_text}" data-color-title="${model_color_name}" data-color-thumb="${model_image_default}">
				<input id="color-${num}" type="radio" name="color" value="${model_color_name}" ${
			num === 0 ? 'checked=checked' : ''
		} />
				<div class="variant-box__content">
					<h4 class="title title--6">${model_color_name}</h4>
					<p class="variant-box__price">${price_text}</p>
				</div>
				<div class="variant-box__color"><img class="img img--full" src="${colour_image}" alt="" /></div>
			</label>
		</div>`;
	}

	function renderGalleryColorItem(data, num) {
		const {
			model_image_360,
			model_image_default,
			configure_2d_colour: { colour_id },
		} = data;
		return `<div data-color-id="${colour_id}" class="car-degrees car-degrees--color car-degrees--color-${num} ${
			num === 0 ? 'is-active' : ''
		}">
			<div class="slick slick--car-degrees">
				<div class="slick__item"><img class="img img--full lazyload" data-src="${model_image_default}" alt="" /></div>
				${model_image_360
					.map(
						(item) =>
							`<div class="slick__item"><img class="img img--full lazyload" data-src="${item.url}" alt="" /></div>`
					)
					.join('')}
			</div>
			<div class="car-degrees__control">
				<div class="car-degrees__prev">Prev</div>
				<div class="car-degrees__paging"></div>
				<div class="car-degrees__next">Next</div>
			</div>
		</div>`;
	}

	function renderFabricImageItem(data, num) {
		const { fabric_image } = data;
		return `<div class="fabric__item fabric__item--${num} ${num === 0 ? 'is-active' : ''}">
			<img class="img img--full" src="${fabric_image}" alt="">
		</div>`;
	}

	function renderFabricItem(data, num) {
		const { fabric_id, fabric_image, fabric_name, price, price_text } = data;
		return `<div class="row__col row__col--6 row__col--lg-4">
			<label class="variant-box__item variant-box__item--fabric ${
				num === 0 ? 'is-active' : ''
			}" for="fabric-${num}" data-fabric-id="${fabric_id}" data-fabric-price="${price}" data-fabric-price-text="${price_text}" data-fabric-title="${fabric_name}" data-fabric-idx="${num}">
				<input id="fabric-${num}" type="radio" name="fabric" value="Black" ${
			num === 0 ? 'checked="checked"' : ''
		}>
				<div class="variant-box__content">
					<h4 class="title title--6">${fabric_name}</h4>
					<p class="variant-box__price">+${price_text}</p>
				</div>
				<div class="variant-box__color"><img class="img img--full" src="${fabric_image}" alt=""></div>
			</label>
		</div>`;
	}

	function renderVariantToDom(data) {
		let items = data.map((item, index) => renderVariantItem(item, index)).join('');
		$('.variant-list').html(items);

		const variantBoxItem = $('.variant-box__item--variant');

		variantBoxItem.on('click', function () {
			const obj = $(this);
			const objVariantId = obj.data('variant-id');
			const objVariantName = obj.data('variant-title');
			const objVariantPrice = obj.data('variant-price');
			const desc = obj.data('variant-desc');
			variantPriceTotal = objVariantPrice;
			variantId = objVariantId;

			if (obj.hasClass(CLASS._active)) return;

			variantBoxItem.removeClass(CLASS._active);
			obj.addClass(CLASS._active);
			summaryVariant.html(' ' + objVariantName);
			summaryTitle.html(modelName + ' ' + objVariantName);
			$('.configurator-result__title').html(modelName);
			summaryDescription.html(desc);
			$('[name="variant_id"]').val(variantId);
			$('[name="variant_name"]').val(objVariantName);

			if ($('.choose__item--model').hasClass(CLASS._active)) {
				addPriceToSummary();
			}

			colorIdParam = getParameterByName('colour_id');
			fabricIdParam = getParameterByName('fabric_id');

			if (!colorIdParam && !fabricIdParam) {
				addParams(modelId, variantId, '', '', '');
				shortLinkVariant();
				if (variantId != variantIdParam) {
					variantIdParam = variantId;
					// console.log('addParams', modelId, variantId, variantIdParam);
					handleInitialLoad();
				}
			}
		});

		if (variantIdParam) {
			// console.log(variantIdParam);
			$('.variant-box__item--variant').removeClass(CLASS._active);
			$(`.variant-box__item--variant[data-variant-id=${variantIdParam}]`).trigger('click');
			$(`.variant-box__item--variant[data-variant-id=${variantIdParam}]`).addClass(CLASS._active);
		} else {
			const variantActive = $('.variant-box__item.is-active');
			const variantActiveId = variantActive.data('variant-id');
			variantId = variantActiveId;
			addParams(modelId, variantId, '', '', '');

			shortLinkVariant();
		}
	}

	function renderColorToDom(data) {
		let items = data.map((item, index) => renderColorItem(item, index)).join('');
		$('.color-list').html(items);
		const variantBoxItem = $('.variant-box__item--color');

		variantBoxItem.on('click', function () {
			const obj = $(this);
			const idx = obj.data('idx');
			const objColorId = obj.data('color-id');
			const objColorTitle = obj.data('color-title');
			const objColorPrice = obj.data('color-price');
			const objColorPriceText = obj.data('color-price-text');
			const objColorThumb = obj.data('color-thumb');
			const objColorPriceIncluded = collapseColorPrice.data('price-included');
			colorId = objColorId;
			colorPriceTotal = objColorPrice;
			colorText = objColorTitle;
			colorPriceText = objColorPriceText;

			if (obj.hasClass(CLASS._active)) return;

			variantBoxItem.removeClass(CLASS._active);
			obj.addClass(CLASS._active);
			$('.car-degrees--color').removeClass(CLASS._active);
			$(`.car-degrees--color-${idx}`).addClass(CLASS._active);
			collapseColorText.html(colorText);
			collapseColorPrice.html(colorPriceText);
			summaryThumb.html(`<img class="img" src="${objColorThumb}" alt="">`);
			configuratorResultThumb.html(`<img class="img img--full" src="${objColorThumb}" alt="">`);

			if ($('.choose__item--accessory').hasClass(CLASS._active)) {
				addPriceToSummary();
			}

			if (objColorPrice === 0) {
				collapseColorPrice.html(objColorPriceIncluded);
			} else {
				collapseColorPrice.html(objColorPriceText);
			}

			if (stepConfigurator === 'colorSelector') {
				addParams(modelId, variantId, colorId, '', 'colorSelector');
				shortLinkColor();
			}
		});

		if (colorIdParam) {
			$('.variant-box__item--color').removeClass(CLASS._active);
			$(`.variant-box__item--color[data-color-id=${colorIdParam}]`).trigger('click');
			$(`.variant-box__item--color[data-color-id=${colorIdParam}]`).addClass(CLASS._active);
		} else {
			variantBoxItem.eq(0).trigger('click');
		}

		if (stepConfigurator === 'colorSelector') {
			setTimeout(() => {
				btnColor.trigger('click');
			}, 500);
		}
	}

	function renderFabricToDom(data) {
		let items = data.map((item, index) => renderFabricItem(item, index)).join('');
		$('.fabric-list').html(items);
		const variantBoxItem = $('.variant-box__item--fabric');

		variantBoxItem.on('click', function () {
			const obj = $(this);
			const idx = obj.data('fabric-idx');
			const objFabricId = obj.data('fabric-id');
			const objFabricName = obj.data('fabric-title');
			const objFabricPrice = obj.data('fabric-price');
			const objFabricPriceText = obj.data('fabric-price-text');
			const objFabricPriceIncluded = collapseFabricPrice.data('price-included');
			fabricPriceTotal = objFabricPrice;
			fabricId = objFabricId;

			if (obj.hasClass(CLASS._active)) return;

			variantBoxItem.removeClass(CLASS._active);
			obj.addClass(CLASS._active);
			$('.fabric__item').removeClass(CLASS._active);
			$(`.fabric__item--${idx}`).addClass(CLASS._active);
			collapseFabricText.html(objFabricName);

			if (objFabricPrice === 0) {
				collapseFabricPrice.html(objFabricPriceIncluded);
			} else {
				collapseFabricPrice.html(objFabricPriceText);
			}

			if ($('.choose__item--fabric').hasClass(CLASS._active)) {
				addPriceToSummary();
			}

			if (stepConfigurator === 'fabricSelector') {
				addParams(modelId, variantId, colorId, fabricId, 'fabricSelector');
				shortLinkFabric();
			}
		});

		if (fabricIdParam) {
			$('.variant-box__item--fabric').removeClass(CLASS._active);
			$(`.variant-box__item--fabric[data-fabric-id=${fabricIdParam}]`).trigger('click');
			$(`.variant-box__item--fabric[data-fabric-id=${fabricIdParam}]`).addClass(CLASS._active);
		} else {
			variantBoxItem.eq(0).trigger('click');
		}

		if (stepConfigurator === 'fabricSelector') {
			setTimeout(() => {
				btnFabric.trigger('click');
			}, 500);
		}
	}

	function renderGallaryToDom(data) {
		let items = renderGalleryItem(data[0].model_image_360, data[0].model_image_default);
		$('.car-degrees-wrap--model').html(items);
		APP.carDegrees('.car-degrees--model');
	}

	function renderGallaryColorToDom(data) {
		// console.log(data);
		let items = data.map((item, index) => renderGalleryColorItem(item, index)).join('');
		$('.car-degrees-wrap--color').html(items);
		if (stepConfigurator === 'colorSelector') {
			$('.car-degrees--color').removeClass(CLASS._active);
			$(`.car-degrees--color[data-color-id=${colorIdParam}]`).addClass(CLASS._active);
		}
	}

	function renderFabricImageToDom(data) {
		let items = data.map((item, index) => renderFabricImageItem(item, index)).join('');
		$('.fabric').html(items);
	}

	async function getData(url) {
		loading.show();

		const response = await fetch(url, {
			headers: {
				'Content-Type': 'application/json',
				lang: _lang,
			},
		});

		const res = await response.json();

		if (!res.errorCode) {
			setTimeout(() => {
				loading.hide();
				setTimeout(() => {
					el.addClass(CLASS._show);
				}, 800);
			}, 1000);
		}

		return res;
	}

	async function handleInitialLoad() {
		if (modelId) {
			const variantData = await getData(`${api}/wp/variant?model_id=${modelId}`);
			const modelData = await getData(`${api}/wp/model?model_id=${modelId}`);
			// console.log(variantId, variantIdParam);
			const configureData = await getData(
				`${api}/wp/configure?model_id=${modelId}&variant_id=${variantId || variantIdParam}`
			);

			modelName = modelData.item.name;
			if (!variantIdParam) {
				sendEvent('model');
			}

			if (variantData.items.length > 0) {
				if (stepConfigurator === 'summarySelector') {
					setTimeout(() => {
						btnSummary.trigger('click');
					}, 500);
				}

				const variantActive =
					variantData.items.find((item) => item.id === variantIdParam) || variantData.items[0];

				renderVariantToDom(variantData.items);
				renderGallaryToDom(configureData.item.acf.model_color_items);
				renderColorToDom(configureData.item.acf.model_color_items);
				renderGallaryColorToDom(configureData.item.acf.model_color_items);
				renderFabricToDom(configureData.item.acf.configure_2d_fabric.fabric_items);
				renderFabricImageToDom(configureData.item.acf.configure_2d_fabric.fabric_items);
				summaryModel.html(modelName);
				summaryVariant.html(variantActive.title);
				$('[name="model_id"]').val(modelId);
				$('[name="model_name"]').val(modelName);
			} else {
				window.location.href = backLink;
			}
		} else {
			window.location.href = backLink;
		}
	}

	init();
};
