APP.tradein = function () {
	const el = $('.tradein');
	if (el.length === 0) return;
	const form = el.find('form');
	const formProvinces = el.find('[name="province"], [name="registration_area"]');
	const slickCarChoose = el.find('.slick--car-choose');
	const carChoose = el.find('.car-choose');
	const carChooseImgs = carChoose.find('.img');
	const carChooseTitle = form.find('.car-choose__title').eq(0);
	const carChooseTitle2 = form.find('.car-choose__title').eq(1);
	const formCarChoose = el.find('.car-choose .form__group');
	const formTradeInType = el.find('[name="tradein_type"]');
	const modelId = el.find('[name="model_id"]');
	const modelName = el.find('[name="model_name"]');
	const dealer_code = el.find('[name="dealer_code"]').val();
	const manufacturer = el.find('[name="manufacturer"]');
	const manufacturer2 = el.find('[name="manufacturer2"]');
	const used_model = el.find('[name="used_model"]');
	const used_model2 = el.find('[name="used_model2"]');
	const used_model3 = el.find('[name="used_model3"]');
	const item3 = el.find('.fleetsale__item').eq(2);

	const thankYou = $('.thank-you');
	const thankYouThumb = thankYou.find('.thank-you__thumb img');

	let dataSubmit = {
		utm_source: getCookie('utm_source') ? getCookie('utm_source') : '',
		utm_content: getCookie('utm_content') ? getCookie('utm_content') : '',
		utm_medium: getCookie('utm_medium') ? getCookie('utm_medium') : '',
		utm_campaign: getCookie('utm_campaign') ? getCookie('utm_campaign') : '',
		mmv_campaign: getCookie('mmv_campaign') ? getCookie('mmv_campaign') : '',
		form_type: getParameterByName('form_type') ? getParameterByName('form_type') : 'tradein',
		location: window.location.href,
		env: getParameterByName('env') ? getParameterByName('env') : 'dev',
	};
	let imageLoaded = 0;

	function getProvinces(selectEl, optText, inputPlaceholder) {
		let options = `<option value="">${optText}</option>`;
		APP.getData(`${api}/general/province`, function (data) {
			data.items
				.map((province) => {
					options += `<option data-province-id="${province.province_id}" value="${province.name}">${province.name}</option>`;
				})
				.join('');
			APP.renderSelect(selectEl, options, inputPlaceholder);
		});
	}

	function renderUsedModel(selectEl, optText, inputPlaceholder) {
		APP.getData(`${api}/wp/model?is_tradein=1`, function (data) {
			let options = `<option value="">${optText}</option>`;
			data?.items?.map(function (d) {
				options += `<option data-model-id="${d.slug}" value="${d.name}">${d.name}</option>`;
			});
			options += `<option value="Other">Khác</option>`;
			APP.renderSelect(selectEl, options, inputPlaceholder);
			used_model2.on('change', function () {
				const obj = $(this);
				const value = obj.val();
				if (value === 'Other') {
					used_model3.parent().attr('data-required', 'true').parents('.row').eq(0).show();
				} else {
					used_model3.parent().removeAttr('data-required').parents('.row').eq(0).hide();
				}
			});
		});
	}

	function renderManufacturer(selectEl, optText, inputPlaceholder) {
		APP.getData(`${api}/general/tradeIn/manufacture`, function (data) {
			let options = `<option value="">${optText}</option>`;
			data?.items?.map(function (d) {
				options += `<option value="${d.name}">${d.name}</option>`;
			});
			options += `<option value="Other">Khác</option>`;
			APP.renderSelect(selectEl, options, inputPlaceholder);
		});
	}

	function onClickSlickItem(e) {
		e.stopPropagation();
		const index = $(this).data('slick-index');
		slickCarChoose.slick('slickGoTo', index);
		handleCarChoose();
	}

	function handleCarChoose() {
		const slickSlide = slickCarChoose.find('.slick-slide');
		const formGroup = slickCarChoose.next();
		const formError = formGroup.find('.form__error');
		const slickActiveCenter = slickCarChoose.find('.slick-active.slick-center');
		const title = slickActiveCenter.find('.title');
		const parent = title.parents('.car-choose__main');
		const imgSrc = parent.find('.img').attr('src');
		const modelIdValue = parseInt(title.data('model-id'));
		const modelNameValue = title.text();
		slickSlide.removeClass(CLASS._selected);
		slickActiveCenter.addClass(CLASS._selected);
		formGroup.removeClass(CLASS._error).removeClass(CLASS._focus);
		formError.text('');
		modelId.val(modelIdValue);
		modelName.val(modelNameValue);
		thankYouThumb.attr('src', imgSrc);
		console.log(modelIdValue, modelNameValue);
	}

	function initManufacturer(val = '', disabled = false) {
		manufacturer.select2('destroy');
		manufacturer.select2({
			with: 'resolve',
			disabled: disabled,
			searchInputPlaceholder: manufacturer.data('input-placeholder'),
			templateSelection: function (data, container) {
				console.log(data);
				if (data.id !== '') {
					$(container).addClass('select2-selection__rendered--value');
				} else {
					$(container).removeClass('select2-selection__rendered--value');
				}
				return data.text;
			},
		});
		manufacturer.val(val).trigger('change');
	}

	function init() {
		if (document.getElementById('desire_sell')) {
			priceMask = IMask(document.getElementById('desire_sell'), {
				mask: Number,
				min: 1,
				max: 9999999999999,
				thousandsSeparator: ',',
			});
		}

		if (document.getElementById('odometer')) {
			priceMask = IMask(document.getElementById('odometer'), {
				mask: '000000',
				min: 1,
				max: 9999999999999,
				thousandsSeparator: ',',
			});
		}

		manufacturer.on('change', function () {
			const obj = $(this);
			const value = obj.val();
			if (value === 'Mitsubishi') {
				used_model2
					.val('')
					.trigger('change')
					.parent()
					.attr('data-required', 'true')
					.parent()
					.show();
				used_model.val('').parent().removeAttr('data-required').parent().hide();
				manufacturer2
					.val('')
					.trigger('change')
					.parent()
					.removeAttr('data-required')
					.parent()
					.hide();
			} else if (value === 'Other') {
				manufacturer2
					.val('')
					.trigger('change')
					.parent()
					.attr('data-required', 'true')
					.parent()
					.show();
				used_model2.val('').trigger('change').parent().removeAttr('data-required').parent().hide();
				used_model
					.val('')
					.parent()
					.attr('data-required', 'true')
					.parent()
					.removeClass('row__col--lg-6')
					.addClass('row__col--lg-12')
					.show();
			} else {
				used_model2.val('').trigger('change').parent().removeAttr('data-required').parent().hide();
				used_model
					.val('')
					.parent()
					.attr('data-required', 'true')
					.parent()
					.removeClass('row__col--lg-12')
					.addClass('row__col--lg-6')
					.show();
				manufacturer2
					.val('')
					.trigger('change')
					.parent()
					.removeAttr('data-required')
					.parent()
					.hide();
			}
		});

		formProvinces.each(function () {
			const obj = $(this);
			getProvinces(obj, obj.data('label-province'), obj.data('input-placeholder'));
		});

		renderUsedModel(
			used_model2,
			used_model2.data('label-model'),
			used_model2.data('input-placeholder')
		);

		renderManufacturer(
			manufacturer,
			manufacturer.data('label-manufacturer'),
			manufacturer.data('input-placeholder')
		);

		slickCarChoose.on('swipe', function () {
			handleCarChoose();
		});

		slickCarChoose.on('afterChange', function () {
			handleCarChoose();
		});

		slickCarChoose.on('click', '.slick-active', onClickSlickItem);

		carChooseImgs.on('load', function () {
			imageLoaded += 1;
			if (imageLoaded === carChooseImgs.length) {
				carChoose.css({
					overflow: 'hidden',
					height: 0,
					padding: 0,
				});
				carChooseTitle.hide();
				carChooseTitle2.hide();
			}
		});

		slickCarChoose.slick({
			infinite: true,
			centerMode: true,
			centerPadding: '180px',
			slidesToShow: 3,
			slidesToScroll: 3,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						arrows: false,
						infinite: true,
						centerMode: true,
						centerPadding: '60px',
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		});

		thankYou.hide();

		formTradeInType.on('change', function () {
			const obj = $(this);
			const value = obj.val();
			initManufacturer();
			if (value === 'trade_in') {
				carChoose.css({
					overflow: 'hidden',
					height: 0,
					padding: 0,
				});
				carChooseTitle2.hide();
				carChooseTitle.show();
				formCarChoose.removeAttr('data-required');
				initManufacturer('Mitsubishi', 'readonly');
			} else {
				carChoose.css({
					overflow: 'visible',
					height: 'auto',
					padding: '4rem 0',
				});
				carChooseTitle2.show();
				carChooseTitle.hide();
				formCarChoose.attr('data-required', 'true');
				initManufacturer();
			}
			used_model3.parents('.row').eq(0).hide();
			item3.removeClass('d-none');
		});

		APP.validate(form, {
			isAjax: true,
			onComplete: function (data) {
				console.log(data);
				// HACK: fix bug validate
				if (!data.manufacturer) {
					data.manufacturer = manufacturer.val();
				}
				dataSubmit = {
					...data,
					...dataSubmit,
					name: data.name,
					email: data.email,
					phone: data.phone,
					province: data.province,
					trade_in: data.tradein_type === 'trade_in' ? '1' : '0',
					sale_old_car: data.tradein_type === 'sale_old_car' ? '1' : '0',
					manufacturer: data.manufacturer === 'Other' ? data.manufacturer2 : data.manufacturer,
					used_model:
						data.tradein_type === 'trade_in'
							? data.used_model2 === 'Other'
								? data.used_model3
								: data.used_model2
							: data.manufacturer === 'Mitsubishi'
							? data.used_model2 === 'Other'
								? data.used_model3
								: data.used_model2
							: data.used_model,
					new_model: modelName.val(),
					year_of_manufacture: data.year_of_manufacture,
					odometer: data.odometer,
					registration_area: data.registration_area,
					dealer_code: dealer_code,
					desire_sell: data.desire_sell,
				};
				// console.log(dataSubmit);
				fetch(`${api}/form/tradeIn`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						lang: _lang,
					},
					body: JSON.stringify(dataSubmit),
				})
					.then((res) => res.json())
					.then((res) => {
						if (res.result === 'success') {
							el.addClass(CLASS._blur);
							gsap.set(thankYou, {
								display: 'block',
								alpha: 0,
							});

							if (data.tradein_type === 'trade_in') {
								thankYouThumb.hide();
								thankYou.find('.title--6').hide();
							} else {
								thankYou.find('.title--6').text(dataSubmit.new_model).show();
								thankYouThumb.show();
							}

							thankYou
								.find('.thank-you__used_model')
								.html(
									`<span>${thankYou.find('.thank-you__used_model').data('text')}<strong>${
										dataSubmit.used_model
									}, ${dataSubmit.year_of_manufacture}</strong></span>`
								);

							thankYou
								.find('.thank-you__username')
								.html(
									`<span>${thankYou.find('.thank-you__username').data('text')}<strong>${
										dataSubmit.name
									}</strong></span>`
								);

							thankYou
								.find('.thank-you__userphone')
								.html(
									`<span>${thankYou.find('.thank-you__userphone').data('text')}<strong>${
										dataSubmit.phone
									}</strong></span>`
								);

							thankYou
								.find('.thank-you__userprovince')
								.html(
									`<span>${thankYou.find('.thank-you__userprovince').data('text')}<strong>${
										dataSubmit.province
									}</strong></span>`
								);

							if (dataSubmit.email) {
								thankYou
									.find('.thank-you__useremail')
									.html(
										`<span>${thankYou.find('.thank-you__useremail').data('text')}<strong>${
											dataSubmit.email
										}</strong></span>`
									);
							}

							gsap.to(thankYou, {
								duration: 0.4,
								alpha: 1,
								onComplete: function () {
									const thankYouPos = thankYou.position().top;
									APP.setScrollTopAnimation(thankYouPos);
								},
							});
						} else {
							console.log(res);
						}
					})
					.catch((err) => {
						loading.hide();
						console.log(err);
					});

				// CDP
				const company = [];
				if (dataSubmit.new_model) company.push(dataSubmit.new_model);
				if (dataSubmit.mmv_campaign) company.push(dataSubmit.mmv_campaign);
				else company.push(data.tradein_type !== 'trade_in' ? 'Trade-in' : 'Bán xe');
				dataLayer.push({
					event: 'pangoTrack',
					eventAction: 'generate_lead',
					label: '',
					user_data: {
						email_address: data.email,
						phone_number: data.phone,
					},
					cdpData: {
						properties: {
							fullname: dataSubmit.name,
							email: dataSubmit.email,
							phone: dataSubmit.phone,
							manufacture: dataSubmit.manufacturer,
							versionVehicle: dataSubmit.used_model,
							yearManufacture: dataSubmit.year_of_manufacture,
							numberODO: dataSubmit.odometer,
							useFor: data.tradein_type === 'trade_in' ? 'resell' : 'buyoldrenew',
							placeOfRegist: dataSubmit.registration_area,
							livingArea: dataSubmit.province,
							desiredSell: parseInt(dataSubmit.desire_sell.replace(/,/g, '')),
							isTradeIn: true,
							listTagsModel: dataSubmit.new_model,
							listTagsCampaign:
								dataSubmit.mmv_campaign || data.tradein_type !== 'trade_in' ? 'Trade-in' : 'Bán xe',
							company: company.join(','),
							dealercode: dataSubmit.dealer_code,
							position: data.tradein_type !== 'trade_in' ? 'Trade-in' : 'Bán xe',
						},
					},
				});
			},
		});
	}

	init();
};
