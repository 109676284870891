APP.carCompare = function () {
	const el = $('.car-compare');

	if (el.length === 0) return;

	const carCompareBody = el.find('.car-compare__body');
	const carCompareFoot = el.find('.car-compare__foot');
	const boxCompare = el.find('.box-compare');
	const boxCompareItemAdd = boxCompare.find('.box-compare__item--add');
	const boxCompareBtn = boxCompare.find('.box-compare__btn');
	const titleBox = carCompareBody.data('title-box');
	const selectModel = carCompareBody.data('select-model');
	const selectVariant = carCompareBody.data('select-variant');
	const apiLang = carCompareBody.data('lang');
	const arrTitleBox = titleBox.split(',');
	const countArrTitleBox = arrTitleBox.length;
	const submit = carCompareFoot.find('.btn');
	let submitHref = submit.attr('href');
	let variantItems = [null, null];
	let param = '';
	let href = '';

	function init() {
		for (let i = 0; i < 2; i++) {
			addBox(i);
		}

		boxCompareBtn.on('click', onClickBoxCompareBtn);
	}

	function onClickBoxCompareBtn() {
		const obj = $(this);
		const boxCompareItem = boxCompare.find('.box-compare__item');
		const countBoxCompareItem = boxCompareItem.length;

		obj.addClass(CLASS._loader);

		addBox(countBoxCompareItem - 1, function () {
			if (countBoxCompareItem - 1 >= countArrTitleBox - 1) {
				boxCompareItemAdd.hide();
			}
			obj.removeClass(CLASS._loader);
		});
	}

	function addBox(idx, callback) {
		disabledSubmit();

		fetch(`https://mitsubishi-motors.com.vn/webapi/v1/wp/model?lang=${apiLang}`)
			.then((res) => res.json())
			.then((models) => {
				options = `<option value="">${selectModel}</option>`;
				models.items
					.map((model) => {
						options += `<option data-model-id="${model.id}" value="${model.id}">${model.name}</option>`;
					})
					.join('');

				const html = `<div class="box-compare__item">
					<div class="box-compare__form">
						<div class="box-compare__body">
							<div class="box-compare__title">
								<h3 class="title title--5">${arrTitleBox[idx]}</h3>
								<select name="model-car" style="width: 100%">
									${options}
								</select>
								<select name="variant-car" style="width: 100%">
									<option value="">${selectVariant}</option>
								</select>
							</div>
						</div>
					</div>
				</div>`;

				$('.box-compare__item--add').before(html);
				$('select').select2({
					minimumResultsForSearch: -1,
					width: 'resolve',
					templateSelection: function (data, container) {
						if (data.id !== '') {
							$(container).addClass('select2-selection__rendered--value');
						} else {
							$(container).removeClass('select2-selection__rendered--value');
						}
						return data.text;
					},
				});

				const modalCar = el.find('select[name="model-car"]');
				const variantCar = el.find('select[name="variant-car"]');

				// modalCar.unbind('change');
				// variantCar.unbind('change');

				modalCar.on('change', onChangeModel);
				variantCar.on('change', onChangeVariant);
				if (callback) callback();
			});
	}

	function onChangeModel() {
		const obj = $(this);
		const selected = obj.find('option:selected');
		const parent = obj.parents('.box-compare__item');
		const parentIndex = parent.index();
		const variantCar = parent.find('select[name="variant-car"]');
		let modelCarId = selected.data('model-id') ? selected.data('model-id') : null;
		let options = `<option value="">${selectVariant}</option>`;

		if (modelCarId !== null) {
			fetch(
				`https://mitsubishi-motors.com.vn/webapi/v1/wp/variant?lang=${apiLang}&model_id=${modelCarId}`
			)
				.then((res) => res.json())
				.then((variants) => {
					variants.items
						.map((variant) => {
							options += `<option data-variant-id="${variant.id}" value="${variant.title}">${variant.title}</option>`;
						})
						.join('');
					variantCar.html(options);
					variantCar.select2('destroy');
					variantCar.select2({
						minimumResultsForSearch: -1,
						width: 'resolve',
						templateSelection: function (data, container) {
							if (data.id !== '') {
								$(container).addClass('select2-selection__rendered--value');
							} else {
								$(container).removeClass('select2-selection__rendered--value');
							}
							return data.text;
						},
					});
				})
				.catch((err) => console.log(err));
		} else {
			variantItems[parentIndex] = null;
			disabledSubmit();
			variantCar.html(options);
			variantCar.select2('destroy');
			variantCar.select2({
				minimumResultsForSearch: -1,
				width: 'resolve',
				templateSelection: function (data, container) {
					if (data.id !== '') {
						$(container).addClass('select2-selection__rendered--value');
					} else {
						$(container).removeClass('select2-selection__rendered--value');
					}
					return data.text;
				},
			});
		}
	}

	function onChangeVariant() {
		const obj = $(this);
		const selected = obj.find('option:selected');
		const parent = obj.parents('.box-compare__item');
		const boxCompareItem = el.find('.box-compare__item');
		const parentIndex = parent.index();
		const variantCarId = selected.data('variant-id') ? selected.data('variant-id') : null;
		const countBoxCompareItem = boxCompareItem.length;

		variantItems[parentIndex] = variantCarId;

		if (!variantItems.includes(null) && variantItems.length === countBoxCompareItem - 1) {
			param = `?variant_ids=${variantItems.toString()}`;
			href = submitHref + param;
			submit.removeClass(CLASS._disabled).attr('href', href);
		} else {
			disabledSubmit();
		}
	}

	function disabledSubmit() {
		param = '';
		href = '';
		submit.addClass(CLASS._disabled).attr('href', submitHref);
	}

	init();
};
