APP.standardHero = function () {
	const standardHero = $('.standard-hero');
	const standardHeroVideo = standardHero.find('video')[0];
	const standardHeroControl = standardHero.find('.standard-hero__control');

	const init = function () {
		autoPlayStandardHero();
		standardHeroControl.on('click', handleStandardHeroControl);
	};

	const handleStandardHeroControl = function () {
		const obj = $(this);
		if (standardHeroVideo) {
			if (obj.hasClass(CLASS._played)) {
				standardHeroVideo.pause();
			} else {
				standardHeroVideo.play();
			}
			obj.toggleClass(CLASS._played);
		}
	};

	const autoPlayStandardHero = function () {
		if (standardHeroVideo) {
			standardHeroVideo.play();
			standardHeroControl.addClass(CLASS._played);
		}
	};

	init();
};
