APP.ctaContact = function () {
	const el = $('.cta-contact');
	const ctaContactBtn = el.find('.cta-contact__btn');
	const ctaContactBody = el.find('.cta-contact__body');
	const ctaContactContent = el.find('.cta-contact__content');
	let ctaContactContentH = ctaContactContent.outerHeight();

	const init = function () {
		detect();
		APP.resize(detect);

		ctaContactBtn.on('click', onClickCtaContact);
	};

	function onClickCtaContact(e) {
		const obj = $(this);
		const parent = obj.parent();
		ctaContactContentH = ctaContactContent.outerHeight();

		if (parent.hasClass(CLASS._show)) {
			parent.removeClass(CLASS._show);
			gsap.to(ctaContactBody, {
				duration: 0.4,
				height: 0,
				ease: 'power1.out',
			});
		} else {
			parent.addClass(CLASS._show);
			gsap.to(ctaContactBody, {
				duration: 0.4,
				height: ctaContactContentH,
				ease: 'power1.out',
			});
		}
	}

	function detect() {
		if (el.hasClass(CLASS._show)) {
			let ctaContactContentH = ctaContactContent.outerHeight();
			gsap.set(ctaContactBody, {
				height: ctaContactContentH,
			});
		}
	}

	init();
};
