APP.countDown = function () {
	const init = function () {
		if (!document.getElementById('countdown')) return;
		const second = 1000,
			minute = second * 60,
			hour = minute * 60,
			day = hour * 24;

		//I'm adding this section so I don't have to keep updating this pen every year :-)
		//remove this if you don't need it
		let today = new Date(),
			dd = String(today.getDate()).padStart(2, '0'),
			mm = String(today.getMonth() + 1).padStart(2, '0'),
			yyyy = today.getFullYear(),
			nextYear = yyyy + 1,
			dayMonth = '10/19/',
			birthday = dayMonth + yyyy;

		today = mm + '/' + dd + '/' + yyyy;
		if (today > birthday) {
			birthday = dayMonth + nextYear;
		}
		//end

		const countDown = new Date(birthday).getTime() + 9.5 * 60 * 60 * 1000,
			x = setInterval(function () {
				const now = new Date().getTime(),
					distance = countDown - now;

				(document.getElementById('days').innerText = String(Math.floor(distance / day)).padStart(
					2,
					'0'
				)),
					(document.getElementById('hours').innerText = String(
						Math.floor((distance % day) / hour)
					).padStart(2, '0')),
					(document.getElementById('minutes').innerText = String(
						Math.floor((distance % hour) / minute)
					).padStart(2, '0')),
					(document.getElementById('seconds').innerText = String(
						Math.floor((distance % minute) / second)
					).padStart(2, '0'));

				//do something later when date is reached
				if (distance < 0) {
					// document.getElementById('headline').innerText = "It's my birthday!";
					document.getElementById('countdown').style.display = 'none';
					document.getElementById('content').style.display = 'block';
					clearInterval(x);
				}
				//seconds
			}, 0);
	};
	init();
};
