APP.init = function () {
	APP.device();

	// SmoothScroll({
	// 	animationTime: 400,
	// 	accelerationDelta: 20,
	// 	accelerationMax: 1,
	// });

	$('[data-modal]').on('click', function (e) {
		e.preventDefault();
		const obj = $(this);
		const modalShow = obj.data('modal');
		if (modalShow) APP.modal(modalShow);
	});

	$('select').select2({
		minimumResultsForSearch: -1,
		width: 'resolve',
		templateSelection: function (data, container) {
			if (data.id !== '') {
				$(container).addClass('select2-selection__rendered--value');
			} else {
				$(container).removeClass('select2-selection__rendered--value');
			}
			return data.text;
		},
	});

	$('.collapse').each(function () {
		APP.collapse(this);
	});

	APP.taber('.taber');

	APP.agreeCookie();
	APP.header();
	APP.footer();
	APP.form();
	APP.waypoint();
	APP.navigation();
	APP.bannerHero();
	APP.carSelector();
	APP.standardHero();
	APP.buildYourOwn();
	APP.specification();
	APP.sideNavigation();
	APP.ctaContact();
	APP.carCompare();
	APP.tableTabbed();
	APP.aboutUs();
	APP.dealerNetwork();
	APP.testDrive();
	APP.registerDealer();
	APP.recallChecking();
	APP.bookAService();
	APP.costEstimate();
	APP.contactUs();
	APP.serviceInterval();
	APP.carouselImage();
	APP.carouselTabbed();
	APP.heritage();
	APP.carCalculator();
	APP.fleetsale();
	APP.xpander();
	APP.career();
	APP.configurator();
	APP.configurator3d();
	APP.registerOne();
	APP.registerTwo();
	APP.qrCode();
	APP.countDown();
	APP.mbaTab();
	APP.conceptCarAtivation();
	APP.mapWorkHour();
	APP.testDriveCheckin();
	// APP.banner_product(
	// 	'http://google.com',
	// 	'https://images.unsplash.com/photo-1656308918259-8ce8f38f0442?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2781&q=80',
	// 	'https://images.unsplash.com/photo-1656143149291-8cef099031a8?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80'
	// );
	// APP.showPopup1();
	//APP.showPopup2();
	// APP.showPopup3();
	APP.tradein();
};
