APP.sideNavigation = function () {
	const header = $('.header');
	const sideNavigation = $('.side-navigation');
	const sideNavigationItem = sideNavigation.find('.side-navigation__item');
	const hash = location.hash;

	const init = function () {
		sideNavigationItem.on('click', onClickSlideNavigationItem);
		DOCUMENT.on('scroll', onScroll);

		if (hash) {
			setTimeout(function () {
				const posY = $(hash).offset().top;
				APP.setScrollTopAnimation(posY);
			}, 300);
		}
	};

	const onClickSlideNavigationItem = function (evt) {
		evt.preventDefault();
		const obj = $(this);
		const secID = obj.data('id');
		const headerH = header.outerHeight();
		const scrollPos = DOCUMENT.scrollTop();
		const objTop = $(secID).position().top;
		const posY = objTop <= scrollPos ? objTop - headerH : objTop;

		if (obj.hasClass(CLASS._active)) return;

		DOCUMENT.off('scroll');
		sideNavigationItem.removeClass(CLASS._active);
		obj.addClass(CLASS._active);
		APP.setScrollTopAnimation(posY, function () {
			DOCUMENT.on('scroll', onScroll);
		});
	};

	const onScroll = function () {
		const scrollPos = DOCUMENT.scrollTop();

		sideNavigationItem.each(function () {
			const obj = $(this);
			const secID = obj.data('id');
			const objTop = $(secID).position().top;

			if (objTop <= scrollPos && objTop + $(secID).height() > scrollPos) {
				sideNavigationItem.removeClass(CLASS._active);
				obj.addClass(CLASS._active);
			} else {
				obj.removeClass(CLASS._active);
			}
		});
	};

	init();
};
