APP.aboutUs = function () {
	const aboutUsSlider = $('.about-us-slider');
	const slickAboutUs = aboutUsSlider.find('.slick--about-us-slider');
	const slickAboutUsPaging = aboutUsSlider.find('.about-us-slider__paging');

	const init = function () {
		slickAboutUs.on('init reInit afterChange', function (event, slick, currentSlide) {
			let i = (currentSlide ? currentSlide : 0) + 1;
			let slideCount = slick.slideCount;
			let number = i > 9 ? i : '0' + i;
			let total = slideCount > 9 ? slideCount : '0' + slideCount;
			slickAboutUsPaging.html(number + '<span>/</span>' + total);
		});

		slickAboutUs.slick({
			infinite: false,
			arrows: true,
			slidesToShow: 1,
			slidesToScroll: 1,
		});
	};

	init();
};
