APP.heritage = function () {
	const el = $('.history--expand');
	const historyWrap = el.find('.history__wrap');
	const yearsMenu = el.find('.years-menu');
	const yearsMenuItem = yearsMenu.find('.years-menu__item');
	const yearsMenuLink = yearsMenu.find('.years-menu__text');
	let lastScroll = 0;

	function init() {
		if (el.length === 0) return;

		yearsMenuLink.on('click', handleYearsMenuLink);
		$(window).scroll(reOrder);
		$(window).resize(reOrder);
	}

	function reOrder() {
		var scroll = $(window).scrollTop(),
			topContent = historyWrap.offset().top,
			sectionHeight = historyWrap.height(),
			rightHeight = yearsMenu.height(),
			bottomContent = topContent + sectionHeight - rightHeight;

		if (scroll > 0 && lastScroll <= scroll) {
			yearsMenu.removeClass('scroll-up');
		} else {
			yearsMenu.addClass('scroll-up');
		}

		lastScroll = scroll;

		if (scroll > topContent && scroll < bottomContent) {
			yearsMenu.removeClass('posAbs').addClass('posFix');
		} else if (scroll > bottomContent) {
			yearsMenu.removeClass('posFix').addClass('posAbs');
		} else if (scroll < topContent) {
			yearsMenu.removeClass('posFix');
		}
	}

	function handleYearsMenuLink(e) {
		e.preventDefault();
		const obj = $(this);
		const parent = obj.parent();
		const id = obj.attr('href');
		const posY = $(id).offset().top;

		if (parent.hasClass(CLASS._active)) return;

		yearsMenuItem.removeClass(CLASS._active);
		parent.addClass(CLASS._active);
		APP.setScrollTopAnimation(posY);
	}

	init();
};
