APP.serviceInterval = function () {
	const el = $('.service-interval');
	const selectCarModel = el.find('[name="car-model"]');
	const selectSubVersion = el.find('[name="sub-version"]');
	const selectSubVersionOption = selectSubVersion.data('option-text');

	function init() {
		selectCarModel.on('change', handleChangeCarModel);
		APP.validate('.form--service-interval');
	}

	function handleChangeCarModel() {
		const obj = $(this);
		const modelId = obj.val();
		APP.getSubVersion(selectSubVersion, selectSubVersionOption, '', modelId);
	}

	init();
};
