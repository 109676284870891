APP.registerDealer = function () {
	const el = $('.register-dealer');
	const loading = el.find('.loading');

	const resultDealer = $('.result-dealer');

	const dataSubmit = {
		utm_source: getCookie('utm_source') ? getCookie('utm_source') : '',
		utm_content: getCookie('utm_content') ? getCookie('utm_content') : '',
		utm_medium: getCookie('utm_medium') ? getCookie('utm_medium') : '',
		utm_campaign: getCookie('utm_campaign') ? getCookie('utm_campaign') : '',
		mmv_campaign: getCookie('mmv_campaign') ? getCookie('mmv_campaign') : '',
	};

	function init() {
		if (el.length === 0) return;

		APP.validate('.form--register-dealer', {
			isAjax: true,
			onComplete: function (res) {
				const data = {
					...dataSubmit,
					...res,
				};

				loading.show();
				fetch(`${api}/form/dealership`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						lang: _lang,
					},
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((data) => {
						loading.hide();

						if (data.result === 'success') {
							el.addClass(CLASS._blur);
							gsap.set(resultDealer, {
								display: 'block',
								alpha: 0,
							});

							gsap.to(resultDealer, {
								duration: 0.4,
								alpha: 1,
								onComplete: function () {
									const resultDealerPos = resultDealer.position().top;
									APP.setScrollTopAnimation(resultDealerPos);
								},
							});
						} else {
							console.log(err);
						}
					});
			},
		});
	}

	init();
};
