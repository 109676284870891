APP.registerTwo = function () {
	const el = $('.register-two');
	const thankYou = $('.thank-you');
	const loading = el.find('.loading');
	const slickCarChoose = el.find('.slick--car-choose');
	const radio = $('.radio input');
	const modelId = el.find('[name="model_id"]');
	const modelName = el.find('[name="model_name"]');
	const selectVariant = el.find('[name="variant_id"]');
	const selectVariantOption = selectVariant.data('label-text');
	const selectVariantInputPlaceholder = selectVariant.data('input-placeholder');
	const company = el.find('[name="o2o-company"]');
	const tagsModel = el.find('[name="tags_model"]');
	const selectDealer = el.find('[name="dealer_id"]');

	const dataSubmit = {
		utm_source: getCookie('utm_source') ? getCookie('utm_source') : '',
		utm_content: getCookie('utm_content') ? getCookie('utm_content') : '',
		utm_medium: getCookie('utm_medium') ? getCookie('utm_medium') : '',
		utm_campaign: getCookie('utm_campaign') ? getCookie('utm_campaign') : '',
		mmv_campaign: getCookie('mmv_campaign') ? getCookie('mmv_campaign') : '',
		form_type: getParameterByName('form_type') ? getParameterByName('form_type') : 'test-drive',
		location: window.location.href,
		env: getParameterByName('env') ? getParameterByName('env') : 'dev',
	};

	function init() {
		if (el.length === 0) return;

		radio.on('click', function (evt) {
			const obj = $(this);
			const id = obj.data('time-id');
			$('[name="dealer_id"]').val('').change();
			$('[name="dealer_id"] option:not(:first-child)').attr('disabled', true);
			$('[name="dealer_id"] option[data-time-id=' + id + ']').attr('disabled', false);
		});

		slickCarChoose.slick({
			infinite: true,
			centerMode: true,
			centerPadding: '180px',
			slidesToShow: 3,
			slidesToScroll: 3,
			responsive: [
				{
					breakpoint: 992,
					settings: {
						arrows: false,
						infinite: true,
						centerMode: true,
						centerPadding: '60px',
						slidesToShow: 1,
						slidesToScroll: 1,
					},
				},
			],
		});

		slickCarChoose.on('swipe', function () {
			handleCarChoose();
		});

		slickCarChoose.on('click', '.slick-active', onClickSlickItem);

		APP.validate('.form--register-two', {
			isAjax: true,
			onComplete: function (res) {
				const dealer_name =
					selectDealer.find('option:selected').text() || $('[name="model_id"]').data('model-name');
				const variant_name = selectVariant.find('option:selected').text();
				const event_session = $('input[name="event_slot"]:checked').data('time-session');
				const data = {
					...res,
					...dataSubmit,
					dealer_name,
					variant_name,
					event_session,
				};
				const dataInput = data;

				loading.show();
				fetch(`${api}/form/testDrive`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						lang: _lang,
					},
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((data) => {
						loading.hide();

						if (data.result === 'success') {
							/// BEGIN Send data to CDP & CRM
							// dataLayer = dataLayer || [];
							let tags = dataInput.tags_model;
							if (dataInput.mmv_campaign) {
								tags += ',' + dataInput.mmv_campaign;
							}

							dataLayer.push({
								event: 'pangoTrack',
								eventAction: 'generate_lead',
								label: '',
								user_data: {
									email_address: dataInput.email,
									phone_number: dataInput.phone,
								},
								cdpData: {
									properties: {
										fullname: dataInput.name,
										email: dataInput.email,
										phone: dataInput.phone,
										eventName: dataInput.event_name,
										eventSlot: dataInput.event_slot,
										eventSession: dataInput.event_session,
										listTagsModel: dataInput.tags_model,
										listTagsCampaign: dataInput.mmv_campaign,
										company: tags,
										address: dataInput.dealer_name,
										note: dataInput.note,
									},
								},
							});
							/// End

							el.addClass(CLASS._blur);
							gsap.set(thankYou, {
								display: 'block',
								alpha: 0,
							});

							gsap.to(thankYou, {
								duration: 0.4,
								alpha: 1,
								onComplete: function () {
									const thankYouPos = thankYou.position().top;
									APP.setScrollTopAnimation(thankYouPos);
								},
							});
						} else {
							$('.form__error--response').html(data.errorMessage);
						}
					})
					.catch((err) => {
						loading.hide();
						console.log(err);
					});
			},
		});
	}

	function onClickSlickItem(e) {
		e.stopPropagation();
		const index = $(this).data('slick-index');
		slickCarChoose.slick('slickGoTo', index);
		handleCarChoose();
	}

	function handleCarChoose() {
		const slickSlide = slickCarChoose.find('.slick-slide');
		const formGroup = slickCarChoose.next();
		const formError = formGroup.find('.form__error');
		const slickActiveCenter = slickCarChoose.find('.slick-active.slick-center');
		const title = slickActiveCenter.find('.title');
		const modelIdValue = parseInt(title.data('model-id'));
		const modelNameValue = title.text();
		const modelTagValue = title.data('model-tag');
		slickSlide.removeClass(CLASS._selected);
		slickActiveCenter.addClass(CLASS._selected);
		formGroup.removeClass(CLASS._error).removeClass(CLASS._focus);
		formError.text('');
		modelId.val(modelIdValue);
		modelName.val(modelNameValue);
		tagsModel.val(modelTagValue);
		company.val(`${modelNameValue}, ${dataSubmit.mmv_campaign}`);
		APP.getVariants(
			selectVariant,
			selectVariantOption,
			selectVariantInputPlaceholder,
			modelIdValue
		);
	}

	init();
};
