APP.testDriveCheckin = function () {
	const el = $('.test-drive-checkin');
	if (el.length === 0) return false;

	const holder = el.find('.test-drive-checkin__holder');
	const holderWidth = holder.innerWidth();
	const inner = el.find('.test-drive-checkin__inner');
	const loading = el.find('.loading');

	const form1 = el.find('.info-step1');
	const phone = form1.find('[name="phone"]');

	const form1otp = el.find('.info-step1-otp');
	const otpCode = form1otp.find('[name="otp_code"]');
	const changePhone = form1otp.find('.btn--change-phone');

	const form2 = el.find('.info-step2');
	const nvbhSelect = form2.find('[name="nvbh"]');
	const form2Action = form2.find('[name="is_regist_test_drive"]');
	const form2Accept = form2.find('.form__accept');
	const form2AcceptGroup = form2Accept.find('.form__group');
	const form2Button = form2.find('.btn');
	const form2ButtonText = form2Button.find('.btn__text');
	const form2ButtonNext = form2Button.data('text-next');
	const form2ButtonRegister = form2Button.data('text-register');

	const customerName = form2.find('.test-drive-checkin__customer');
	const customerPhoneLabel = form2.find('.test-drive-checkin__phone--label');
	const customerPhoneValue = form2.find('.test-drive-checkin__phone--value');
	const customerEmailLabel = form2.find('.test-drive-checkin__email--label');
	const customerEmailValue = form2.find('.test-drive-checkin__email--value');
	const customerAgencyLabel = form2.find('.test-drive-checkin__agency--value');
	const customerAgencyValue = form2.find('.test-drive-checkin__agency--value');

	const form2Update = el.find('.info-step2-update');
	const form2UpdateAction = form2Update.find('[name="is_regist_test_drive_update"]');
	const form2UpdateAccept = form2Update.find('.form__accept');
	const form2UpdateAcceptGroup = form2UpdateAccept.find('.form__group');
	const form2UpdateButton = form2Update.find('.btn');
	const form2UpdateButtonText = form2UpdateButton.find('.btn__text');
	const form2UpdateButtonNext = form2UpdateButton.data('text-next');
	const form2UpdateButtonRegister = form2UpdateButton.data('text-register');

	const form3 = el.find('.info-step3');
	const licenceFile1 = form3.find('[name="file_GPLX1"]');

	const thankYou = $('.thank-you-1');
	const thankYou2 = $('.thank-you-2');

	const backButtons = el.find('.btn-back');
	let currentFormPos = 0;
	let dataSubmit = {};

	let customerId = '';
	let userEventData = [];
	let userEventSelected = {};
	let dataSubmitLast = {
		utm_source: getCookie('utm_source') ? getCookie('utm_source') : '',
		utm_content: getCookie('utm_content') ? getCookie('utm_content') : '',
		utm_medium: getCookie('utm_medium') ? getCookie('utm_medium') : '',
		utm_campaign: getCookie('utm_campaign') ? getCookie('utm_campaign') : '',
		mmv_campaign: getCookie('mmv_campaign') ? getCookie('mmv_campaign') : '',
	};

	let croppers = [];

	function animForm(isBack) {
		currentFormPos = isBack ? currentFormPos + holderWidth : currentFormPos - holderWidth;
		APP.setScrollTopAnimation(0);
		TweenMax.to(inner, 0.4, {
			x: currentFormPos,
			delay: 0.15,
			ease: Power1.easeOut,
		});
	}

	function animForm1(isBack) {
		currentFormPos = isBack ? currentFormPos + holderWidth * 2 : currentFormPos - holderWidth * 2;
		APP.setScrollTopAnimation(0);
		TweenMax.to(inner, 0.4, {
			x: currentFormPos,
			ease: Power1.easeOut,
		});
	}

	function animForm2(isBack) {
		currentFormPos = isBack ? currentFormPos + holderWidth * 3 : currentFormPos - holderWidth * 3;
		APP.setScrollTopAnimation(0);
		TweenMax.to(inner, 0.4, {
			x: currentFormPos,
			ease: Power1.easeOut,
		});
	}

	function init() {
		phone.focus();
		// Step 1
		APP.validate(form1, {
			isAjax: true,
			onComplete: function (data) {
				dataSubmit = { ...dataSubmit, ...data };
				loading.show();

				fetch(`${api}/dealer/crm/customer`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Cookie: 'PHPSESSID=bn1pik82vpnsp9lqdkbjo44amk',
						lang: _lang,
					},
					body: JSON.stringify(data),
				})
					.then((res) => res.json())
					.then((data) => {
						loading.hide();
						if (data?.result === 'fail') {
							form1.find('.form__error--response').html(data.errorMessage);
						} else if (!data?.data?.phone_number_verified) {
							otpCode.val(data?.data?.otp_code);
							animForm();
							setTimeout(() => {
								otpCode.focus();
							}, 500);

							customerId = data?.data?.id;

							dataSubmitLast = {
								...dataSubmitLast,
								phone: dataSubmit?.phone,
								dealer_code: dataSubmit?.dealer_code,
								event_code: dataSubmit?.event_code,
								crm_customer_id: '',
							};

							let dataCheckPhone = {
								phone: data?.data?.phone,
								id: data?.data?.id,
							};

							APP.validate(form1otp, {
								isAjax: true,
								onComplete: function (data) {
									dataCheckPhone = {
										...dataCheckPhone,
										...data,
									};
									loading.show();
									fetch(`${api}/dealer/customer/verify`, {
										method: 'POST',
										headers: {
											'Content-Type': 'application/json',
											Cookie: 'PHPSESSID=bn1pik82vpnsp9lqdkbjo44amk',
											lang: _lang,
										},
										body: JSON.stringify(dataCheckPhone),
									})
										.then((res) => res.json())
										.then((data) => {
											loading.hide();

											if (data?.result === 'fail') {
												form1otp.find('.form__error--response').html(data.errorMessage);
											} else {
												animForm1();
											}
										});
								},
							});
						} else if (!data?.data?.registered_event) {
							customerId = data?.data?.id;

							dataSubmitLast = {
								...dataSubmitLast,
								phone: dataSubmit?.phone,
								dealer_code: dataSubmit?.dealer_code,
								event_code: dataSubmit?.event_code,
								crm_customer_id: '',
							};
							animForm2();
						} else {
							animForm1();
							customerId = data?.data?.id;
							userEventData = data?.data?.items;

							if (userEventData.length > 0) {
								userEventSelected = userEventData[0];
								renderSelectSale(userEventData);
								renderCustomer(userEventSelected);

								dataSubmitLast = {
									...dataSubmitLast,
									name: userEventSelected?.name,
									phone: data?.data?.phone,
									email: userEventSelected?.email_full,
									sales_consultant: userEventSelected?.brokerName,
									dealer_code: dataSubmit?.dealer_code,
									event_code: dataSubmit?.event_code,
									crm_customer_id: '',
								};
							}

							if (!data?.data?.has_broker_name) {
								nvbhSelect.parent().removeAttr('data-required').hide();
								$('.select-nvbh').hide();
								dataSubmitLast.crm_customer_id = userEventSelected.id;
							} else {
								nvbhSelect.parent().attr('data-required');
								$('.select-nvbh').show();
								dataSubmitLast.crm_customer_id = '';
							}
						}
					})
					.catch((error) => {
						loading.hide();
						console.log(error);
					});
			},
		});

		form2Action.on('change', function () {
			const _this = $(this);
			if (_this.is(':checked')) {
				const val = parseInt(_this.val());
				if (val === 0) {
					form2Accept.show();
					form2AcceptGroup.attr('data-required', 'data-required');
					form2ButtonText.text(form2ButtonRegister);
				} else {
					form2Accept.hide();
					form2AcceptGroup
						.removeClass('is-error')
						.removeClass('is-focus')
						.removeAttr('data-required');
					form2AcceptGroup.find('.form__error').html('');
					form2ButtonText.text(form2ButtonNext);
				}
			}
		});

		form2UpdateAction.on('change', function () {
			const _this = $(this);
			if (_this.is(':checked')) {
				const val = parseInt(_this.val());
				if (val === 0) {
					form2UpdateAccept.show();
					form2UpdateAcceptGroup.attr('data-required', 'data-required');
					form2UpdateButtonText.text(form2UpdateButtonRegister);
				} else {
					form2UpdateAccept.hide();
					form2UpdateAcceptGroup
						.removeClass('is-error')
						.removeClass('is-focus')
						.removeAttr('data-required');
					form2UpdateAcceptGroup.find('.form__error').html('');
					form2UpdateButtonText.text(form2UpdateButtonNext);
				}
			}
		});

		changePhone.on('click', function () {
			animForm(true);
			phone.val('');
			setTimeout(() => {
				phone.focus();
			}, 500);
		});

		APP.validate(form2, {
			isAjax: true,
			onComplete: function (data) {
				dataSubmitLast = {
					...dataSubmitLast,
					sales_consultant: data.nvbh,
					is_regist_test_drive: data.is_regist_test_drive,
				};
				if (data.is_regist_test_drive === '0') {
					const formData = new URLSearchParams();
					for (let key in dataSubmitLast) {
						formData.append(key, dataSubmitLast[key]);
					}
					submitData(form2Update, formData);
				} else {
					animForm1();
				}
			},
		});

		APP.validate(form2Update, {
			isAjax: true,
			onComplete: function (data) {
				dataSubmitLast = {
					...dataSubmitLast,
					name: data.name,
					email: data.email,
					sales_consultant: data.nvbh,
					is_regist_test_drive: data.is_regist_test_drive_update,
				};
				if (data.is_regist_test_drive_update === '0') {
					const formData = new URLSearchParams();

					for (let key in dataSubmitLast) {
						formData.append(key, dataSubmitLast[key]);
					}

					submitData(form2Update, formData);
				} else {
					animForm();
				}
			},
		});

		APP.validate(form3, {
			isAjax: true,
			onComplete: function (data) {
				loading.show();

				dataSubmitLast = {
					...dataSubmitLast,
					driving_licence: data.driving_licence,
				};

				const files = croppers.map((item) => {
					return item.getCroppedCanvas().toDataURL('image/jpeg');
				});

				const dataUpload = {
					note: dataSubmitLast.event_code,
					files,
					id_user: customerId,
				};

				fetch(`${api}/file/upload`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						lang: _lang,
					},
					body: JSON.stringify(dataUpload),
				})
					.then((res) => res.json())
					.then((data) => {
						if (data.result === 'success') {
							form1.find('.form__error--response').html('');
							const fileIds = data.files.map((item) => item.id).join(',');
							dataSubmitLast = {
								...dataSubmitLast,
								file_id: fileIds,
							};

							const formData = new URLSearchParams();

							for (let key in dataSubmitLast) {
								formData.append(key, dataSubmitLast[key]);
							}

							if (!dataSubmitLast.file_id) {
								formData.append('file_id', '');
							}

							submitData(form3, formData);
						} else {
							form3.find('.form__error--response').html(data.errorMessage);
						}
					});
			},
		});

		licenceFile1.on('change', async function (e) {
			const preview = licenceFile1.parent().find('.image-preview');
			preview.html('');
			croppers = [];
			[...e.target.files].map((file) => {
				const previewItem = $(`<div class="image-preview-item" style="padding: 1vw 0;"></div>`);
				const image = new Image();
				image.src = URL.createObjectURL(file);
				previewItem.append(image);
				preview.append(previewItem);
				function onLoadImage() {
					const cropper = new Cropper(image, {
						viewMode: 3,
						zoomOnTouch: false,
						zoomable: false,
						zoomOnWheel: false,
						autoCrop: true,
						aspectRatio: 3 / 2,
						responsive: false,
					});
					croppers.push(cropper);
					image.removeEventListener('load', onLoadImage);
				}
				image.addEventListener('load', onLoadImage);
			});
		});

		// Back buttons
		backButtons.on('click', function (e) {
			animForm(true);
			e.preventDefault();
		});

		thankYou.find('.btn-close').on('click', function (e) {
			TweenMax.to(thankYou, 0.4, {
				autoAlpha: 0,
				display: 'none',
				ease: Power1.easeOut,
				onComplete: function () {},
			});
			// remove param ticket_code
			const url = new URL(window.location.href);
			url.searchParams.delete('ticket_code');
			window.location.href = url.href;
		});
	}

	function submitData(form, formData) {
		fetch(`${api}/dealer/crm/customer/${customerId}`, {
			method: 'PUT',
			body: formData,
		})
			.then((res) => res.json())
			.then((data) => {
				loading.hide();
				if (data.result === 'success') {
					TweenMax.to(el, 0.4, {
						autoAlpha: 0,
						ease: Power1.easeOut,
						onComplete: function () {
							el.hide();
						},
					});

					if (data?.item?.orderIdTestDriveName) {
						thankYou.find('.title').html(' ' + data.item.name);
						thankYou.find('.thank-you__sttname').html(' ' + data.item.orderIdTestDriveName);
						TweenMax.to(thankYou, 0.4, {
							autoAlpha: 1,
							display: 'block',
							ease: Power1.easeOut,
						});
					} else {
						thankYou2.find('.title').html(' ' + data.item.name);

						TweenMax.to(thankYou2, 0.4, {
							autoAlpha: 1,
							display: 'block',
							ease: Power1.easeOut,
						});
					}
				} else {
					form.find('.form__error--response').html(data.errorMessage);
				}
			});
	}

	function renderSelectSale(data) {
		let items = [];
		if (data.length > 0) {
			data.forEach((item) => {
				items.push(`<option value="${item.brokerName}">${item.brokerName}</option>`);
			});
		}

		nvbhSelect.html(items.join(''));
		nvbhSelect.select2('destroy');
		nvbhSelect.select2({
			with: 'resolve',
			minimumResultsForSearch: -1,
			//searchInputPlaceholder: chooseExtendSelectPlaceholder,
			templateSelection: function (data, container) {
				if (data.id !== '') {
					$(container).addClass('select2-selection__rendered--value');
				} else {
					$(container).removeClass('select2-selection__rendered--value');
				}
				return data.text;
			},
		});

		nvbhSelect.on('change', function () {
			const obj = $(this);
			const val = obj.val();
			let userEventChoose = userEventData.find((item) => item.brokerName === val);
			if (val === userEventSelected.brokerName) {
				dataSubmitLast.crm_customer_id = '';
			} else {
				dataSubmitLast.crm_customer_id = userEventChoose.id;
			}
			renderCustomer(userEventChoose);
		});
	}

	function renderCustomer(data) {
		if (data.name) customerName.html(' ' + data.name);
		if (data.phone) {
			customerPhoneLabel.show();
			customerPhoneValue.html(' ' + data.phone + '<br/>');
		} else {
			customerPhoneLabel.hide();
		}
		if (data.email) {
			customerEmailLabel.show();
			customerEmailValue.html(' ' + data.email + '<br/>');
		} else {
			customerEmailLabel.hide();
		}
		if (data.dealerShortName) {
			customerAgencyLabel.show();
			customerAgencyValue.html(' ' + data.dealerShortName + '<br/>');
		} else {
			customerAgencyLabel.hide();
		}
	}

	init();
};
