APP.carDegrees = function (element) {
	const el = $(element);

	function init() {
		if (el.length === 0) return;

		el.each(function () {
			const obj = $(this);
			const slickCarDegrees = obj.find('.slick--car-degrees');
			const slickNext = obj.find('.car-degrees__next');
			const slickPrev = obj.find('.car-degrees__prev');
			const slickPaging = obj.find('.car-degrees__paging');
			slickPrev.on('click', function () {
				slickCarDegrees.slick('slickPrev');
			});

			slickNext.on('click', function () {
				slickCarDegrees.slick('slickNext');
			});

			slickCarDegrees.on('init reInit afterChange', function (event, slick, currentSlide) {
				let i = (currentSlide ? currentSlide : 0) + 1;
				let slideCount = slick.slideCount;
				let number = i > 9 ? i : '0' + i;
				let total = slideCount > 9 ? slideCount : '0' + slideCount;
				slickPaging.html(number + '<span>/</span>' + total);
			});

			slickCarDegrees.slick({
				arrows: false,
				infinite: true,
				fade: true,
				speed: 300,
			});
		});
	}

	init();
};
