APP.mbaTab = function () {
	const init = function () {
		function getMobileOperatingSystem() {
			var userAgent = navigator.userAgent || navigator.vendor || window.opera;

			if (/android/i.test(userAgent)) {
				return 'android';
			}

			// iOS detection from: http://stackoverflow.com/a/9039885/177710
			if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
				return 'ios';
			}

			return 'unknown';
		}

		var os = getMobileOperatingSystem();
		if (os == 'android') {
			$('.qr-ios').hide();
		} else if (os == 'ios') {
			$('.qr-android').hide();
		}
	};

	window.addEventListener('load', videoScroll);
	window.addEventListener('scroll', videoScroll);
	function videoScroll() {
		if (document.querySelectorAll('video[autoplay]').length > 0) {
			var windowHeight = window.innerHeight,
				videoEl = document.querySelectorAll('video[autoplay]');

			for (var i = 0; i < videoEl.length; i++) {
				var thisVideoEl = videoEl[i],
					videoHeight = thisVideoEl.clientHeight,
					videoClientRect = thisVideoEl.getBoundingClientRect().top;

				if (
					videoClientRect <= windowHeight - videoHeight * 0.5 &&
					videoClientRect >= 0 - videoHeight * 0.5
				) {
					thisVideoEl.play();
				} else {
					thisVideoEl.pause();
				}
			}
		}
	}

	init();
};
